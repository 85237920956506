import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {storeYoutubeAuthFailure, storeYoutubeAuthSuccess} from "../../actions/InfluencerChannelActions/storeYoutubeAuthActions";

function* storeYoutubeAuth({ payload, callback }) {
    try {
        console.log("here inside storeYoutubeAuth")
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.storeYoutubeAuth,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("STORE_YOUTUBE_AUTH_SUCCESS status :", response.data.status);
        console.log("STORE_YOUTUBE_AUTH_SUCCESS data :", response.data.response);
        console.log("STORE_YOUTUBE_AUTH_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            storeYoutubeAuthSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("STORE_YOUTUBE_AUTH_FAILURE status :", error?.response?.status);
        console.log("STORE_YOUTUBE_AUTH_FAILURE data:", error?.response?.data);
        console.log("STORE_YOUTUBE_AUTH_FAILURE error :", error);
        console.log('====================================');
        yield put(storeYoutubeAuthFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* storeYoutubeAuthSaga() {
    yield takeLatest(actionTypes.STORE_YOUTUBE_AUTH_REQUEST, storeYoutubeAuth);
}

export default storeYoutubeAuthSaga;
