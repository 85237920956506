import { actionTypes } from '../../../types';

export const addMoneyToWallet = (payload, callback) => ({
    type: actionTypes.ADD_MONEY_TO_WALLET_REQUEST,
    payload,
    callback
});

export const addMoneyToWalletSuccess = (payload) => ({
    type: actionTypes.ADD_MONEY_TO_WALLET_SUCCESS,
    payload,
});

export const addMoneyToWalletFailure = (payload) => ({
    type: actionTypes.ADD_MONEY_TO_WALLET_FAILURE,
    payload,
});

export const addMoneyToWalletReset = () => ({
    type: actionTypes.ADD_MONEY_TO_WALLET_RESET,
});

export const addMoneyToWalletResetAll = () => ({
    type: actionTypes.ADD_MONEY_TO_WALLET_RESET_ALL,
});
