import { actionTypes } from "../../../types"

const initialState = {
    accessToken: '',
    sessionToken: '',
    userId: '',
    emailForLogin: '',
    emailForForgetPassword: '',
    userType: '',
    brandCampaignCreationStep: 1,
    brandCampaignCreationData: {
        basicDetails: {
            campaignName: null,
            campaignPurpose: null,
            platform: null,
            campaignType: null,
            referralCode: null
        },
        contentDetails: {
            startDate: null,
            endDate: null,
            bannerTemplate: {},
            videoPosition: null,
            mediaFileLink: null,
            mediaFileName: null,
            script: null,
            dialogue: null,
            backgroundColor: null,
            otherProperties: null,
            isLocationRequired: false,
            isBrandApprovalRequired: false,
            uploadDuration: null,
        },
        demographicsDetails: {
            region: {},
            country: {},
            state: {},
            campaignAgeGroups: [],
            gender: null,
            niches: [],
            influencerType: null,
            targetView: null,
            estimatedBudget: null
        },

    },
    isMergePopupActive: false,
    optAndSubmitContentResponse: {},
    streamVideoResponse: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload
            }
        case actionTypes.SET_USER_ID:
            return {
                ...state,
                userId: action.payload
            }
        case actionTypes.SET_SESSON_TOKEN:
            return {
                ...state,
                sessionToken: action.payload
            }
        case actionTypes.SET_EMAIL_FOR_LOGIN:
            return {
                ...state,
                emailForLogin: action.payload
            }
        case actionTypes.SET_EMAIL_FOR_FORGET_PASSWORD:
            return {
                ...state,
                emailForForgetPassword: action.payload
            }
        case actionTypes.SET_USER_TYPE:
            return {
                ...state,
                userType: action.payload
            }
        case actionTypes.SET_BRAND_CAMPAIGN_CREATION_STEP:
            return {
                ...state,
                brandCampaignCreationStep: action.payload
            }
        case actionTypes.SET_BRAND_CAMPAIGN_CREATION_DATA:
            return {
                ...state,
                brandCampaignCreationData: action.payload
            }
        case actionTypes.SET_IS_MERGE_POPUP_ACTIVE:
            return {
                ...state,
                isMergePopupActive: action.payload
            }
        case actionTypes.SET_OPT_AND_SUBMIT_CONTENT_RESPONSE:
            return {
                ...state,
                optAndSubmitContentResponse: action.payload
            }
        case actionTypes.SET_STREAM_VIDEO_RESPONSE:
            return {
                ...state,
                streamVideoResponse: action.payload
            }
        default:
            return state;
    }
}
