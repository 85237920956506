import {actionTypes} from "../../../types";

const initialState = {
    loading: false,
    status: '',
    message: '',
    data: {}
}

export default (state = initialState, action) => {
	switch (action.type) {
        case actionTypes.FORGET_PASSWORD_RESET_REQUEST:
            return {
                ...state,
                loading: true,
            }
		case actionTypes.FORGET_PASSWORD_RESET_SUCCESS:
			return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
                data: action?.payload?.response
            }
        case actionTypes.FORGET_PASSWORD_RESET_FAILURE:
            return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
            }
        case actionTypes.FORGET_PASSWORD_RESET_RESET:
            return {
                ...state,
                status: '',
                message: ''
            }
        case actionTypes.FORGET_PASSWORD_RESET_RESET_ALL:
            return initialState
		default:
			return state;
	}
};
