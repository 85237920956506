import {actionTypes} from "../../../types";

const initialState = {
    loading: false,
    status: '',
    message: '',
    data: []
}

export default (state = initialState, action) => {
	switch (action.type) {
        case actionTypes.GET_MY_BRAND_BUSINESS_REQUEST:
            return {
                ...state,
                loading: true,
            }
		case actionTypes.GET_MY_BRAND_BUSINESS_SUCCESS:
			return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
                data: action?.payload?.response
            }
        case actionTypes.GET_MY_BRAND_BUSINESS_FAILURE:
            return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
            }
        case actionTypes.GET_MY_BRAND_BUSINESS_RESET:
            return {
                ...state,
                status: '',
                message: ''
            }
        case actionTypes.GET_MY_BRAND_BUSINESS_RESET_ALL:
            return initialState
		default:
			return state;
	}
};
