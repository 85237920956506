import React, {useEffect, useState} from 'react';
import NavigationButtons from "../../components/main/BrandCampaignCreation/BrandCampaignLayout/NavigationButtons";
import BasicDetails from "../../components/main/BrandCampaignCreation/BasicDetails";
import Breadcrumbs from "../../components/common/Breadcrumbs/Breadcrumbs";
import { useDispatch, useSelector } from 'react-redux';
import EstimatedBudget from '../../components/main/BrandCampaignCreation/EstimatedBudget';
import AudienceDemographicDetails from '../../components/main/BrandCampaignCreation/AudienceDemographicDetails';
import CampaignForm from '../../components/main/BrandCampaignCreation/CampaignForm';
import { useNavigate } from 'react-router';
import { getMyBrandBusinessDetail } from '../../store/BusinessStore/actions/BrandBusinessActions/getMyBrandBusinessDetailActions';
import Popup from '../../components/popups/Popup/Popup';
import { SiLibreofficewriter } from 'react-icons/si';

const CreateCampaign = () => {

    const { brandCampaignCreationStep } = useSelector(state => state.utilReducers);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isPopupOpen, setPopupOpen] = useState(false);

    const breadcrumbData = [
        { label: 'Home', href: '/' },
        { label: 'My Campaigns', href: '/brand/campaigns' },
        { label: 'Details', href: '/brand/campaigns/create' },

    ];

    useEffect(() => {
        dispatch(getMyBrandBusinessDetail(( status, response, message ) => {
            if (status === 302){
                setPopupOpen(true)
            }else{
                // Toast(response.message, 'error');
            }
        }))
    }, []);

    return (
        <div className='w-full flex flex-col overflow-auto bg-[#F2F3F7]'>
            <Breadcrumbs breadcrumbs={breadcrumbData} />
            <div className='px-[4rem]'>
                <NavigationButtons />
                {brandCampaignCreationStep === 4 ?
                    <EstimatedBudget />
                    :brandCampaignCreationStep === 3 ?
                    <AudienceDemographicDetails />
                    :brandCampaignCreationStep === 2 ?
                    <CampaignForm />
                    :
                    <BasicDetails />
                }
            </div>
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setPopupOpen(false)}
                title="Business Registration"
                canDismiss={false}
                icon={<SiLibreofficewriter color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>Please register your business to access the campaign creation features</p>}
                size="sm"
                primaryButton={{
                    text: 'Add Business',
                    onClick: () => {
                        navigate("/brand/busisness")
                        setPopupOpen(false);
                    },
                }}
                // secondaryButton={{
                //     text: 'Not Now',
                //     onClick: () => setPopupOpen(false),
                // }}
            />
        </div>
    );
}

export default CreateCampaign;
