import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { IoCaretDownSharp, IoCaretUpSharp } from 'react-icons/io5';

const MultiSelectDropdown = ({ options, onSelect, placeholder = "Select options" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    if (!isOpen && buttonRef.current) {
      const { bottom, left } = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: bottom + window.scrollY,
        left: left + window.scrollX,
      });
    }
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const isSelected = selectedOptions.some((item) => item.id === option.id);
    const newSelections = isSelected
      ? selectedOptions.filter((item) => item.id !== option.id)
      : [...selectedOptions, option];

    setSelectedOptions(newSelections);
    onSelect(newSelections);
  };

  const handleRemoveOption = (optionId) => {
    const newSelections = selectedOptions.filter((item) => item.id !== optionId);
    setSelectedOptions(newSelections);
    onSelect(newSelections);
  };

  const isSelected = (option) => selectedOptions.some((item) => item.id === option.id);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!buttonRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (buttonRef.current) {
        const { bottom, left } = buttonRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: bottom + window.scrollY,
          left: left + window.scrollX,
        });
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  return (
    <div className="relative w-full" ref={buttonRef}>
      {/* Dropdown Toggle Box with Selected Items */}
      <div
        className="w-full h-[5rem] pl-[1rem] pr-[2rem] text-[1.6rem] text-[#111729] inter-font font-[400] bg-[#FFFFFF] border border-gray-300 rounded-lg focus:outline-none cursor-pointer flex items-center flex-wrap gap-x-2"
        onClick={toggleDropdown}
      >
        {selectedOptions.length > 0 ? (
          selectedOptions.map((option) => (
            <div
              key={option.id}
              className="flex items-center bg-blue-200 text-blue-700 rounded px-1"
            >
              <span className="pl-2 py-1">{option.label} |</span>
              <button
                className="text-red-500 hover:bg-blue-100 px-2"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent dropdown toggle on remove
                  handleRemoveOption(option.id);
                }}
              >
                ✕
              </button>
            </div>
          ))
        ) : (
          <span className="">{placeholder}</span>
        )}
        <div className="ml-auto text-gray-400">{isOpen ? <IoCaretUpSharp size='2.3rem' color='#2CC946' /> : <IoCaretDownSharp size='2.3rem' color='#2CC946' />}</div>
      </div>

      {/* Dropdown Menu */}
      {isOpen &&
        createPortal(
          <div
            style={{
              position: 'absolute',
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              width: buttonRef.current?.offsetWidth || 'auto',
              maxHeight: '20rem', // Fixed height for dropdown
            }}
            className="bg-white border border-gray-300 rounded-lg shadow-xl z-50 overflow-y-auto"
          >
            {options.map((option) => ( 
              <div
                key={option.id}
                className={`px-[2rem] py-[.4rem] cursor-pointer hover:bg-blue-600 flex items-center text-[1.4rem]`}
                onClick={() => handleOptionClick(option)}
              >
                <input
                  type="checkbox"
                  checked={isSelected(option)}
                  onChange={() => handleOptionClick(option)}
                  className="mr-2"
                />
                {option.label}
              </div>
            ))}
          </div>,
          document.body
        )}
    </div>
  );
};

export default MultiSelectDropdown;
