import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {setSessonToken} from "../../../publicStore/actions/utilsActions/utilsActions";
import {createUserFailure, createUserSuccess} from "../../actions/authActions/createUserActions";

function* createUser({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.createUser,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("CREATE_USER_SUCCESS status :", response.data.status);
        console.log("CREATE_USER_SUCCESS data :", response.data.response);
        console.log("CREATE_USER_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            createUserSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        yield put(setSessonToken(response?.data?.response));
    } catch (error) {
        console.log('====================================');
        console.log("CREATE_USER_FAILURE status :", error?.response?.status);
        console.log("CREATE_USER_FAILURE data:", error?.response?.data);
        console.log("CREATE_USER_FAILURE error :", error);
        console.log('====================================');
        yield put(createUserFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* createUserSaga() {
    yield takeLatest(actionTypes.CREATE_USER_REQUEST, createUser);
}

export default createUserSaga;
