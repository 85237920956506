import React from 'react';
import { useSelector } from 'react-redux';
import { SidebarImg } from '../../../static/constant/imgConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FaWallet } from 'react-icons/fa';
import { useNavigate } from 'react-router';

const Header = () => {

  const navigate = useNavigate()

  const getUserInfoReducer = useSelector(state => state.getUserInfoReducer);

  return (
    <header className="w-full h-[7.1rem] flex justify-between items-center px-[2rem] py-[.8rem] bg-white border-b">
      <div className="w-[7.6rem]">
        <img className="w-full h-full" alt="" src={SidebarImg.login1} />
      </div>

      <div className="w-full flex justify-end items-center">
        <div className="flex justify-center items-center bg-[#96A3B6]/10 rounded-[0.3rem] mr-[10%]">
          <FontAwesomeIcon icon={faSearch} size="2rem" color="#666666" className="p-[1.4rem]" />
          <input
            type="text"
            placeholder="Search"
            className="w-[21.5rem] h-full text-[1.6rem] border-none focus:outline-none bg-transparent"
          />
        </div>

        <button
          className='w-[10rem] h-[4rem] flex justify-center items-center bg-[#0D9B50] hover:bg-[#6ec396] rounded-full mx-[2rem]'
          onClick={() => navigate("/brand/transactions")}
        >
          <FaWallet size={20} color='white' className="mx-1" />
          <span className="text-[#ffffff] text-[1.4rem]">${getUserInfoReducer?.data?.walletBalance}</span>
        </button>

        {/* <button className='w-[6rem] h-[4rem] flex justify-evenly items-center bg-[#FD6C55] hover:bg-[#FD6D77] rounded-full mx-[2rem]'>
          <img src={SidebarImg.notification} alt="notification-bell" className="w-[2rem] h-[2rem]" />
          <span className="text-[#ffffff] text-[1.4rem]">2</span>
        </button> */}

        {/* User Info Section */}
        <div className="flex items-center">
          <div className="w-[3.6rem] h-[3.6rem] rounded-full mx-[2rem]">
            <img className="w-full h-full rounded-full" src={SidebarImg.profile} alt="User Avatar" />
          </div>
          <span className="text-[1.5rem]">
            {getUserInfoReducer?.data?.firstName} {/* Dynamic name from getUserInfoReducer?.data */}
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
