import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";
import { MyCampaignsImg } from "../../../static/constant/imgConstants";
import Popup from "../../popups/Popup/Popup";
import { FaCircleCheck, FaCircleExclamation } from "react-icons/fa6";
import { uploadInfluencerVideo } from "../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/uploadInfluencerVideoActions";
import { uploadToS3 } from "../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/uploadToS3Actions";
import { useToast } from "../../../hooks/useToast";
import { optAndSubmitContent } from "../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/optAndSubmitContentActions";
import { checkContentMergeStatus } from "../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/checkContentMergeStatusActions";
import { streamVideo } from "../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/streamVideoActions";
import { Oval } from "react-loader-spinner";
import { publishVideoOnYoutube } from "../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/publishVideoOnYoutubeAction";
import moment from "moment/moment";
import { GiSandsOfTime } from "react-icons/gi";
import { SiLibreofficewriter } from "react-icons/si";
import { setIsMergePopupActive, setOptAndSubmitContentResponse } from "../../../store/publicStore/actions/utilsActions/utilsActions";
import Footer from "../../common/Footer/Footer";

const UploadCampaignVideos = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const Toast = useToast();
    const navigate = useNavigate()

    const { campaign } = location.state || {};

    const [videoFile, setVideoFile] = useState(null);
    const [videoPreview, setVideoPreview] = useState("");
    const [videoInfo, setVideoInfo] = useState({ name: "", duration: 0 });
    const [loading, setLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSuccessPopupOpen, setSuccessPopupOpen] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isTitlePopupOpen, setIsTitlePopupOpen] = useState(false);
    const [uploadVideoResponse, setUploadVideoResponse] = useState();
    const [ugcOptAndSubmitContentResponse, setUgcOptAndSubmitContentResponse] = useState();
    // const [streamVideoResponse, setStreamVideoResponse] = useState();
    const [loadingVideoUpload, setLoadingVideoUpload] = useState(false);
    const [loadingVideoUploading, setLoadingVideoUploading] = useState(false);
    const [popupType, setPopupType] = useState(null);
    const [publishTitle, setPublishTitle] = useState();
    const [publishDescription, setPublishDescription] = useState();
    const [scheduleTime, setScheduleTime] = useState();
    const [publishVideoResponse, setPublishVideoResponse] = useState();
    const [ugcSuccessPopup, setUgcSuccessPopup] = useState();
    const [isPopupForTokenExpireOpen, setPopupForTokenExpireOpen] = useState(false);
    const [ugcContentResponse, setUgcContentResponse] = useState();


    const uploadToS3ReducerStatus = useSelector((state) => state.uploadToS3Reducer.status);
    const { optAndSubmitContentResponse, streamVideoResponse, isMergePopupActive } = useSelector((state) => state.utilReducers);


    const handleFileUpload = (file) => {
        if (!file || !file.type.startsWith("video/")) {
            setErrors((prevState) => ({
                ...prevState,
                mediaFileLink: "Please upload a valid video file",
            }));
            return;
        }

        setLoading(true);
        const previewURL = URL.createObjectURL(file);
        setVideoPreview(previewURL);
        setVideoFile(file);

        // Extract video metadata
        const videoElement = document.createElement("video");
        videoElement.preload = "metadata";

        videoElement.onloadedmetadata = () => {
            const duration = videoElement.duration; // Duration in seconds
            setVideoInfo({
                name: file.name,
                duration: duration.toFixed(2), // Format duration to two decimals
            });
            setLoading(false);
            URL.revokeObjectURL(previewURL);
        };

        videoElement.src = previewURL;

        setErrors((prevState) => {
            const { mediaFileLink, ...updatedErrors } = prevState;
            return updatedErrors;
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        handleFileUpload(file);
    };

    const handleRemoveVideo = () => {
        setVideoFile(null);
        setVideoPreview("");
        setVideoInfo({ name: "", duration: 0 });
    };

    const handleConfirmUpload = () => {
        /* if (videoFile) {
            setIsTitlePopupOpen(true);
        } else {
            setErrors((prevState) => ({
                ...prevState,
                mediaFileLink: "Please upload a video before proceeding",
            }));
        } */
        setLoadingVideoUploading(true);
        dispatch(uploadInfluencerVideo({
            "videoName": videoInfo?.name,
            "campaignType": campaign?.campaignType,
            "durationInSeconds": Math.round(videoInfo?.duration)
        }, (status, response, message) => {
            if (status == 200) {
                setUploadVideoResponse(response);

                // Toast(message, "success");
            } else {
                Toast(message, "info");
            }
        }))

    };

    useEffect(() => {
        dispatch(uploadToS3({ presignedURL: uploadVideoResponse?.presignedURL, file: videoFile },
            (status, response, message) => {
                if (status == 200) {
                    setLoadingVideoUploading(false);
                } else if (status) {
                    setLoadingVideoUploading(false);
                }
            }
        ));
    }, [uploadVideoResponse])

    useEffect(() => {
        if (uploadToS3ReducerStatus == 200) {

        } else if (uploadToS3ReducerStatus) {

        }
    }, [uploadToS3ReducerStatus])



    console.log("campaign UploadCampaignVideos", campaign);



    return (
        <>
            <div className="w-full h-full flex flex-col justify-start bg-[#EDEEF1]">
                <div className="w-full h-full flex flex-col bg-[#F2F3F7]">
                    <Breadcrumbs
                        breadcrumbs={[
                            { label: "Home", href: "/" },
                            { label: "My Campaigns", href: "/influencer/campaigns" },
                            { label: "Campaigns Upload", href: "/influencer/campaigns/upload" },
                        ]}
                    />
                    {loadingVideoUpload ?
                        <div className="p-10 h-full flex flex-col justify-center items-center gap-5">
                            <Oval
                                visible={true}
                                height="50"
                                width="50"
                                color="#000000"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <h2 className="text-[1.6rem]">Your content is in processing it will take few minutes. Please wait.</h2>
                        </div>
                        :
                        <div className="p-10 h-full">
                            <h2 className="text-[1.4rem] font-semibold mb-4">
                                {videoPreview ? "Preview Video" : "Upload Video"}
                            </h2>
                            <div className="text-[1.4rem] font-semibold mt-4 mb-2">
                                {videoPreview ? "Your Preview is ready for watch" : ""}
                            </div>
                            <div
                                className={`flex items-center justify-center bg-white border-2 border-dashed border-gray-300 w-5/6 h-3/5 rounded-lg p-6 text-center ${isDragging ? "bg-green-100 border-green-500" : ""
                                    }`}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                            >
                                {videoPreview ? (
                                    <div className="w-full max-w-4xl aspect-video rounded-lg overflow-hidden bg-black">
                                        <video
                                            className="w-full h-full object-contain"
                                            controls
                                            src={Object.keys(streamVideoResponse).length !== 0 ? streamVideoResponse
                                                : videoPreview}
                                        />
                                    </div>
                                ) : Object.keys(streamVideoResponse).length !== 0 ?
                                    (
                                        <div className="w-full max-w-4xl aspect-video rounded-lg overflow-hidden bg-black">
                                            <video
                                                className="w-full h-full object-contain"
                                                controls
                                                src={streamVideoResponse}
                                            />
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="mt-12">
                                            <p className="text-[1.4rem] font-medium text-gray-700 mb-2">
                                                Upload your video
                                            </p>
                                            <p className="text-[1.4rem] text-gray-500 mb-4">
                                                The video should have a resolution of 4K or higher and a
                                                duration of less than 60 seconds.
                                            </p>
                                            <div className="flex flex-col items-center justify-center">
                                                <div className="mb-4">
                                                    <div className="h-[68%] flex items-center justify-center">
                                                        <div className="h-[3.1rem]">
                                                            <img
                                                                src={MyCampaignsImg.uploadIcon}
                                                                alt="icon"
                                                                className="w-full h-full"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="video/*"
                                                    className="w-full hidden"
                                                    onChange={(e) =>
                                                        handleFileUpload(e.target.files[0])
                                                    }
                                                    id="fileUpload"
                                                />
                                                <p className="text-[1.4rem] text-gray-500 mb-4">
                                                    Drag Your Videos Here
                                                </p>
                                                <p className="text-[1.4rem] text-gray-500 mb-4">Or</p>
                                                <label
                                                    htmlFor="fileUpload"
                                                    className="bg-[#0D9B50] text-[1.4rem] text-white px-12 py-4 rounded-lg "
                                                >
                                                    Browse for Video
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                {errors?.mediaFileLink && (
                                    <p className="text-red-500 text-[1.6rem]">
                                        {errors?.mediaFileLink}
                                    </p>
                                )}
                            </div>
                            {/* {videoFile && (
                            <div className="mt-4">
                                <p>
                                    <strong>File Name:</strong> {videoInfo.name}
                                </p>
                                <p>
                                    <strong>Duration:</strong> {videoInfo.duration} seconds
                                </p>
                            </div>
                        )} */}

                            <div className="flex justify-start mt-6">
                                {Object.keys(streamVideoResponse).length !== 0 || ugcContentResponse ?
                                    <div className="flex justify-start mt-6">
                                        <button
                                            className={`bg-[#0D9B50] text-white px-14 py-2 rounded-lg mr-5 text-[1.8rem]`}
                                            onClick={() => {
                                                setPopupType("publish");
                                                setPopupOpen(true);
                                            }}
                                        >
                                            Proceed to YouTube
                                        </button>
                                        <button
                                            className={`bg-[#ffffff] text-[#0D9B50] px-14 py-2 rounded-lg border-2 border-[#0D9B50] text-[1.8rem]`}
                                            onClick={() => {
                                                setPopupType("schedule");
                                                setPopupOpen(true);
                                            }}
                                        >
                                            Schedule It
                                        </button>
                                    </div>
                                    : uploadToS3ReducerStatus === 200 && !isMergePopupActive ?
                                        <button
                                            className={`bg-[#0D9B50] text-white px-14 py-2 rounded-lg mr-2 text-[1.8rem]`}
                                            onClick={() => {
                                                dispatch(optAndSubmitContent({
                                                    "campaignId": campaign?.campaignId,
                                                    "uploadFileS3Path": uploadVideoResponse?.uploadedS3Path
                                                }, (status, response, message) => {
                                                    if (status === 202) {
                                                        if (campaign?.campaignType != "UGC") {
                                                            dispatch(setIsMergePopupActive(true));
                                                        }
                                                        // Toast(message, "success")
                                                        response.campaignType = campaign?.campaignType
                                                        setUgcOptAndSubmitContentResponse({status:status, message:message, response:response});

                                                        dispatch(setOptAndSubmitContentResponse(response));
                                                        // setLoadingVideoUpload(true);
                                                    } else if (status === 200 && campaign.isBrandApprovalRequired) {
                                                        dispatch(setOptAndSubmitContentResponse(response));
                                                        setUgcOptAndSubmitContentResponse({status:status, message:message, response:response});
                                                        setUgcContentResponse(response)

                                                        setUgcSuccessPopup(true);
                                                    }
                                                    else if (status === 200 && !campaign.isBrandApprovalRequired) {
                                                        setUgcOptAndSubmitContentResponse({status:status, message:message, response:response});

                                                        setUgcContentResponse(response)
                                                        setUgcSuccessPopup(false);
                                                        dispatch(setOptAndSubmitContentResponse(response));
                                                    }
                                                    else if (status === 305) {
                                                        setPopupForTokenExpireOpen(true)
                                                    } else if (status) {
                                                        Toast(message, "error")
                                                    }
                                                }))

                                            }
                                            }
                                        // disabled={!videoFile}
                                        >
                                            Continue
                                        </button>
                                        : isMergePopupActive ?
                                            <div className="text-[1.4rem] text-center"> Your content is being processed. You can leave this page if you wish; the process will continue in the background. </div>
                                            :
                                            null
                                }
                            </div>
                            {uploadToS3ReducerStatus != 200 && !(Object.keys(streamVideoResponse).length !== 0) ?
                                <div className="flex justify-start mt-6">
                                    <button
                                        className={`bg-[#0D9B50] ${!videoFile ? "bg-[#0D9B50]/50" : "bg-[#0D9B50]"
                                            } text-white px-14 py-2 rounded-lg mr-2 text-[1.8rem]`}
                                        onClick={handleConfirmUpload}
                                        disabled={!videoFile}
                                    >
                                        {loadingVideoUploading ?
                                            <Oval
                                                visible={true}
                                                height="20"
                                                width="20"
                                                color="#000000"
                                                ariaLabel="oval-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                            :
                                            "Upload"
                                        }
                                    </button>
                                    {videoFile ?
                                        <button
                                            className="w-[15rem] h-[5rem] text-[#0D9B50] text-[1.8rem] border border-[#0D9B50] bg-[#ffffff] hover:scale-95 text-center rounded-[.8rem] cursor-pointer mr-[3rem]"
                                            onClick={handleRemoveVideo}
                                            disabled={!videoFile}
                                        >
                                            Replace
                                        </button>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    }
                </div>
                <Footer />
            </div>

            {/* Popups */}
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setPopupOpen(false)}
                title={popupType === 'publish' ? 'Publish It' : 'Schedule It'}
                canDismiss={false}
                content={
                    <div className="text-[1.6rem] mt-12">
                        <form className="space-y-4">
                            <div>
                                <label htmlFor="title" className="text-[#202224] text-[1.5rem]">
                                    Add Title<span className="text-[1.6rem] text-[#E3351D]">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="title"
                                    placeholder="Enter Title here"
                                    className="w-full bg-[#F7FBFF] h-20 border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none"
                                    onChange={(e) => {
                                        setPublishTitle(e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="description" className="text-[#202224] text-[1.5rem]">
                                    Add Description<span className="text-[1.6rem] text-[#E3351D]">*</span>
                                </label>
                                <input
                                    id="description"
                                    placeholder="Enter Description here"
                                    className="w-full bg-[#F7FBFF] h-20 border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none"
                                    onChange={(e) => {
                                        setPublishDescription(e.target.value);
                                    }}
                                />
                            </div>
                            {popupType === 'schedule' && (
                                <div className="relative">
                                    <label htmlFor="datetime" className="text-[#202224] text-[1.5rem]">
                                        Add Schedule<span className="text-[1.6rem] text-[#E3351D]">*</span>
                                    </label>
                                    <input
                                        type="datetime-local"
                                        id="datetime"
                                        min={new Date().toISOString().slice(0, 16)}
                                        placeholder="Enter Schedule here"
                                        className="w-full bg-[#F7FBFF] h-20 border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none"
                                        value={scheduleTime}
                                        onChange={(e) => {
                                            console.log("schedule input :", e.target.value);
                                            setScheduleTime(e.target.value);
                                        }}
                                    />
                                    <img
                                        src={MyCampaignsImg.calendarIcon}
                                        alt="Calendar Icon"
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 mt-4 w-10 h-10"
                                        onKeyDown={(e) => e.preventDefault()}
                                        onClick={() => {
                                            const inputDateElement = document.getElementById('datetime');
                                            inputDateElement.showPicker();
                                        }}
                                    />
                                </div>
                            )}
                        </form>
                    </div>
                }
                size="sm"
                primaryButton={{
                    text: popupType === 'publish' ? 'Upload Now' : 'Schedule Now',
                    onClick: () => {
                        if (popupType === 'publish') {
                            if (!publishTitle) {
                                Toast("Title is mandatory", "error");
                            } else if (!publishDescription) {
                                Toast("Description is mandatory", "error");
                            }
                            else {
                                dispatch(publishVideoOnYoutube({
                                    "contentId": ((campaign != null && campaign?.campaignType == 'UGC') || optAndSubmitContentResponse?.campaignType == 'UGC') ? ugcContentResponse.contentId : optAndSubmitContentResponse?.contentId,
                                    "title": publishTitle,
                                    "description": publishDescription,
                                    "isPublishNow": true,
                                    "scheduleDateTime": ""
                                }, (status, response, message) => {
                                    if (status == 200) {
                                        setPublishVideoResponse({ status: 200, response: response, message: message });
                                        setSuccessPopupOpen(true);
                                    } else if (status === 305) {
                                        setPopupForTokenExpireOpen(true)
                                    } else if (status) {
                                        setPublishVideoResponse({ status: status, response: response, message: message });
                                        setSuccessPopupOpen(true);
                                    }
                                }))
                                setPopupOpen(false);
                            }
                        } else {
                            if (!publishTitle) {
                                Toast("Title is mandatory", "error");
                            } else if (!publishDescription) {
                                Toast("Description is mandatory", "error");
                            } else if (!scheduleTime) {
                                Toast("Date is mandatory", "error");
                            } else {
                                dispatch(publishVideoOnYoutube({
                                    "contentId": ((campaign != null && campaign?.campaignType == 'UGC') || optAndSubmitContentResponse?.campaignType == 'UGC') ? ugcContentResponse.contentId : optAndSubmitContentResponse?.contentId,
                                    "title": publishTitle,
                                    "description": publishDescription,
                                    "isPublishNow": false,
                                    "scheduleDateTime": moment(scheduleTime).format("YYYY-MM-DD HH:mm:ss")
                                }, (status, response, message) => {
                                    if (status == 200) {
                                        setPublishVideoResponse({ status: 200, response: response, message: message });
                                        setSuccessPopupOpen(true);
                                    } else if (status) {
                                        setPublishVideoResponse({ status: status, response: response, message: message });
                                        setSuccessPopupOpen(true);
                                    }
                                }))
                                setPopupOpen(false);
                            }
                        }

                    },
                }}
            />
            <Popup
                isOpen={isSuccessPopupOpen}
                onClose={() => setSuccessPopupOpen(false)}
                title={"Message"}
                canDismiss={false}
                icon={publishVideoResponse?.status == 200 ? <FaCircleCheck color="#0D9B50" size={110} className="my-[5rem]" /> : <FaCircleExclamation color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>{publishVideoResponse?.message}</p>}
                size="sm"
                primaryButton={{
                    text: publishVideoResponse?.status == 200 ? 'Done' : "Retry",
                    onClick: () => {
                        if (publishVideoResponse?.status == 200) {
                            navigate('/influencer/campaigns')
                            setSuccessPopupOpen(false);
                        } else {
                            setSuccessPopupOpen(false);
                        }
                    },
                }}
            />
            <Popup
                isOpen={ugcSuccessPopup}
                onClose={() => setUgcSuccessPopup(false)}
                title={"Message"}
                canDismiss
                icon={ugcOptAndSubmitContentResponse?.status == 200 ? <GiSandsOfTime color="#FFAF45" size={110} className="my-[5rem]" /> : <FaCircleExclamation color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>{ugcOptAndSubmitContentResponse?.message}</p>}
                size="sm"
                primaryButton={{
                    text: ugcOptAndSubmitContentResponse?.status == 200 ? 'Done' : "Retry",
                    onClick: () => {
                        if (ugcOptAndSubmitContentResponse?.status == 200) {
                            navigate('/influencer/campaigns')
                            setUgcSuccessPopup(false);
                        } else {
                            setUgcSuccessPopup(false);
                        }
                    },
                }}
            />
            <Popup
                isOpen={isPopupForTokenExpireOpen}
                onClose={() => setPopupForTokenExpireOpen(false)}
                title="Youtube Auth token"
                canDismiss
                icon={<SiLibreofficewriter color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>Your Channel authentication is expired or revoked</p>}
                size="sm"
                primaryButton={{
                    text: 'Re-auth',
                    onClick: () => {
                        window.open('/influencer-channel-auth', '_blank');
                        setPopupForTokenExpireOpen(false);
                    },
                }}
                secondaryButton={{
                    text: 'Not Now',
                    onClick: () => setPopupForTokenExpireOpen(false),
                }}
            />
            {/* Success, Failure, and Title popups remain unchanged */}
        </>
    );
};

export default UploadCampaignVideos;
