import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const storeYoutubeAuth = (payload, callback) => ({
    type: actionTypes.STORE_YOUTUBE_AUTH_REQUEST,
    payload,
    callback,
});

// Action creator for successful OTP generation
export const storeYoutubeAuthSuccess = (payload) => ({
    type: actionTypes.STORE_YOUTUBE_AUTH_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const storeYoutubeAuthFailure = (payload) => ({
    type: actionTypes.STORE_YOUTUBE_AUTH_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const storeYoutubeAuthReset = () => ({
    type: actionTypes.STORE_YOUTUBE_AUTH_RESET,
});

// Action creator for resetting all OTP generation reducer
export const storeYoutubeAuthResetAll = () => ({
    type: actionTypes.STORE_YOUTUBE_AUTH_RESET_ALL,
});
