import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const getYoutubeChannelList = (callback) => ({
    type: actionTypes.GET_YOUTUBE_CHANNEL_LIST_REQUEST,
    callback,
});

// Action creator for successful OTP generation
export const getYoutubeChannelListSuccess = (payload) => ({
    type: actionTypes.GET_YOUTUBE_CHANNEL_LIST_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getYoutubeChannelListFailure = (payload) => ({
    type: actionTypes.GET_YOUTUBE_CHANNEL_LIST_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getYoutubeChannelListReset = () => ({
    type: actionTypes.GET_YOUTUBE_CHANNEL_LIST_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getYoutubeChannelListResetAll = () => ({
    type: actionTypes.GET_YOUTUBE_CHANNEL_LIST_RESET_ALL,
});
