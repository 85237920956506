import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getWalletTxnWithFilter = (payload, callback) => ({
    type: actionTypes.GET_WALLET_TXN_WITH_FILTER_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const getWalletTxnWithFilterSuccess = (payload) => ({
    type: actionTypes.GET_WALLET_TXN_WITH_FILTER_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getWalletTxnWithFilterFailure = (payload) => ({
    type: actionTypes.GET_WALLET_TXN_WITH_FILTER_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getWalletTxnWithFilterReset = () => ({
    type: actionTypes.GET_WALLET_TXN_WITH_FILTER_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getWalletTxnWithFilterResetAll = () => ({
    type: actionTypes.GET_WALLET_TXN_WITH_FILTER_RESET_ALL,
});
