import { actionTypes } from '../../../types';

export const txnStatus = (payload, callback) => ({
    type: actionTypes.TXN_STATUS_REQUEST,
    payload,
    callback
});

export const txnStatusSuccess = (payload) => ({
    type: actionTypes.TXN_STATUS_SUCCESS,
    payload,
});

export const txnStatusFailure = (payload) => ({
    type: actionTypes.TXN_STATUS_FAILURE,
    payload,
});

export const txnStatusReset = () => ({
    type: actionTypes.TXN_STATUS_RESET,
});

export const txnStatusResetAll = () => ({
    type: actionTypes.TXN_STATUS_RESET_ALL,
});
