import { actionTypes } from '../../../types';

export const publishVideoOnYoutube = (payload, callback) => ({
    type: actionTypes.PUBLISH_VIDEO_ON_YOUTUBE_REQUEST,
    payload,
    callback
});

export const publishVideoOnYoutubeSuccess = (payload) => ({
    type: actionTypes.PUBLISH_VIDEO_ON_YOUTUBE_SUCCESS,
    payload,
});

export const publishVideoOnYoutubeFailure = (payload) => ({
    type: actionTypes.PUBLISH_VIDEO_ON_YOUTUBE_FAILURE,
    payload,
});

export const publishVideoOnYoutubeReset = () => ({
    type: actionTypes.PUBLISH_VIDEO_ON_YOUTUBE_RESET,
});

export const publishVideoOnYoutubeResetAll = () => ({
    type: actionTypes.PUBLISH_VIDEO_ON_YOUTUBE_RESET_ALL,
});
