import {actionTypes} from "../../../types";

export const logout = () => ({
    type: actionTypes.USER_LOGOUT
});

export const setAccessToken = (payload) => ({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: payload
});

export const setSessonToken = (payload) => ({
    type: actionTypes.SET_SESSON_TOKEN,
    payload: payload
});

export const setEmailForLogin = (payload) => ({
    type: actionTypes.SET_EMAIL_FOR_LOGIN,
    payload: payload
});

export const setEmailForForgetPassword = (payload) => ({
    type: actionTypes.SET_EMAIL_FOR_FORGET_PASSWORD,
    payload: payload
});

export const setUserType = (payload) => ({
    type: actionTypes.SET_USER_TYPE,
    payload: payload
});

export const setUserId = (payload) => ({
    type: actionTypes.SET_USER_ID,
    payload: payload
});

export const setBrandCampaignCreationStep = (payload) => ({
    type: actionTypes.SET_BRAND_CAMPAIGN_CREATION_STEP,
    payload: payload
});

export const setBrandCampaignCreationData = (payload) => ({
    type: actionTypes.SET_BRAND_CAMPAIGN_CREATION_DATA,
    payload: payload
});

export const setIsMergePopupActive = (payload) => ({
    type: actionTypes.SET_IS_MERGE_POPUP_ACTIVE,
    payload: payload
});

export const setOptAndSubmitContentResponse = (payload) => ({
    type: actionTypes.SET_OPT_AND_SUBMIT_CONTENT_RESPONSE,
    payload: payload
});

export const setStreamVideoResponse = (payload) => ({
    type: actionTypes.SET_STREAM_VIDEO_RESPONSE,
    payload: payload
});
