import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getTotalIncomeByMonthAndYearForDashboard = (payload, callback) => ({
    type: actionTypes.GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const getTotalIncomeByMonthAndYearForDashboardSuccess = (payload) => ({
    type: actionTypes.GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getTotalIncomeByMonthAndYearForDashboardFailure = (payload) => ({
    type: actionTypes.GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getTotalIncomeByMonthAndYearForDashboardReset = () => ({
    type: actionTypes.GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getTotalIncomeByMonthAndYearForDashboardResetAll = () => ({
    type: actionTypes.GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET_ALL,
});
