import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const userLogin = (payload) => ({
    type: actionTypes.USER_LOGIN_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const userLoginSuccess = (payload) => ({
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const userLoginFailure = (payload) => ({
    type: actionTypes.USER_LOGIN_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const userLoginReset = () => ({
    type: actionTypes.USER_LOGIN_RESET,
});

// Action creator for resetting all OTP generation reducer
export const userLoginResetAll = () => ({
    type: actionTypes.USER_LOGIN_RESET_ALL,
});
