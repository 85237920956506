import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { stripeOnboardingFailure, stripeOnboardingSuccess } from '../../actions/InfluencerCampaignActions/stripeOnboardingAction';

function* stripeOnboarding({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.stripeOnboarding,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("STRIPE_ONBOARDING_SUCCESS status :", response.data.status);
        console.log("STRIPE_ONBOARDING_SUCCESS data :", response.data.response);
        console.log("STRIPE_ONBOARDING_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            stripeOnboardingSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("STRIPE_ONBOARDING_FAILURE status :", error?.response?.status);
        console.log("STRIPE_ONBOARDING_FAILURE data:", error?.response?.data);
        console.log("STRIPE_ONBOARDING_FAILURE error :", error);
        console.log('====================================');
        yield put(stripeOnboardingFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* stripeOnboardingSaga() {
    yield takeLatest(actionTypes.STRIPE_ONBOARDING_REQUEST, stripeOnboarding);
}

export default stripeOnboardingSaga;
