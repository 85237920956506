import { actionTypes } from '../../../types';

export const uploadToS3 = (payload, callback) => ({
    type: actionTypes.UPLOAD_TO_S3_REQUEST,
    payload,
    callback
});

export const uploadToS3Success = (payload) => ({
    type: actionTypes.UPLOAD_TO_S3_SUCCESS,
    payload,
});

export const uploadToS3Failure = (payload) => ({
    type: actionTypes.UPLOAD_TO_S3_FAILURE,
    payload,
});

export const uploadToS3Reset = () => ({
    type: actionTypes.UPLOAD_TO_S3_RESET,
});

export const uploadToS3ResetAll = () => ({
    type: actionTypes.UPLOAD_TO_S3_RESET_ALL,
});
