import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getCampaignListWithFilterDashBoard = (payload, callback) => ({
    type: actionTypes.GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const getCampaignListWithFilterDashBoardSuccess = (payload) => ({
    type: actionTypes.GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getCampaignListWithFilterDashBoardFailure = (payload) => ({
    type: actionTypes.GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getCampaignListWithFilterDashBoardReset = () => ({
    type: actionTypes.GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getCampaignListWithFilterDashBoardResetAll = () => ({
    type: actionTypes.GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_RESET_ALL,
});
