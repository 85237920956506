import React, { useState } from "react";
import { MyCampaignsImg } from "../../../static/constant/imgConstants";
import BottomNavigation from "./BrandCampaignLayout/BottomNavigation";
import { useDispatch, useSelector } from "react-redux";
import {
    setBrandCampaignCreationData,
    setBrandCampaignCreationStep
} from "../../../store/publicStore/actions/utilsActions/utilsActions";

const BasicDetails = () => {
    const dispatch = useDispatch();

    const { brandCampaignCreationStep, brandCampaignCreationData } = useSelector((state) => state.utilReducers);

    const [campaignName, setCampaignName] = useState(brandCampaignCreationData?.basicDetails?.campaignName ? brandCampaignCreationData?.basicDetails?.campaignName : "");
    const [campaignPurpose, setCampaignPurpose] = useState(brandCampaignCreationData?.basicDetails?.campaignPurpose ? brandCampaignCreationData?.basicDetails?.campaignPurpose : "");
    const [platform, setPlatform] = useState(brandCampaignCreationData?.basicDetails?.platform ? brandCampaignCreationData?.basicDetails?.platform : "");
    const [campaignType, setCampaignType] = useState(brandCampaignCreationData?.basicDetails?.campaignType ? brandCampaignCreationData?.basicDetails?.campaignType : "Banner");
    const [referralCode, setReferralCode] = useState("");
    const [errors, setErrors] = useState({});

    const campaignTypeData = [
        { id: "", campaignType: "Banner", campaignTypeIcon: MyCampaignsImg.mycampaigns1 },
        { id: "", campaignType: "Video", campaignTypeIcon: MyCampaignsImg.mycampaigns2 },
        { id: "", campaignType: "User Generated Content", campaignTypeIcon: MyCampaignsImg.mycampaigns3 },
    ]

    // Validate the form and return errors
    const validateForm = (data) => {
        let formErrors = {};

        if (!data.campaignName.trim()) {
            formErrors.campaignName = "Campaign Name is required";
        }
        if (!data.campaignPurpose.trim()) {
            formErrors.campaignPurpose = "Campaign Purpose is required";
        }
        if (!data.platform) {
            formErrors.platform = "Social Media Platform is required";
        }
        if (!data.campaignType) {
            formErrors.campaignType = "Campaign Type is required";
        }

        return formErrors;
    };

    const handleNext = () => {

        console.log("brandCampaignCreationStep :", brandCampaignCreationStep);

        // Validate form data
        const formErrors = validateForm({
            campaignName,
            campaignPurpose,
            platform,
            campaignType,
            referralCode
        });

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors); // Show errors if validation fails
        } else {
            // Clear errors and save data to Redux store
            setErrors({});
            console.log("Successfully data from basic details page", brandCampaignCreationData?.basicDetails);
            // Dispatch the campaign data to Redux
            dispatch(
                setBrandCampaignCreationData({
                    basicDetails: {
                        campaignName: campaignName,
                        campaignPurpose: campaignPurpose,
                        platform: platform,
                        campaignType: campaignType,
                        referralCode: referralCode
                    },
                    contentDetails: {
                        startDate: brandCampaignCreationData?.contentDetails?.startDate,
                        endDate: brandCampaignCreationData?.contentDetails?.endDate,
                        bannerTemplate: brandCampaignCreationData?.contentDetails?.bannerTemplate,
                        videoPosition: brandCampaignCreationData?.contentDetails?.videoPosition,
                        mediaFileLink: brandCampaignCreationData?.contentDetails?.mediaFileLink,
                        mediaFileName: brandCampaignCreationData?.contentDetails?.mediaFileName,
                        script: brandCampaignCreationData?.contentDetails?.script,
                        dialogue: brandCampaignCreationData?.contentDetails?.dialogue,
                        backgroundColor: brandCampaignCreationData?.contentDetails?.backgroundColor,
                        otherProperties: brandCampaignCreationData?.contentDetails?.otherProperties,
                        isLocationRequired: brandCampaignCreationData?.contentDetails?.isLocationRequired,
                        isBrandApprovalRequired: brandCampaignCreationData?.contentDetails?.isBrandApprovalRequired,
                        uploadDuration: brandCampaignCreationData?.contentDetails?.uploadDuration,
                    },
                    audienceDemographicDetails: {
                        ageGroup: brandCampaignCreationData?.audienceDemographicDetails?.ageGroup,
                        country: brandCampaignCreationData?.audienceDemographicDetails?.country,
                        niche: brandCampaignCreationData?.audienceDemographicDetails?.niche,
                        region: brandCampaignCreationData?.audienceDemographicDetails?.region,
                        gender: brandCampaignCreationData?.audienceDemographicDetails?.gender,
                        state: brandCampaignCreationData?.audienceDemographicDetails?.state,
                    }
                })
            );

            // Proceed to the next step
            dispatch(
                setBrandCampaignCreationStep(brandCampaignCreationStep + 1)
            );
        }
    };

    return (
        <div className="w-full h-[80%] bg-[#FFFFFF66] shadow-xl shadow-[#E3E8EF] rounded-[0.6rem] p-[3.2rem]">
            <div className="w-full h-full !overflow-y-scroll no-scrollbar">
                <div className="w-full flex flex-col md:flex-row justify-between items-top mb-[2.8rem]">
                    <div className="w-[49%] text-[1.6rem] inter-font font-[400]">
                        <label className="text-[#111729]">
                            Campaign Name<span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Enter a Campaign Name"
                            className="w-full px-4 py-4 mt-[.8rem] border border-[#E3E8EF] rounded-lg focus:outline-none placeholder-[#677489]"
                            value={campaignName}
                            onChange={(e) => {
                                setCampaignName(e.target.value);
                                setErrors((prevState) => { const { ["campaignName"]: _, ...updatedObject } = prevState; return updatedObject; });
                            }}
                        />
                        {errors.campaignName && (
                            <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                                {errors.campaignName}
                            </p>
                        )}
                    </div>
                    <div className="w-[49%] text-[1.6rem] inter-font font-[400]">
                        <label className="text-[#111729]">
                            Purpose of the Campaign
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Enter a Purpose of the Campaign"
                            className="w-full px-4 py-4 mt-[.8rem] border border-[#E3E8EF] rounded-lg focus:outline-none placeholder-[#677489]"
                            value={campaignPurpose}
                            onChange={(e) => {
                                setCampaignPurpose(e.target.value);
                                setErrors((prevState) => { const { ["campaignPurpose"]: _, ...updatedObject } = prevState; return updatedObject; });
                            }}
                        />
                        {errors.campaignPurpose && (
                            <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                                {errors.campaignPurpose}
                            </p>
                        )}
                    </div>
                </div>

                <div className="w-full flex flex-col md:flex-row justify-between items-top mb-[2.8rem]">
                    <div className="w-[49%]">
                        <label className="text-[1.6rem] inter-font font-[400] text-[#111729]">
                            Social Media Platform
                            <span className="text-red-500">*</span>
                        </label>
                        <select
                            className="w-full px-4 py-4 mt-[.8rem] text-[1.6rem] inter-font font-[400] border border-[#E3E8EF] rounded-lg focus:outline-none"
                            value={platform}
                            onChange={(e) => {
                                setPlatform(e.target.value);
                                setErrors((prevState) => { const { ["platform"]: _, ...updatedObject } = prevState; return updatedObject; });
                            }}
                        >
                            <option value="" disabled>Select Platform</option>
                            <option value="YOUTUBE">YOUTUBE</option>
                            <option value="INSTAGRAM" disabled>INSTAGRAM (comming soon)</option>
                            <option value="TIKTOK" disabled>TikTok (comming soon)</option>
                        </select>
                        {errors.platform && (
                            <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                                {errors.platform}
                            </p>
                        )}
                    </div>
                    <div className="w-[49%] text-[1.6rem] inter-font font-[400]">
                        <label className="text-[#111729]">
                            Referral Code{/* <span className="text-red-500">*</span> */}
                        </label>
                        <input
                            type="text"
                            placeholder="Enter a Referral Code"
                            className="w-full px-4 py-4 mt-[.8rem] border border-[#E3E8EF] rounded-lg focus:outline-none placeholder-[#677489]"
                            value={referralCode}
                            onChange={(e) => {
                                setReferralCode(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="w-full my-[2.5rem]">
                    <h1 className="text-[1.6rem] text-[#111729] inter-font font-[400] mb-[.8rem]">
                        Select Campaign Type
                        <span className="text-red-500">*</span>
                    </h1>
                    <div className="flex justify-start items-center">
                        {
                            campaignTypeData?.map((type, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={`w-[18rem] h-[19rem] flex flex-col items-center justify-between mr-[4rem] border rounded-lg cursor-pointer 
                                            ${campaignType === type.campaignType ? "border-[#0D9B50] bg-[#DBEAE6]" : "border-[#E3E8EF] bg-[#FFFFFF]"} 
                                            hover:border-[#0D9B50] hover:bg-[#DBEAE6]/20`
                                        }
                                        onClick={() => setCampaignType(type.campaignType)}
                                    >
                                        <div className="h-[68%] flex items-center justify-center">
                                            <div className="h-[3.1rem]">
                                                <img
                                                    src={type.campaignTypeIcon}
                                                    alt="icon"
                                                    className="w-full h-full"
                                                />
                                            </div>
                                        </div>
                                        <div className="h-[32%] mt auto text-[#111729] inter-font font-[400] text-[1.6rem] text-center">
                                            {type.campaignType}
                                        </div>
                                    </div>
                                )
                            })

                        }
                    </div>
                </div>
                <BottomNavigation isNextDisabled={Object.keys(errors).length > 0} handleNext={handleNext} />
            </div>
        </div>
    );
};

export default BasicDetails;
