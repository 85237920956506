import React from 'react';
import { youtube_redirect_uri } from '../../../store/constants';

const YouTubeOAuth = ({redirectUri}) => {

    console.log("redirectUri : " , redirectUri)
    const handleConnectYouTube = () => {
        const clientId = '647395777111-ihuj3klciv80l73qr0hv8nmne2gud7mv.apps.googleusercontent.com'; // Your Google Client ID
        // const redirectUri = redirectUri;
        const scope = [
            'https://www.googleapis.com/auth/youtube.upload',
            'https://www.googleapis.com/auth/youtube.readonly',
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile',
        ].join(' ');

        // Build the Google OAuth URL
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&prompt=consent&include_granted_scopes=true`;
        // Redirect to Google OAuth2 for authentication
        window.location.href = authUrl;
    };

    // Retrieve the authorization code from the URL after redirect
  /*  React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        console.log("youtube code", code)
        if (code) {
            handleStoreYoutubeAuth(code); // Save the code to state to display on the UI
        }
    }, []); // Run once on component mount*/

    return (
        <button 
            className="w-[21rem] px-4 py-[1rem] text-[2rem] rounded-[1.2rem] cursor-pointer bg-[#0D9B50] text-white hover:bg-[#0D9B50]/90"
            onClick={handleConnectYouTube} 
        >
            Connect to Channel
        </button>
    );
};

export default YouTubeOAuth;
