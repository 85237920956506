export const actionTypes = {
	// Utils
	USER_LOGOUT: 'USER_LOGOUT',
	SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
	SET_SESSON_TOKEN: 'SET_SESSON_TOKEN',
	SET_EMAIL_FOR_LOGIN: 'SET_EMAIL_FOR_LOGIN',
	SET_EMAIL_FOR_FORGET_PASSWORD: 'SET_EMAIL_FOR_FORGET_PASSWORD',
	SET_USER_TYPE: 'SET_USER_TYPE',
	SET_USER_ID: 'SET_USER_ID',
	SET_BRAND_CAMPAIGN_CREATION_STEP: 'SET_BRAND_CAMPAIGN_CREATION_STEP',
	SET_BRAND_CAMPAIGN_CREATION_DATA: 'SET_BRAND_CAMPAIGN_CREATION_DATA',
	SET_IS_MERGE_POPUP_ACTIVE: 'SET_IS_MERGE_POPUP_ACTIVE',
	SET_OPT_AND_SUBMIT_CONTENT_RESPONSE: 'SET_OPT_AND_SUBMIT_CONTENT_RESPONSE',
	SET_STREAM_VIDEO_RESPONSE: 'SET_STREAM_VIDEO_RESPONSE',

	// Public
	GET_ALL_COUNTRAY_REQUEST: 'GET_ALL_COUNTRAY_REQUEST',
	GET_ALL_COUNTRAY_SUCCESS: 'GET_ALL_COUNTRAY_SUCCESS',
	GET_ALL_COUNTRAY_FAILURE: 'GET_ALL_COUNTRAY_FAILURE',
	GET_ALL_COUNTRAY_RESET: 'GET_ALL_COUNTRAY_RESET',
	GET_ALL_COUNTRAY_RESET_ALL: 'GET_ALL_COUNTRAY_RESET_ALL',

	// Auth
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGIN_RESET: 'LOGIN_RESET',
	LOGIN_RESET_ALL: 'LOGIN_RESET_ALL',

	GOOGLE_USER_LOGIN_REQUEST: 'GOOGLE_USER_LOGIN_REQUEST',
	GOOGLE_USER_LOGIN_SUCCESS: 'GOOGLE_USER_LOGIN_SUCCESS',
	GOOGLE_USER_LOGIN_FAILURE: 'GOOGLE_USER_LOGIN_FAILURE',
	GOOGLE_USER_LOGIN_RESET: 'GOOGLE_USER_LOGIN_RESET',
	GOOGLE_USER_LOGIN_RESET_ALL: 'GOOGLE_USER_LOGIN_RESET_ALL',

	GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
	GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
	GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',
	GET_USER_INFO_RESET: 'GET_USER_INFO_RESET',
	GET_USER_INFO_RESET_ALL: 'GET_USER_INFO_RESET_ALL',

	CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
	CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
	CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
	CREATE_USER_RESET: 'CREATE_USER_RESET',
	CREATE_USER_RESET_ALL: 'CREATE_USER_RESET_ALL',

	VERIFY_OTP_AND_CREATE_USER_REQUEST: 'VERIFY_OTP_AND_CREATE_USER_REQUEST',
	VERIFY_OTP_AND_CREATE_USER_SUCCESS: 'VERIFY_OTP_AND_CREATE_USER_SUCCESS',
	VERIFY_OTP_AND_CREATE_USER_FAILURE: 'VERIFY_OTP_AND_CREATE_USER_FAILURE',
	VERIFY_OTP_AND_CREATE_USER_RESET: 'VERIFY_OTP_AND_CREATE_USER_RESET',
	VERIFY_OTP_AND_CREATE_USER_RESET_ALL: 'VERIFY_OTP_AND_CREATE_USER_RESET_ALL',

	SEND_OTP_FOR_FORGET_PASSWORD_REQUEST: 'SEND_OTP_FOR_FORGET_PASSWORD_REQUEST',
	SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS: 'SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS',
	SEND_OTP_FOR_FORGET_PASSWORD_FAILURE: 'SEND_OTP_FOR_FORGET_PASSWORD_FAILURE',
	SEND_OTP_FOR_FORGET_PASSWORD_RESET: 'SEND_OTP_FOR_FORGET_PASSWORD_RESET',
	SEND_OTP_FOR_FORGET_PASSWORD_RESET_ALL: 'SEND_OTP_FOR_FORGET_PASSWORD_RESET_ALL',

	VERIFY_OTP_FOR_FORGET_PASSWORD_REQUEST: 'VERIFY_OTP_FOR_FORGET_PASSWORD_REQUEST',
	VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS: 'VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS',
	VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE: 'VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE',
	VERIFY_OTP_FOR_FORGET_PASSWORD_RESET: 'VERIFY_OTP_FOR_FORGET_PASSWORD_RESET',
	VERIFY_OTP_FOR_FORGET_PASSWORD_RESET_ALL: 'VERIFY_OTP_FOR_FORGET_PASSWORD_RESET_ALL',

	FORGET_PASSWORD_RESET_REQUEST: 'FORGET_PASSWORD_RESET_REQUEST',
	FORGET_PASSWORD_RESET_SUCCESS: 'FORGET_PASSWORD_RESET_SUCCESS',
	FORGET_PASSWORD_RESET_FAILURE: 'FORGET_PASSWORD_RESET_FAILURE',
	FORGET_PASSWORD_RESET_RESET: 'FORGET_PASSWORD_RESET_RESET',
	FORGET_PASSWORD_RESET_RESET_ALL: 'FORGET_PASSWORD_RESET_RESET_ALL',


	SEND_OTP_TO_CREATE_USER_REQUEST: 'SEND_OTP_TO_CREATE_USER_REQUEST',
	SEND_OTP_TO_CREATE_USER_SUCCESS: 'SEND_OTP_TO_CREATE_USER_SUCCESS',
	SEND_OTP_TO_CREATE_USER_FAILURE: 'SEND_OTP_TO_CREATE_USER_FAILURE',
	SEND_OTP_TO_CREATE_USER_RESET: 'SEND_OTP_TO_CREATE_USER_RESET',
	SEND_OTP_TO_CREATE_USER_RESET_ALL: 'SEND_OTP_TO_CREATE_USER_RESET_ALL',

	USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
	USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
	USER_LOGIN_RESET: 'USER_LOGIN_RESET',
	USER_LOGIN_RESET_ALL: 'USER_LOGIN_RESET_ALL',

	VERIFY_OTP_FOR_LOGIN_REQUEST: 'VERIFY_OTP_FOR_LOGIN_REQUEST',
	VERIFY_OTP_FOR_LOGIN_SUCCESS: 'VERIFY_OTP_FOR_LOGIN_SUCCESS',
	VERIFY_OTP_FOR_LOGIN_FAILURE: 'VERIFY_OTP_FOR_LOGIN_FAILURE',
	VERIFY_OTP_FOR_LOGIN_RESET: 'VERIFY_OTP_FOR_LOGIN_RESET',
	VERIFY_OTP_FOR_LOGIN_RESET_ALL: 'VERIFY_OTP_FOR_LOGIN_RESET_ALL',

	GET_ALL_REGION_REQUEST: 'GET_ALL_REGION_REQUEST',
	GET_ALL_REGION_SUCCESS: 'GET_ALL_REGION_SUCCESS',
	GET_ALL_REGION_FAILURE: 'GET_ALL_REGION_FAILURE',
	GET_ALL_REGION_RESET: 'GET_ALL_REGION_RESET',
	GET_ALL_REGION_RESET_ALL: 'GET_ALL_REGION_RESET_ALL',

	GET_COUNTRY_BY_REGION_REQUEST: 'GET_COUNTRY_BY_REGION_REQUEST',
	GET_COUNTRY_BY_REGION_SUCCESS: 'GET_COUNTRY_BY_REGION_SUCCESS',
	GET_COUNTRY_BY_REGION_FAILURE: 'GET_COUNTRY_BY_REGION_FAILURE',
	GET_COUNTRY_BY_REGION_RESET: 'GET_COUNTRY_BY_REGION_RESET',
	GET_COUNTRY_BY_REGION_RESET_ALL: 'GET_COUNTRY_BY_REGION_RESET_ALL',


	GET_STATE_BY_COUNTRY_REQUEST: 'GET_STATE_BY_COUNTRY_REQUEST',
	GET_STATE_BY_COUNTRY_SUCCESS: 'GET_STATE_BY_COUNTRY_SUCCESS',
	GET_STATE_BY_COUNTRY_FAILURE: 'GET_STATE_BY_COUNTRY_FAILURE',
	GET_STATE_BY_COUNTRY_RESET: 'GET_STATE_BY_COUNTRY_RESET',
	GET_STATE_BY_COUNTRY_RESET_ALL: 'GET_STATE_BY_COUNTRY_RESET_ALL',

	GET_ALL_AGE_GROUP_REQUEST: 'GET_ALL_AGE_GROUP_REQUEST',
	GET_ALL_AGE_GROUP_SUCCESS: 'GET_ALL_AGE_GROUP_SUCCESS',
	GET_ALL_AGE_GROUP_FAILURE: 'GET_ALL_AGE_GROUP_FAILURE',
	GET_ALL_AGE_GROUP_RESET: 'GET_ALL_AGE_GROUP_RESET',
	GET_ALL_AGE_GROUP_RESET_ALL: 'GET_ALL_AGE_GROUP_RESET_ALL',


	GET_ALL_NICHE_REQUEST: 'GET_ALL_NICHE_REQUEST',
	GET_ALL_NICHE_SUCCESS: 'GET_ALL_NICHE_SUCCESS',
	GET_ALL_NICHE_FAILURE: 'GET_ALL_NICHE_FAILURE',
	GET_ALL_NICHE_RESET: 'GET_ALL_NICHE_RESET',
	GET_ALL_NICHE_RESET_ALL: 'GET_ALL_NICHE_RESET_ALL',


	GET_BUDGET_REQUEST: 'GET_BUDGET_REQUEST',
	GET_BUDGET_SUCCESS: 'GET_BUDGET_SUCCESS',
	GET_BUDGET_FAILURE: 'GET_BUDGET_FAILURE',
	GET_BUDGET_RESET: 'GET_BUDGET_RESET',
	GET_BUDGET_RESET_ALL: 'GET_BUDGET_RESET_ALL',

	GET_MAX_VIEWS_REQUEST: 'GET_MAX_VIEWS_REQUEST',
	GET_MAX_VIEWS_SUCCESS: 'GET_MAX_VIEWS_SUCCESS',
	GET_MAX_VIEWS_FAILURE: 'GET_MAX_VIEWS_FAILURE',
	GET_MAX_VIEWS_RESET: 'GET_MAX_VIEWS_RESET',
	GET_MAX_VIEWS_RESET_ALL: 'GET_MAX_VIEWS_RESET_ALL',


	GET_BRAND_CAMPAIGN_REQUEST: 'GET_BRAND_CAMPAIGN_REQUEST',
	GET_BRAND_CAMPAIGN_SUCCESS: 'GET_BRAND_CAMPAIGN_SUCCESS',
	GET_BRAND_CAMPAIGN_FAILURE: 'GET_BRAND_CAMPAIGN_FAILURE',
	GET_BRAND_CAMPAIGN_RESET: 'GET_BRAND_CAMPAIGN_RESET',
	GET_BRAND_CAMPAIGN_RESET_ALL: 'GET_BRAND_CAMPAIGN_RESET_ALL',

	GET_ALL_BANNER_TEMPLATE_REQUEST: 'GET_ALL_BANNER_TEMPLATE_REQUEST',
	GET_ALL_BANNER_TEMPLATE_SUCCESS: 'GET_ALL_BANNER_TEMPLATE_SUCCESS',
	GET_ALL_BANNER_TEMPLATE_FAILURE: 'GET_ALL_BANNER_TEMPLATE_FAILURE',
	GET_ALL_BANNER_TEMPLATE_RESET: 'GET_ALL_BANNER_TEMPLATE_RESET',
	GET_ALL_BANNER_TEMPLATE_RESET_ALL: 'GET_ALL_BANNER_TEMPLATE_RESET_ALL',


	UPLOAD_CAMPAIGN_FILE_REQUEST: 'UPLOAD_CAMPAIGN_FILE_REQUEST',
	UPLOAD_CAMPAIGN_FILE_SUCCESS: 'UPLOAD_CAMPAIGN_FILE_SUCCESS',
	UPLOAD_CAMPAIGN_FILE_FAILURE: 'UPLOAD_CAMPAIGN_FILE_FAILURE',
	UPLOAD_CAMPAIGN_FILE_RESET: 'UPLOAD_CAMPAIGN_FILE_RESET',
	UPLOAD_CAMPAIGN_FILE_RESET_ALL: 'UPLOAD_CAMPAIGN_FILE_RESET_ALL',

	CREATE_CAMPAIGN_REQUEST: 'CREATE_CAMPAIGN_REQUEST',
	CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
	CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',
	CREATE_CAMPAIGN_RESET: 'CREATE_CAMPAIGN_RESET',
	CREATE_CAMPAIGN_RESET_ALL: 'CREATE_CAMPAIGN_RESET_ALL',

	GET_MY_BRAND_BUSINESS_REQUEST: 'GET_MY_BRAND_BUSINESS_REQUEST',
	GET_MY_BRAND_BUSINESS_SUCCESS: 'GET_MY_BRAND_BUSINESS_SUCCESS',
	GET_MY_BRAND_BUSINESS_FAILURE: 'GET_MY_BRAND_BUSINESS_FAILURE',
	GET_MY_BRAND_BUSINESS_RESET: 'GET_MY_BRAND_BUSINESS_RESET',
	GET_MY_BRAND_BUSINESS_RESET_ALL: 'GET_MY_BRAND_BUSINESS_RESET_ALL',

	SESRCH_BUSINESS_PLACES_REQUEST: 'SESRCH_BUSINESS_PLACES_REQUEST',
	SESRCH_BUSINESS_PLACES_SUCCESS: 'SESRCH_BUSINESS_PLACES_SUCCESS',
	SESRCH_BUSINESS_PLACES_FAILURE: 'SESRCH_BUSINESS_PLACES_FAILURE',
	SESRCH_BUSINESS_PLACES_RESET: 'SESRCH_BUSINESS_PLACES_RESET',
	SESRCH_BUSINESS_PLACES_RESET_ALL: 'SESRCH_BUSINESS_PLACES_RESET_ALL',

	REGISTER_BUSINESS_REQUEST: 'REGISTER_BUSINESS_REQUEST',
	REGISTER_BUSINESS_SUCCESS: 'REGISTER_BUSINESS_SUCCESS',
	REGISTER_BUSINESS_FAILURE: 'REGISTER_BUSINESS_FAILURE',
	REGISTER_BUSINESS_RESET: 'REGISTER_BUSINESS_RESET',
	REGISTER_BUSINESS_RESET_ALL: 'REGISTER_BUSINESS_RESET_ALL',

	MY_CHANNEL_LIST_REQUEST: 'MY_CHANNEL_LIST_REQUEST',
	MY_CHANNEL_LIST_SUCCESS: 'MY_CHANNEL_LIST_SUCCESS',
	MY_CHANNEL_LIST_FAILURE: 'MY_CHANNEL_LIST_FAILURE',
	MY_CHANNEL_LIST_RESET: 'MY_CHANNEL_LIST_RESET',
	MY_CHANNEL_LIST_RESET_ALL: 'MY_CHANNEL_LIST_RESET_ALL',

	STORE_YOUTUBE_AUTH_REQUEST: 'STORE_YOUTUBE_AUTH_REQUEST',
	STORE_YOUTUBE_AUTH_SUCCESS: 'STORE_YOUTUBE_AUTH_SUCCESS',
	STORE_YOUTUBE_AUTH_FAILURE: 'STORE_YOUTUBE_AUTH_FAILURE',
	STORE_YOUTUBE_AUTH_RESET: 'STORE_YOUTUBE_AUTH_RESET',
	STORE_YOUTUBE_AUTH_RESET_ALL: 'STORE_YOUTUBE_AUTH_RESET_ALL',

	GET_YOUTUBE_CHANNEL_LIST_REQUEST: 'GET_YOUTUBE_CHANNEL_LIST_REQUEST',
	GET_YOUTUBE_CHANNEL_LIST_SUCCESS: 'GET_YOUTUBE_CHANNEL_LIST_SUCCESS',
	GET_YOUTUBE_CHANNEL_LIST_FAILURE: 'GET_YOUTUBE_CHANNEL_LIST_FAILURE',
	GET_YOUTUBE_CHANNEL_LIST_RESET: 'GET_YOUTUBE_CHANNEL_LIST_RESET',
	GET_YOUTUBE_CHANNEL_LIST_RESET_ALL: 'GET_YOUTUBE_CHANNEL_LIST_RESET_ALL',

	STORE_VALIDATE_CHANNEL_REQUEST: 'STORE_VALIDATE_CHANNEL_REQUEST',
	STORE_VALIDATE_CHANNEL_SUCCESS: 'STORE_VALIDATE_CHANNEL_SUCCESS',
	STORE_VALIDATE_CHANNEL_FAILURE: 'STORE_VALIDATE_CHANNEL_FAILURE',
	STORE_VALIDATE_CHANNEL_RESET: 'STORE_VALIDATE_CHANNEL_RESET',
	STORE_VALIDATE_CHANNEL_RESET_ALL: 'STORE_VALIDATE_CHANNEL_RESET_ALL',


	GET_CAMPAIGN_LIST_FILTER_REQUEST: 'GET_CAMPAIGN_LIST_FILTER_REQUEST',
	GET_CAMPAIGN_LIST_FILTER_SUCCESS: 'GET_CAMPAIGN_LIST_FILTER_SUCCESS',
	GET_CAMPAIGN_LIST_FILTER_FAILURE: 'GET_CAMPAIGN_LIST_FILTER_FAILURE',
	GET_CAMPAIGN_LIST_FILTER_RESET: 'GET_CAMPAIGN_LIST_FILTER_RESET',
	GET_CAMPAIGN_LIST_FILTER_RESET_ALL: 'GET_CAMPAIGN_LIST_FILTER_RESET_ALL',


	UPLOAD_INFLUENCER_VIDEO_REQUEST: 'UPLOAD_INFLUENCER_VIDEO_REQUEST',
	UPLOAD_INFLUENCER_VIDEO_SUCCESS: 'UPLOAD_INFLUENCER_VIDEO_SUCCESS',
	UPLOAD_INFLUENCER_VIDEO_FAILURE: 'UPLOAD_INFLUENCER_VIDEO_FAILURE',
	UPLOAD_INFLUENCER_VIDEO_RESET: 'UPLOAD_INFLUENCER_VIDEO_RESET',
	UPLOAD_INFLUENCER_VIDEO_RESET_ALL: 'UPLOAD_INFLUENCER_VIDEO_RESET_ALL',

	STREAM_VIDEO_REQUEST: 'STREAM_VIDEO_REQUEST',
	STREAM_VIDEO_SUCCESS: 'STREAM_VIDEO_SUCCESS',
	STREAM_VIDEO_FAILURE: 'STREAM_VIDEO_FAILURE',
	STREAM_VIDEO_RESET: 'STREAM_VIDEO_RESET',
	STREAM_VIDEO_RESET_ALL: 'STREAM_VIDEO_RESET_ALL',

	OPT_AND_SUBMIT_CONTENT_REQUEST: 'OPT_AND_SUBMIT_CONTENT_REQUEST',
	OPT_AND_SUBMIT_CONTENT_SUCCESS: 'OPT_AND_SUBMIT_CONTENT_SUCCESS',
	OPT_AND_SUBMIT_CONTENT_FAILURE: 'OPT_AND_SUBMIT_CONTENT_FAILURE',
	OPT_AND_SUBMIT_CONTENT_RESET: 'OPT_AND_SUBMIT_CONTENT_RESET',
	OPT_AND_SUBMIT_CONTENT_RESET_ALL: 'OPT_AND_SUBMIT_CONTENT_RESET_ALL',

	CHECK_CONTENT_MERGE_STATUS_REQUEST: 'CHECK_CONTENT_MERGE_STATUS_REQUEST',
	CHECK_CONTENT_MERGE_STATUS_SUCCESS: 'CHECK_CONTENT_MERGE_STATUS_SUCCESS',
	CHECK_CONTENT_MERGE_STATUS_FAILURE: 'CHECK_CONTENT_MERGE_STATUS_FAILURE',
	CHECK_CONTENT_MERGE_STATUS_RESET: 'CHECK_CONTENT_MERGE_STATUS_RESET',
	CHECK_CONTENT_MERGE_STATUS_RESET_ALL: 'CHECK_CONTENT_MERGE_STATUS_RESET_ALL',

	PUBLISH_VIDEO_ON_YOUTUBE_REQUEST: 'PUBLISH_VIDEO_ON_YOUTUBE_REQUEST',
	PUBLISH_VIDEO_ON_YOUTUBE_SUCCESS: 'PUBLISH_VIDEO_ON_YOUTUBE_SUCCESS',
	PUBLISH_VIDEO_ON_YOUTUBE_FAILURE: 'PUBLISH_VIDEO_ON_YOUTUBE_FAILURE',
	PUBLISH_VIDEO_ON_YOUTUBE_RESET: 'PUBLISH_VIDEO_ON_YOUTUBE_RESET',
	PUBLISH_VIDEO_ON_YOUTUBE_RESET_ALL: 'PUBLISH_VIDEO_ON_YOUTUBE_RESET_ALL',

	RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_REQUEST: 'RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_REQUEST',
	RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_SUCCESS: 'RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_SUCCESS',
	RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_FAILURE: 'RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_FAILURE',
	RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_RESET: 'RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_RESET',
	RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_RESET_ALL: 'RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_RESET_ALL',

	UPLOAD_TO_S3_REQUEST: 'UPLOAD_TO_S3_REQUEST',
	UPLOAD_TO_S3_SUCCESS: 'UPLOAD_TO_S3_SUCCESS',
	UPLOAD_TO_S3_FAILURE: 'UPLOAD_TO_S3_FAILURE',
	UPLOAD_TO_S3_RESET: 'UPLOAD_TO_S3_RESET',
	UPLOAD_TO_S3_RESET_ALL: 'UPLOAD_TO_S3_RESET_ALL',




	GET_UPLOADED_UGC_CONTENT_LIST_REQUEST: 'GET_UPLOADED_UGC_CONTENT_LIST_REQUEST',
	GET_UPLOADED_UGC_CONTENT_LIST_SUCCESS: 'GET_UPLOADED_UGC_CONTENT_LIST_SUCCESS',
	GET_UPLOADED_UGC_CONTENT_LIST_FAILURE: 'GET_UPLOADED_UGC_CONTENT_LIST_FAILURE',
	GET_UPLOADED_UGC_CONTENT_LIST_RESET: 'GET_UPLOADED_UGC_CONTENT_LIST_RESET',
	GET_UPLOADED_UGC_CONTENT_LIST_RESET_ALL: 'GET_UPLOADED_UGC_CONTENT_LIST_RESET_ALL',

	MARK_APPROVED_REJECTED_CONTENT_REQUEST: 'MARK_APPROVED_REJECTED_CONTENT_REQUEST',
	MARK_APPROVED_REJECTED_CONTENT_SUCCESS: 'MARK_APPROVED_REJECTED_CONTENT_SUCCESS',
	MARK_APPROVED_REJECTED_CONTENT_FAILURE: 'MARK_APPROVED_REJECTED_CONTENT_FAILURE',
	MARK_APPROVED_REJECTED_CONTENT_RESET: 'MARK_APPROVED_REJECTED_CONTENT_RESET',
	MARK_APPROVED_REJECTED_CONTENT_RESET_ALL: 'MARK_APPROVED_REJECTED_CONTENT_RESET_ALL',

	GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_REQUEST: 'GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_REQUEST',
	GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_SUCCESS: 'GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_SUCCESS',
	GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_FAILURE: 'GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_FAILURE',
	GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_RESET: 'GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_RESET',
	GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_RESET_ALL: 'GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_RESET_ALL',

	GET_WALLET_TXN_WITH_FILTER_REQUEST: 'GET_WALLET_TXN_WITH_FILTER_REQUEST',
	GET_WALLET_TXN_WITH_FILTER_SUCCESS: 'GET_WALLET_TXN_WITH_FILTER_SUCCESS',
	GET_WALLET_TXN_WITH_FILTER_FAILURE: 'GET_WALLET_TXN_WITH_FILTER_FAILURE',
	GET_WALLET_TXN_WITH_FILTER_RESET: 'GET_WALLET_TXN_WITH_FILTER_RESET',
	GET_WALLET_TXN_WITH_FILTER_RESET_ALL: 'GET_WALLET_TXN_WITH_FILTER_RESET_ALL',

	ADD_MONEY_TO_WALLET_REQUEST: 'ADD_MONEY_TO_WALLET_REQUEST',
	ADD_MONEY_TO_WALLET_SUCCESS: 'ADD_MONEY_TO_WALLET_SUCCESS',
	ADD_MONEY_TO_WALLET_FAILURE: 'ADD_MONEY_TO_WALLET_FAILURE',
	ADD_MONEY_TO_WALLET_RESET: 'ADD_MONEY_TO_WALLET_RESET',
	ADD_MONEY_TO_WALLET_RESET_ALL: 'ADD_MONEY_TO_WALLET_RESET_ALL',

	TXN_STATUS_REQUEST: 'TXN_STATUS_REQUEST',
	TXN_STATUS_SUCCESS: 'TXN_STATUS_SUCCESS',
	TXN_STATUS_FAILURE: 'TXN_STATUS_FAILURE',
	TXN_STATUS_RESET: 'TXN_STATUS_RESET',
	TXN_STATUS_RESET_ALL: 'TXN_STATUS_RESET_ALL',

	STRIPE_ONBOARDING_REQUEST: 'STRIPE_ONBOARDING_REQUEST',
	STRIPE_ONBOARDING_SUCCESS: 'STRIPE_ONBOARDING_SUCCESS',
	STRIPE_ONBOARDING_FAILURE: 'STRIPE_ONBOARDING_FAILURE',
	STRIPE_ONBOARDING_RESET: 'STRIPE_ONBOARDING_RESET',
	STRIPE_ONBOARDING_RESET_ALL: 'STRIPE_ONBOARDING_RESET_ALL',

	CHECK_STRIPE_ONBOARDING_STATUS_REQUEST: 'CHECK_STRIPE_ONBOARDING_STATUS_REQUEST',
	CHECK_STRIPE_ONBOARDING_STATUS_SUCCESS: 'CHECK_STRIPE_ONBOARDING_STATUS_SUCCESS',
	CHECK_STRIPE_ONBOARDING_STATUS_FAILURE: 'CHECK_STRIPE_ONBOARDING_STATUS_FAILURE',
	CHECK_STRIPE_ONBOARDING_STATUS_RESET: 'CHECK_STRIPE_ONBOARDING_STATUS_RESET',
	CHECK_STRIPE_ONBOARDING_STATUS_RESET_ALL: 'CHECK_STRIPE_ONBOARDING_STATUS_RESET_ALL',

	REQUEST_PAYOUT_REQUEST: 'REQUEST_PAYOUT_REQUEST',
	REQUEST_PAYOUT_SUCCESS: 'REQUEST_PAYOUT_SUCCESS',
	REQUEST_PAYOUT_FAILURE: 'REQUEST_PAYOUT_FAILURE',
	REQUEST_PAYOUT_RESET: 'REQUEST_PAYOUT_RESET',
	REQUEST_PAYOUT_RESET_ALL: 'REQUEST_PAYOUT_RESET_ALL',

	GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_REQUEST: 'GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_REQUEST',
	GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_SUCCESS: 'GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_SUCCESS',
	GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_FAILURE: 'GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_FAILURE',
	GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_RESET: 'GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_RESET',
	GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_RESET_ALL: 'GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_RESET_ALL',

	GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_REQUEST: 'GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_REQUEST',
	GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_SUCCESS: 'GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_SUCCESS',
	GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_FAILURE: 'GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_FAILURE',
	GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET: 'GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET',
	GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET_ALL: 'GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET_ALL',

	GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_REQUEST: 'GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_REQUEST',
	GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_SUCCESS: 'GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_SUCCESS',
	GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_FAILURE: 'GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_FAILURE',
	GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET: 'GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET',
	GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET_ALL: 'GET_TOTAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_RESET_ALL',

	GET_DASHBOARD_DATA_REQUEST: 'GET_DASHBOARD_DATA_REQUEST',
	GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
	GET_DASHBOARD_DATA_FAILURE: 'GET_DASHBOARD_DATA_FAILURE',
	GET_DASHBOARD_DATA_RESET: 'GET_DASHBOARD_DATA_RESET',
	GET_DASHBOARD_DATA_RESET_ALL: 'GET_DASHBOARD_DATA_RESET_ALL',

	GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_REQUEST: 'GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_REQUEST',
	GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_SUCCESS: 'GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_SUCCESS',
	GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_FAILURE: 'GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_FAILURE',
	GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_RESET: 'GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_RESET',
	GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_RESET_ALL: 'GET_CAMPAIGN_LIST_WITH_FILTER_DASHBOARD_RESET_ALL',

}

