import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {getUserInfoFailure, getUserInfoSuccess} from "../../actions/authActions/getUserInfoActions";

function* getUserInfo({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url,
            endPoint: endpoints.getUserInfo,
            isAuth: true,
        });
        console.log('====================================');
        console.log("GET_USER_INFO_SUCCESS status :", response.data.status);
        console.log("GET_USER_INFO_SUCCESS data :", response.data.response);
        console.log("GET_USER_INFO_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            getUserInfoSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("GET_USER_INFO_FAILURE status :", error?.response?.status);
        console.log("GET_USER_INFO_FAILURE data:", error?.response?.data);
        console.log("GET_USER_INFO_FAILURE error :", error);
        console.log('====================================');
        yield put(getUserInfoFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* getUserInfoSaga() {
    yield takeLatest(actionTypes.GET_USER_INFO_REQUEST, getUserInfo);
}

export default getUserInfoSaga;
