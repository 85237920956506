import React, { useEffect, useState } from 'react';
import './Login.css';
import {useGoogleLogin} from '@react-oauth/google';
import {LoginPageImg} from '../../static/constant/imgConstants';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useToast} from '../../hooks/useToast';
import PublicHeader from '../../components/utility/PublicHeader/PublicHeader';
import VerifyPopup from '../../components/main/VerifyPopup/VerifyPopup';
import {setEmailForLogin, setUserType} from "../../store/publicStore/actions/utilsActions/utilsActions";
import {userLogin, userLoginReset} from "../../store/authStore/actions/authActions/userLoginActions";
import {googleUserLogin, googleUserLoginReset} from "../../store/authStore/actions/authActions/googleUserLoginActions";
import {getUserInfo, getUserInfoReset} from "../../store/authStore/actions/authActions/getUserInfoActions";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Toast = useToast();

    const userLoginReducer = useSelector(state => state.userLoginReducer);
    const getUserInfoReducer = useSelector(state => state.getUserInfoReducer);
    const googleUserLoginReducer = useSelector(state => state.googleUserLoginReducer);

    const [loginType, setLoginType] = useState('Influencer');
    const [loginDailog1, setLoginDailog1] = useState('Unleash Influencer, Forge Connections.');
    const [errors, setErrors] = useState({});
    const [popupMessage, setPopupMessage] = useState(false);

    // Validation for email
    const validateForm = (data) => {
        let formErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!data.email.trim()) {
            formErrors.email = 'Email is required';
        } else if (!emailRegex.test(data.email)) {
            formErrors.email = 'Enter a valid email address';
        }
        return formErrors;
    };

    const handleLogin = (e) => {
        e.preventDefault();
        const formData = {
            email: e.target.elements.email.value,
        };

        const formErrors = validateForm(formData);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            setErrors({});
            dispatch(setUserType(loginType));
            dispatch(setEmailForLogin(formData.email));
            dispatch(userLogin({
                email: formData.email,
                isBrand: loginType === "Brand",
            }));
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                if (tokenResponse) {
                    dispatch(setUserType(loginType));
                    dispatch(googleUserLogin({
                        token: tokenResponse.access_token,
                        isBrand: loginType === "Brand",
                    }));
                }
            } catch (error) {
                console.error('Error fetching Google user info:', error);
            }
        },
        onError: errorResponse => console.log('Google Login Error', errorResponse),
    });

    useEffect(() => {
        if (userLoginReducer.status === 200) {
            Toast(userLoginReducer.message, 'success');
            dispatch(userLoginReset());
            navigate(`/verifyEmailOtp/${loginType}`);
        } else if (userLoginReducer.status === 403) {
            Toast(userLoginReducer.message, 'error');
            dispatch(userLoginReset());
        } else if (userLoginReducer.status) {
            Toast(userLoginReducer.message, 'info');
            dispatch(userLoginReset());
        }
    }, [userLoginReducer]);


    useEffect(() => {
        if (googleUserLoginReducer.status === 200) {
            Toast(googleUserLoginReducer.message, 'success');
            dispatch(getUserInfo());
            dispatch(googleUserLoginReset());
        } else if (googleUserLoginReducer.status === 403) {
            Toast(googleUserLoginReducer.message, 'error');
            dispatch(googleUserLoginReset());
        } else if (googleUserLoginReducer.status === 305) {
            setPopupMessage(googleUserLoginReducer.message)
            dispatch(googleUserLoginReset());
        } else if (googleUserLoginReducer.status) {
            Toast(googleUserLoginReducer.message, 'info');
            dispatch(googleUserLoginReset());
        }
    }, [googleUserLoginReducer]);

    useEffect(() => {
        if (getUserInfoReducer.status === 200) {
            dispatch(getUserInfoReset());
            if(loginType === "Brand")navigate(`/brand`);
            else navigate(`/influencer`);
        } else if (getUserInfoReducer.status === 403) {
            Toast(getUserInfoReducer.message, 'error');
            dispatch(getUserInfoReset());
        } else if (getUserInfoReducer.status) {
            Toast(getUserInfoReducer.message, 'info');
            dispatch(getUserInfoReset());
        }
    }, [getUserInfoReducer]);

    return (
        <div className='flex flex-col lg:flex-row w-full h-full items-center lg:items-start overflow-auto lg:overflow-hidden'>
            <VerifyPopup message={popupMessage} isOpen={popupMessage} modalAction={() => navigate('/')} type="warning"/>
            <div className='flex flex-col justify-start items-center w-full lg:w-[58%] min-h-max xl:h-full px-[4.8rem] py-[3rem]'>
                <PublicHeader />
                <div className='flex flex-grow flex-col justify-between items-center gap-[2rem] mt-[2rem] lg:mt-[0rem] text-[1.4rem] lg:text-[1.6rem] mt-[0.5rem] lg:mt-[1.5rem] w-[90%] md:w-[60%]  lg:w-[45%] xl:w-[50%] min-h-max '>
                    <div className='login-nav flex justify-strat items-center w-full'>
                        <h4
                            className={`text-[1.6rem] lg:text-[1.8rem] cursor-pointer inter-font font-[600] ${loginType === 'Brand' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'}`}
                            onClick={() => { setLoginType('Brand'); setLoginDailog1('Unleash Brand, Forge Connections.') }}
                        >
                            Brand
                        </h4>
                        <h4
                            className={`text-[1.6rem] lg:text-[1.8rem] cursor-pointer mx-auto inter-font font-[600] ${loginType === 'Influencer' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'}`}
                            onClick={() => { setLoginType('Influencer'); setLoginDailog1('Unleash Influencer, Forge Connections.') }}
                        >
                            Influencer
                        </h4>
                    </div>
                    <div className='flex flex-col items-start w-full mt-[2rem] lg:mt-[6rem]'>
                        <h2 className="text-[1.4rem] lg:text-[1.6rem] inter-font font-[500] text-[#9B9DA1]">{loginDailog1}</h2>
                        <p className="text-[1.4rem] lg:text-[1.6rem] inter-font font-[400]">Login to your account!</p>
                    </div>
                    <form onSubmit={handleLogin} className='w-full'>
                        <div className='w-full'>
                            <label className="text-[1.6rem] text-[#111729] roboto-thin font-[400]">Email ID</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                className={`w-full px-[1.6rem] py-[.8rem] bg-[#F3F9FA] roboto-thin font-[400] mb-[2rem] lg:mb-[0rem] border rounded-[1.2rem] focus:outline-none ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.email && <p className="text-left text-red-500">{errors.email}</p>}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-[#0D9B50] text-[1.8rem] lg:text-[2rem] roboto-thin font-[500] text-white py-[1rem] lg:py-[1.2rem] mb-[1rem] mt-[0.5rem] md:mt-[1rem] lg:mt-[2rem] rounded-[1.2rem] hover:bg-green-700"
                        >
                            Sign in
                        </button>
                    </form>
                    <div className="flex items-center justify-center w-full">
                        <span className="bg-gray-300 h-px w-full"></span>
                        <span className="px-4 inter-font font-[500] text-gray-500">Or</span>
                        <span className="bg-gray-300 h-px w-full"></span>
                    </div>
                    <div className="w-full mb-[0.5rem] md:mb-[3rem]">
                        <button
                            onClick={() => googleLogin()}
                            className="w-full flex justify-center roboto-thin font-[400] items-center py-[0.8rem] bg-[#F3F9FA]/80 text-[1.3rem] rounded-[1.2rem] hover:bg-[#F3F9FA]">
                            <img src={LoginPageImg.google} alt="Google" className="w-[2.8rem] h-[2.8rem] mr-[0.8rem]" />
                            Sign in with Google
                        </button>
                    </div>
                    <div className="text-center text-[1.4rem] lg:text-[1.6rem] inter-font font-[400] text-[#313957] flex ">
                        Don’t have an account? <p onClick={() => { navigate('/registration') }} className="text-[#1E4AE9] cursor-pointer">&nbsp;Sign up</p>
                    </div>
                    <div className="flex justify-center roboto-thin font-[400] items-center text-[#959CB6] text-center text-[1.4rem] lg:text-[1.6rem] mt-auto">
                        © 2024 ALL RIGHTS RESERVED
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center w-[90%] md:w-[60%] lg:w-[42%] min-h-max lg:h-full custom-div">
                <img src={LoginPageImg.loginlogo} alt="" className="w-full h-full" />
            </div>

        </div>
    );
};

export default Login;
