export const base_url = "https://dev.paidpromo.ai/api" // dev base URL

// export const youtube_redirect_uri = "http://localhost:3000/influencer-channel" // local base URL
export const youtube_redirect_uri = "https://dev.paidpromo.ai/influencer-channel" // dev base URL
export const youtube_redirect_uri_auth_only = "https://dev.paidpromo.ai/influencer-channel-auth" // dev base URL
// export const youtube_redirect_uri_auth_only = "http://localhost:3000/influencer-channel-auth" // dev base URL


export const payment_redirect_uri = "https://dev.paidpromo.ai/brand/transactions?sessionId={CHECKOUT_SESSION_ID}&paymentStatus=success" // local base URL
// export const payment_redirect_uri = "http://localhost:3000/brand/transactions?sessionId={CHECKOUT_SESSION_ID}&paymentStatus=" // local base URL


export const endpoints = {
    login: "/paidpromo/auth/login", // Generate OTP
    googleUserLogin: "/paidpromo/auth/googleUserLogin", // Generate OTP
    getUserInfo: "/paidpromo/user/me", // Get Logged in User Details
    createUser: "/paidpromo/user/sendOtpToCreateUser", // Send Otp To Create User
    verifyOtpAndCreateUser: "/paidpromo/user/verifyOtpAndCreateUser", // Verify Otp To Create User
    sendOtpForForgetPassword: "/paidpromo/auth/sendOtpForForgetPassword", // send Otp For Forget Password
    verifyOtpForForgetPassword: "/paidpromo/auth/verifyOtpForForgetPassword", // verify Otp For Forget Password
    forgetPasswordReset: "/paidpromo/auth/forgetPasswordReset", // reset Forget Password
    getAllCountry: "/paidpromo/country/getAllCountry", // reset Forget Password
    sendOtpToCreateUser: "/paidpromo/user/sendOtpToCreateUser",
    userLogin: "/paidpromo/auth/userLogin",
    verifyOtpForLogin: "/paidpromo/auth/verifyOtpForLogin",
    getAllRegion: "/paidpromo/region/getAllRegion",
    getCountryByRegion: "/paidpromo/country/getCountryByRegion?regionId=",
    getStateByCountry: "/paidpromo/state/getStateByCountry?countryId=",
    getAllAgeGroup: "/paidpromo/ageGroup/getAllAgeGroup",
    getAllNiche: "/paidpromo/niche/getAllNiche",
    getBudget: "/paidpromo/campaign/getBudget",
    getMaxViews: "/paidpromo/campaign/getMaxViews",
    getBrandCampaign: "/paidpromo/campaign/getBrandCampaign",
    getAllBannerTemplate: "/paidpromo/bannerTemplate/getAllBannerTemplate",
    uploadCampaignFile: "/paidpromo/campaign/uploadCampaignFile",
    createCampaign: "/paidpromo/campaign/createCampaign",
    getMyBrandBusinessDetail: "/paidpromo/brandBusiness/getMyBrandBusinessDetail",
    searchBusinessPlace: "/paidpromo/brandBusiness/searchBusinessPlace",
    registerBusiness: "/paidpromo/brandBusiness/registerBusiness",
    myChannelList: "/paidpromo/influencerPlatformChannel/myChannelList",
    storeYoutubeAuth: "/paidpromo/influencerPlatformAccount/storeYoutubeAuth",
    getYoutubeChannelList: "/paidpromo/influencerPlatformAccount/getYoutubeChannelList",
    storeValidateChannel: "/paidpromo/influencerPlatformChannel/storeValidateChannel",
    getCampaignListWithFilter: "/paidpromo/campaign/getCampaignListWithFilter",
    uploadInfluencerVideo: "/paidpromo/contentUpload/uploadInfluencerVideo?contentPath=",
    optAndSubmitContent: "/paidpromo/campaign/optAndSubmitContent",
    checkContentMergeStatus: "/paidpromo/campaign/checkContentMergeStatus",
    publishVideoOnYoutube: "/paidpromo/contentUpload/publishVideoOnYoutube",
    resubmissionOptAndSubmitContentUGC: "/paidpromo/campaign/resubmissionOptAndSubmitContentUGC",
    uploadToS3: "",
    streamVideo: "/paidpromo/contentUpload/streamVideo",
    getUploadedUGCContentList: "/paidpromo/contentUpload/getUploadedUGCContentList",
    markApprovedRejectedContent: "/paidpromo/contentUpload/markApprovedRejectedContent",
    getUploadedUGCContentForInfluencer: "/paidpromo/contentUpload/getUploadedUGCContentForInfluencer",
    getWalletTxnWithFilter: "/paidpromo/walletTxn/getWalletTxnWithFilter",
    addMoneyToWallet: "/paidpromo/wallet/addMoneyToWallet",
    txnStatus: "/paidpromo/wallet/txnStatus",
    stripeOnboarding: "/paidpromo/wallet/stripeOnboarding",
    checkStripeOnboardingStatus: "/paidpromo/wallet/checkStripeOnboardingStatus",
    requestPayout: "/paidpromo/wallet/requestPayout",
    getCampaignDetailsForDashboard: "/paidpromo/campaign/getCampaignDetailsForDashboard",
    getPotentialIncomeMissedByMonthAndYearForDashboard: "/paidpromo/campaign/getPotentialIncomeMissedByMonthAndYearForDashboard",
    getTotalIncomeByMonthAndYearForDashboard: "/paidpromo/campaign/getTotalIncomeByMonthAndYearForDashboard",
    getDashboardData: "/paidpromo/campaign/getDashboardData",
    getCampaignListWithFilterDashBoard: "/paidpromo/campaign/getCampaignListWithFilterDashBoard",
}
