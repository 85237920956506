import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getAllRegion = (payload) => ({
    type: actionTypes.GET_ALL_REGION_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllRegionSuccess = (payload) => ({
    type: actionTypes.GET_ALL_REGION_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllRegionFailure = (payload) => ({
    type: actionTypes.GET_ALL_REGION_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getAllRegionReset = () => ({
    type: actionTypes.GET_ALL_REGION_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllRegionResetAll = () => ({
    type: actionTypes.GET_ALL_REGION_RESET_ALL,
}); 