import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const streamVideo = (payload, callback) => ({
    type: actionTypes.STREAM_VIDEO_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const streamVideoSuccess = (payload) => ({
    type: actionTypes.STREAM_VIDEO_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const streamVideoFailure = (payload) => ({
    type: actionTypes.STREAM_VIDEO_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const streamVideoReset = () => ({
    type: actionTypes.STREAM_VIDEO_RESET,
});

// Action creator for resetting all OTP generation reducer
export const streamVideoResetAll = () => ({
    type: actionTypes.STREAM_VIDEO_RESET_ALL,
});
