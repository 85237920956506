import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {storeValidateChannelFailure, storeValidateChannelSuccess} from "../../actions/InfluencerChannelActions/storeValidateChannelActions";

function* storeValidateChannel({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.storeValidateChannel,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("STORE_VALIDATE_CHANNEL_SUCCESS status :", response.data.status);
        console.log("STORE_VALIDATE_CHANNEL_SUCCESS data :", response.data.response);
        console.log("STORE_VALIDATE_CHANNEL_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            storeValidateChannelSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("STORE_VALIDATE_CHANNEL_FAILURE status :", error?.response?.status);
        console.log("STORE_VALIDATE_CHANNEL_FAILURE data:", error?.response?.data);
        console.log("STORE_VALIDATE_CHANNEL_FAILURE error :", error);
        console.log('====================================');
        yield put(storeValidateChannelFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* storeValidateChannelSaga() {
    yield takeLatest(actionTypes.STORE_VALIDATE_CHANNEL_REQUEST, storeValidateChannel);
}

export default storeValidateChannelSaga;
