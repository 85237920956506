import { all } from "redux-saga/effects";

import getAllCountrySaga from "../publicStore/sagas/publicSagas/getAllCountrySaga";

import loginSaga from "../authStore/sagas/authSagas/loginSaga";
import googleUserLoginSaga from "../authStore/sagas/authSagas/googleUserLoginSaga";
import getUserInfoSaga from "../authStore/sagas/authSagas/getUserInfoSaga";
import createUserSaga from "../authStore/sagas/authSagas/createUserSaga";
import verifyOtpAndCreateUserSaga from "../authStore/sagas/authSagas/verifyOtpAndCreateUserSaga";
import sendOtpForForgetPasswordSaga from "../authStore/sagas/authSagas/sendOtpForForgetPasswordSaga";
import verifyOtpForForgetPasswordSaga from "../authStore/sagas/authSagas/verifyOtpForForgetPasswordSaga";
import forgetPasswordResetSaga from "../authStore/sagas/authSagas/forgetPasswordResetSaga";
import sendOtpToCreateUserSaga from "../authStore/sagas/authSagas/sendOtpToCreateUserSaga";
import userLoginSaga from "../authStore/sagas/authSagas/userLoginSaga";
import verifyOtpForLoginSaga from "../authStore/sagas/authSagas/verifyOtpForLoginSaga";

import getAllRegionSaga from "../campaignStore/sagas/campaignMasterSagas/getAllRegionSaga";
import getCountryByRegionSaga from "../campaignStore/sagas/campaignMasterSagas/getCountryByRegionSaga";
import getStateByCountrySaga from "../campaignStore/sagas/campaignMasterSagas/getStateByCountrySaga";
import getAllAgeGroupSaga from "../campaignStore/sagas/campaignMasterSagas/getAllAgeGroupSaga";
import getAllNicheSaga from "../campaignStore/sagas/campaignMasterSagas/getAllNicheSaga";

import getBudgetSaga from "../campaignStore/sagas/createCampaignSagas/getBudgetSaga";
import getMaxViewsSaga from "../campaignStore/sagas/createCampaignSagas/getMaxViewsSaga";
import getBrandCampaignSaga from "../campaignStore/sagas/createCampaignSagas/getBrandCampaignSaga";
import getAllBannerTemplateSaga from "../campaignStore/sagas/createCampaignSagas/getAllBannerTemplateSaga";
import uploadCampaignFileSaga from "../campaignStore/sagas/createCampaignSagas/uploadCampaignFileSaga";
import createCampaignSaga from "../campaignStore/sagas/createCampaignSagas/createCampaignSaga";
import getMyBrandBusinessDetailSaga from "../BusinessStore/sagas/BrandBusinessSagas/getMyBrandBusinessDetailSaga";
import searchBusinessPlaceSaga from "../BusinessStore/sagas/BrandBusinessSagas/searchBusinessPlaceSaga";
import registerBusinessSaga from "../BusinessStore/sagas/BrandBusinessSagas/registerBusinessSaga";
import myChannelListSaga from "../BusinessStore/sagas/InfluencerChannelSagas/myChannelListSaga";
import storeYoutubeAuthSaga from "../BusinessStore/sagas/InfluencerChannelSagas/storeYoutubeAuthSaga";
import getYoutubeChannelListSaga from "../BusinessStore/sagas/InfluencerChannelSagas/getYoutubeChannelListSaga";
import storeValidateChannelSaga from "../BusinessStore/sagas/InfluencerChannelSagas/storeValidateChannelSaga";
import getCampaignListWithFilterSaga
    from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/getCampaignListFilterSaga";
import streamVideoSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/streamVideoSaga";
import uploadInfluencerVideoSaga
    from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/uploadInfluencerVideoSaga";
import optAndSubmitContentSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/optAndSubmitContentSaga";
import checkContentMergeStatusSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/checkContentMergeStatusSaga";
import publishVideoOnYoutubeSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/publishVideoOnYoutubeSaga";
import resubmissionOptAndSubmitContentUGCSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/resubmissionOptAndSubmitContentUGCSaga";
import uploadToS3Saga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/uploadToS3Saga";
import getUploadedUGCContentListSaga from "../campaignStore/sagas/createCampaignSagas/getUploadedUGCContentListSaga";
import markApprovedRejectedContentSaga from "../campaignStore/sagas/createCampaignSagas/markApprovedRejectedContentSaga";
import getUploadedUGCContentForInfluencerSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/getUploadedUGCContentForInfluencerSaga";
import getWalletTxnWithFilterSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/getWalletTxnWithFilterSaga.js";
import addMoneyToWalletSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/addMoneyToWalletSaga.js";
import txnStatusSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/txnStatusSaga.js";
import stripeOnboardingSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/stripeOnboardingSaga.js";
import checkStripeOnboardingStatusSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/checkStripeOnboardingStatusSaga.js";
import requestPayoutSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/requestPayoutSaga.js";
import getCampaignDetailsForDashboardSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/getCampaignDetailsForDashboardSaga.js";
import getPotentialIncomeMissedByMonthAndYearForDashboardSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/getPotentialIncomeMissedByMonthAndYearForDashboardSaga.js";
import getTotalIncomeByMonthAndYearForDashboardSaga from "../influencerCampaignStore/sagas/InfluencerCampaignSagas/getTotalIncomeByMonthAndYearForDashboardSaga.js";
import getDashboardDataSaga from "../brandDashboardStore/sagas/getDashboardDataSaga.js";
import getCampaignListWithFilterDashBoardSaga from "../brandDashboardStore/sagas/getCampaignListWithFilterDashBoardSaga.js";

export default function* rootSaga() {
    yield all([
        // public sagas
        getAllCountrySaga(),

        // auth sagas
        loginSaga(),
        googleUserLoginSaga(),
        getUserInfoSaga(),
        createUserSaga(),
        verifyOtpAndCreateUserSaga(),
        sendOtpForForgetPasswordSaga(),
        verifyOtpForForgetPasswordSaga(),
        forgetPasswordResetSaga(),
        sendOtpToCreateUserSaga(),
        userLoginSaga(),
        verifyOtpForLoginSaga(),

        // Campaign Master Sagas
        getAllRegionSaga(),
        getCountryByRegionSaga(),
        getStateByCountrySaga(),
        getAllAgeGroupSaga(),
        getAllNicheSaga(),

        // Create Campaign Sagas
        getBudgetSaga(),
        getMaxViewsSaga(),
        getBrandCampaignSaga(),
        getAllBannerTemplateSaga(),
        uploadCampaignFileSaga(),
        createCampaignSaga(),
        getMyBrandBusinessDetailSaga(),
        searchBusinessPlaceSaga(),
        registerBusinessSaga(),
        myChannelListSaga(),
        storeYoutubeAuthSaga(),
        getYoutubeChannelListSaga(),
        storeValidateChannelSaga(),
        getUploadedUGCContentListSaga(),
        markApprovedRejectedContentSaga(),

        // Influencer Campaign Sagas
        getCampaignListWithFilterSaga(),
        streamVideoSaga(),
        uploadInfluencerVideoSaga(),
        optAndSubmitContentSaga(),
        checkContentMergeStatusSaga(),
        publishVideoOnYoutubeSaga(),
        resubmissionOptAndSubmitContentUGCSaga(),
        uploadToS3Saga(),
        streamVideoSaga(),
        getUploadedUGCContentForInfluencerSaga(),
        getWalletTxnWithFilterSaga(),
        addMoneyToWalletSaga(),
        txnStatusSaga(),
        stripeOnboardingSaga(),
        checkStripeOnboardingStatusSaga(),
        requestPayoutSaga(),
        getCampaignDetailsForDashboardSaga(),
        getPotentialIncomeMissedByMonthAndYearForDashboardSaga(),
        getTotalIncomeByMonthAndYearForDashboardSaga(),
        getDashboardDataSaga(),
        getCampaignListWithFilterDashBoardSaga(),
    ])
}
