import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { getAllRegionFailure, getAllRegionSuccess } from '../../actions/CampaignMasterActions/getAllRegionActions';

function* getAllRegion({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url,
            endPoint: endpoints.getAllRegion,
            isAuth: true,
        });
        console.log('====================================');
        console.log("GET_ALL_REGION_SUCCESS status :", response.data.status);
        console.log("GET_ALL_REGION_SUCCESS data :", response.data.response);
        console.log("GET_ALL_REGION_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            getAllRegionSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("GET_ALL_REGION_FAILURE status :", error?.response?.status);
        console.log("GET_ALL_REGION_FAILURE data:", error?.response?.data);
        console.log("GET_ALL_REGION_FAILURE error :", error);
        console.log('====================================');
        yield put(getAllRegionFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* getAllRegionSaga() {
    yield takeLatest(actionTypes.GET_ALL_REGION_REQUEST, getAllRegion);
}

export default getAllRegionSaga;