import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { getMaxViewsFailure, getMaxViewsSuccess } from '../../actions/CreateCampaignActions/getMaxViewsActions';

function* getMaxViews({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.getMaxViews,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("GET_MAX_VIEWS_SUCCESS status :", response.data.status);
        console.log("GET_MAX_VIEWS_SUCCESS data :", response.data.response);
        console.log("GET_MAX_VIEWS_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            getMaxViewsSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("GET_MAX_VIEWS_FAILURE status :", error?.response?.status);
        console.log("GET_MAX_VIEWS_FAILURE data:", error?.response?.data);
        console.log("GET_MAX_VIEWS_FAILURE error :", error);
        console.log('====================================');
        yield put(getMaxViewsFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* getMaxViewsSaga() {
    yield takeLatest(actionTypes.GET_MAX_VIEWS_REQUEST, getMaxViews);
}

export default getMaxViewsSaga;