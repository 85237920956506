import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Forbidden = () => {
    const userType = useSelector((state) => state.utilReducers?.userType);

    // Determine the home route based on userType
    const homeRoute =
        userType === "Brand"
            ? "/brand"
            : userType === "Influencer"
            ? "/influencer"
            : "/agency";

    return (
        <div className="h-screen bg-gray-100 flex flex-col items-center justify-center space-y-6">
            <h1 className="text-6xl font-extrabold text-red-600">403</h1>
            <h2 className="text-3xl font-semibold text-gray-800">Forbidden</h2>
            <p className="text-lg text-gray-500 max-w-lg text-center">
                You don't have permission to access this page. If you believe
                this is an error, please contact support.
            </p>
            <Link
                to={homeRoute}
                className="mt-4 px-6 py-3 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-500 transition duration-300"
            >
                Go to Home
            </Link>
        </div>
    );
};

export default Forbidden;
