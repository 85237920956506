import { actionTypes } from '../../../types';

export const resubmissionOptAndSubmitContentUGC = (payload, callback) => ({
    type: actionTypes.RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_REQUEST,
    payload,
    callback
});

export const resubmissionOptAndSubmitContentUGCSuccess = (payload) => ({
    type: actionTypes.RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_SUCCESS,
    payload,
});

export const resubmissionOptAndSubmitContentUGCFailure = (payload) => ({
    type: actionTypes.RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_FAILURE,
    payload,
});

export const resubmissionOptAndSubmitContentUGCReset = () => ({
    type: actionTypes.RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_RESET,
});

export const resubmissionOptAndSubmitContentUGCResetAll = () => ({
    type: actionTypes.RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_RESET_ALL,
});
