import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getMaxViews = (payload) => ({
    type: actionTypes.GET_MAX_VIEWS_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getMaxViewsSuccess = (payload) => ({
    type: actionTypes.GET_MAX_VIEWS_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getMaxViewsFailure = (payload) => ({
    type: actionTypes.GET_MAX_VIEWS_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getMaxViewsReset = () => ({
    type: actionTypes.GET_MAX_VIEWS_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getMaxViewsResetAll = () => ({
    type: actionTypes.GET_MAX_VIEWS_RESET_ALL,
}); 