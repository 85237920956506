import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getAllAgeGroup = (payload) => ({
    type: actionTypes.GET_ALL_AGE_GROUP_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllAgeGroupSuccess = (payload) => ({
    type: actionTypes.GET_ALL_AGE_GROUP_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllAgeGroupFailure = (payload) => ({
    type: actionTypes.GET_ALL_AGE_GROUP_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getAllAgeGroupReset = () => ({
    type: actionTypes.GET_ALL_AGE_GROUP_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllAgeGroupResetAll = () => ({
    type: actionTypes.GET_ALL_AGE_GROUP_RESET_ALL,
}); 