import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const checkStripeOnboardingStatus = (payload, callback) => ({
    type: actionTypes.CHECK_STRIPE_ONBOARDING_STATUS_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const checkStripeOnboardingStatusSuccess = (payload) => ({
    type: actionTypes.CHECK_STRIPE_ONBOARDING_STATUS_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const checkStripeOnboardingStatusFailure = (payload) => ({
    type: actionTypes.CHECK_STRIPE_ONBOARDING_STATUS_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const checkStripeOnboardingStatusReset = () => ({
    type: actionTypes.CHECK_STRIPE_ONBOARDING_STATUS_RESET,
});

// Action creator for resetting all OTP generation reducer
export const checkStripeOnboardingStatusResetAll = () => ({
    type: actionTypes.CHECK_STRIPE_ONBOARDING_STATUS_RESET_ALL,
});
