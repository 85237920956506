import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { markApprovedRejectedContentFailure, markApprovedRejectedContentSuccess } from '../../actions/CreateCampaignActions/markApprovedRejectedContentActions';

function* markApprovedRejectedContent({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.markApprovedRejectedContent,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("MARK_APPROVED_REJECTED_CONTENT_SUCCESS status :", response.data.status);
        console.log("MARK_APPROVED_REJECTED_CONTENT_SUCCESS data :", response.data.response);
        console.log("MARK_APPROVED_REJECTED_CONTENT_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            markApprovedRejectedContentSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("MARK_APPROVED_REJECTED_CONTENT_FAILURE status :", error?.response?.status);
        console.log("MARK_APPROVED_REJECTED_CONTENT_FAILURE data:", error?.response?.data);
        console.log("MARK_APPROVED_REJECTED_CONTENT_FAILURE error :", error);
        console.log('====================================');
        yield put(markApprovedRejectedContentFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* markApprovedRejectedContentSaga() {
    yield takeLatest(actionTypes.MARK_APPROVED_REJECTED_CONTENT_REQUEST, markApprovedRejectedContent);
}

export default markApprovedRejectedContentSaga;