import React from 'react';
import {useNavigate} from "react-router-dom";


const Breadcrumbs = ({ breadcrumbs }) => {


    const navigate = useNavigate();

    return (
        <header className="w-full h-[7rem] flex justify-start items-end text-[1.4rem] text-[#38414A] bg-[#EDEEF1] pb-[1.4rem] cursor-pointer px-[4rem]">
            {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={index}>
                    <a
                       onClick={()=>{navigate(breadcrumb.href)}}
                        className={`hover:underline inter-font font-[500] ${
                            index === breadcrumbs.length - 1 ? 'text-[#0D9B50]' : ''
                        }`}
                    >
                        {breadcrumb.label}
                    </a>
                    {index < breadcrumbs.length - 1 && (
                        <span className="mx-[.4rem]">{'>'}</span>
                    )}
                </React.Fragment>
            ))}
        </header>
    );
};

export default Breadcrumbs;

