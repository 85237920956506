import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getAllBannerTemplate = (payload) => ({
    type: actionTypes.GET_ALL_BANNER_TEMPLATE_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllBannerTemplateSuccess = (payload) => ({
    type: actionTypes.GET_ALL_BANNER_TEMPLATE_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllBannerTemplateFailure = (payload) => ({
    type: actionTypes.GET_ALL_BANNER_TEMPLATE_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getAllBannerTemplateReset = () => ({
    type: actionTypes.GET_ALL_BANNER_TEMPLATE_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllBannerTemplateResetAll = () => ({
    type: actionTypes.GET_ALL_BANNER_TEMPLATE_RESET_ALL,
}); 