import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const registerBusiness = (payload, callback) => ({
    type: actionTypes.REGISTER_BUSINESS_REQUEST,
    payload,
    callback,
});

// Action creator for successful OTP generation
export const registerBusinessSuccess = (payload) => ({
    type: actionTypes.REGISTER_BUSINESS_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const registerBusinessFailure = (payload) => ({
    type: actionTypes.REGISTER_BUSINESS_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const registerBusinessReset = () => ({
    type: actionTypes.REGISTER_BUSINESS_RESET,
});

// Action creator for resetting all OTP generation reducer
export const registerBusinessResetAll = () => ({
    type: actionTypes.REGISTER_BUSINESS_RESET_ALL,
});
