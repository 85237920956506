import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getBudget = (payload) => ({
    type: actionTypes.GET_BUDGET_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getBudgetSuccess = (payload) => ({
    type: actionTypes.GET_BUDGET_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getBudgetFailure = (payload) => ({
    type: actionTypes.GET_BUDGET_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getBudgetReset = () => ({
    type: actionTypes.GET_BUDGET_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getBudgetResetAll = () => ({
    type: actionTypes.GET_BUDGET_RESET_ALL,
}); 