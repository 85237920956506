import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const searchBusinessPlace = (payload, callback) => ({
    type: actionTypes.SESRCH_BUSINESS_PLACES_REQUEST,
    payload,
    callback,
});

// Action creator for successful OTP generation
export const searchBusinessPlaceSuccess = (payload) => ({
    type: actionTypes.SESRCH_BUSINESS_PLACES_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const searchBusinessPlaceFailure = (payload) => ({
    type: actionTypes.SESRCH_BUSINESS_PLACES_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const searchBusinessPlaceReset = () => ({
    type: actionTypes.SESRCH_BUSINESS_PLACES_RESET,
});

// Action creator for resetting all OTP generation reducer
export const searchBusinessPlaceResetAll = () => ({
    type: actionTypes.SESRCH_BUSINESS_PLACES_RESET_ALL,
});
