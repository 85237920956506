import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getAllNiche = (payload) => ({
    type: actionTypes.GET_ALL_NICHE_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllNicheSuccess = (payload) => ({
    type: actionTypes.GET_ALL_NICHE_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllNicheFailure = (payload) => ({
    type: actionTypes.GET_ALL_NICHE_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getAllNicheReset = () => ({
    type: actionTypes.GET_ALL_NICHE_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllNicheResetAll = () => ({
    type: actionTypes.GET_ALL_NICHE_RESET_ALL,
}); 