import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getDashboardData = (payload, callback) => ({
    type: actionTypes.GET_DASHBOARD_DATA_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const getDashboardDataSuccess = (payload) => ({
    type: actionTypes.GET_DASHBOARD_DATA_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getDashboardDataFailure = (payload) => ({
    type: actionTypes.GET_DASHBOARD_DATA_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getDashboardDataReset = () => ({
    type: actionTypes.GET_DASHBOARD_DATA_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getDashboardDataResetAll = () => ({
    type: actionTypes.GET_DASHBOARD_DATA_RESET_ALL,
});
