import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const uploadInfluencerVideo = (payload, callback) => ({
    type: actionTypes.UPLOAD_INFLUENCER_VIDEO_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const uploadInfluencerVideoSuccess = (payload) => ({
    type: actionTypes.UPLOAD_INFLUENCER_VIDEO_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const uploadInfluencerVideoFailure = (payload) => ({
    type: actionTypes.UPLOAD_INFLUENCER_VIDEO_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const uploadInfluencerVideoReset = () => ({
    type: actionTypes.UPLOAD_INFLUENCER_VIDEO_RESET,
});

// Action creator for resetting all OTP generation reducer
export const uploadInfluencerVideoResetAll = () => ({
    type: actionTypes.UPLOAD_INFLUENCER_VIDEO_RESET_ALL,
});
