import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { TbHome } from "react-icons/tb";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { IoIosHeartEmpty } from "react-icons/io";
import { RxGear } from "react-icons/rx";
import { AiOutlineLogout } from "react-icons/ai";
import { logout } from "../../../store/publicStore/actions/utilsActions/utilsActions";
import { FaWallet } from 'react-icons/fa';

const BrandSidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const isSelected = (...paths) => paths.includes(location.pathname);

    // Sidebar data state
    const [sidebarData, setSidebarData] = useState([
        { id: "1", title: "Home", icon: <TbHome size={20} className="mx-[1.8rem]" />, path: "/brand", isActive: true, subMenuItems: [] },
        { id: "2", title: "Transaction", icon: <FaWallet  size={20} className="mx-[1.8rem]" />, path: "/brand/transactions", isActive: false, subMenuItems: [] },
        {
            id: "3", title: "Campaigns", icon: <HiOutlineSpeakerphone size={20} className="mx-[1.8rem]" />, path: "/brand/campaigns", isActive: false, subMenuItems: [

            ],
        },
        { id: "4", title: "UGC Content List", icon: <IoIosHeartEmpty size={20} className="mx-[1.8rem]" />,path: "/brand/campaigns/campaign-list", isActive: false, subMenuItems: [] },
        { id: "5", title: "Wishlist", icon: <IoIosHeartEmpty size={20} className="mx-[1.8rem]" />, path: "/brand", isActive: false, subMenuItems: [] },
        {
            id: "6", title: "Settings", icon: <RxGear size={20} className="mx-[1.8rem]" />, path: "/brand", isActive: false, subMenuItems: [
                { id: "1", title: "Account Details" },
                { id: "2", title: "Shipping Address" },
                { id: "3", title: "Miscellaneous Details" },
                { id: "4", title: "Social Accounts" },
                { id: "5", title: "Payout Settings" },
            ],
        },
    ]);

    // Handle click to toggle `isActive`
    const handleItemClick = (id) => {
        setSidebarData((prevState) =>
            prevState.map((item) =>
                item.id === id
                    ? { ...item, isActive: !item.isActive } // Toggle `isActive` for the clicked item
                    : { ...item, isActive: false } // Set `isActive` to false for all other items
            )
        );
    };

    return (
        <div className="brand-sidebar w-[36rem] py-[4.5rem] flex flex-col justify-between items-start pl-[2.4rem] pr-[5rem]">
            <div className="flex flex-col justify-start items-start w-full h-max">
                {
                    sidebarData?.map((item, index) => {
                        return (
                            <div className="flex flex-col justify-start items-start w-full h-max" key={item.id}>
                                <div
                                    className={`bs-navs flex justify-start items-center w-full h-[5rem] text-[1.8rem] my-[.2rem] cursor-pointer ${item.isActive ? "text-[#FFF] bg-[#0D9B50]" : "text-[#202224]"} rounded-[.8rem] hover:bg-[#0D9B50] hover:text-[#FFF]`}
                                    onClick={() => {
                                        handleItemClick(item.id);
                                        navigate(item.path); // Navigate to the item's path
                                    }}
                                >
                                    {item.icon}
                                    <h4>{item.title}</h4>
                                </div>
                                {item.subMenuItems?.length > 0 && item.isActive &&
                                    item.subMenuItems?.map((subItem) => {
                                        return (
                                            <div
                                                key={subItem.id}
                                                className={`bs-sub-navs flex justify-start items-center w-[90%] h-[5rem] ml-[8%] mr-[2%] px-[1.8rem] text-[1.8rem] my-[.2rem] cursor-pointer ${item.isActive ? "bg-[#0D9B50]/10" : ""} rounded-[.8rem] hover:bg-[#0D9B50]/10 hover:text-[#202224]`}
                                                onClick={() => navigate(subItem.path)} // Navigate to the submenu item's path
                                            >
                                                {subItem.title}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
            <button
                className="bs-navs flex justify-start items-center w-full h-[5rem] text-[1.8rem] my-[.2rem] cursor-pointer rounded-[.8rem] hover:bg-[#0D9B50] hover:text-[#FFF]"
                onClick={() => { dispatch(logout()) }}
            >
                <AiOutlineLogout size={20} className="mx-[1.8rem]" />
                Logout
            </button>
        </div>
    );
};

export default BrandSidebar;
