import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {registerBusinessFailure, registerBusinessSuccess} from "../../actions/BrandBusinessActions/registerBusinessActions";

function* registerBusiness({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.registerBusiness,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("REGISTER_BUSINESS_SUCCESS status :", response.data.status);
        console.log("REGISTER_BUSINESS_SUCCESS data :", response.data.response);
        console.log("REGISTER_BUSINESS_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            registerBusinessSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("REGISTER_BUSINESS_FAILURE status :", error?.response?.status);
        console.log("REGISTER_BUSINESS_FAILURE data:", error?.response?.data);
        console.log("REGISTER_BUSINESS_FAILURE error :", error);
        console.log('====================================');
        yield put(registerBusinessFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* registerBusinessSaga() {
    yield takeLatest(actionTypes.REGISTER_BUSINESS_REQUEST, registerBusiness);
}

export default registerBusinessSaga;
