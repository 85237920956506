import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const uploadCampaignFile = (payload, callback) => ({
    type: actionTypes.UPLOAD_CAMPAIGN_FILE_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const uploadCampaignFileSuccess = (payload) => ({
    type: actionTypes.UPLOAD_CAMPAIGN_FILE_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const uploadCampaignFileFailure = (payload) => ({
    type: actionTypes.UPLOAD_CAMPAIGN_FILE_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const uploadCampaignFileReset = () => ({
    type: actionTypes.UPLOAD_CAMPAIGN_FILE_RESET,
});

// Action creator for resetting all OTP generation reducer
export const uploadCampaignFileResetAll = () => ({
    type: actionTypes.UPLOAD_CAMPAIGN_FILE_RESET_ALL,
});
