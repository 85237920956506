import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MyCampaignsImg } from '../../../static/constant/imgConstants';
import DynamicImagePreview from "./DynamicImagePreview";
import { uploadCampaignFile } from "../../../store/campaignStore/actions/CreateCampaignActions/uploadCampaignFileActions";
import {
    getAllBannerTemplate
} from "../../../store/campaignStore/actions/CreateCampaignActions/getAllBannerTemplateActions";
import CampaignDate from '../BrandCampaignCreation/CampaignDate';
import { useToast } from '../../../hooks/useToast';

const Banner = ({
    selectedBanner,
    setSelectedBanner,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    errors,
    setErrors
}) => {
    const dispatch = useDispatch();
    const Toast = useToast();

    const getAllBannerTemplateReducerData = useSelector(state => state.getAllBannerTemplateReducer.data) || [];

    // Function to handle updating content_or_url in elements
    const handleInputChange = (index, value, localUrl) => {
        const updatedElements = selectedBanner.templateDataJson.elements.map((element, i) => {
            if (i === index) {
                return { ...element, content_or_url: value, localUrl: localUrl }; // Update the content_or_url of the specific element
            }
            return element;
        });

        // Update the state with the modified elements
        setSelectedBanner(prevState => ({
            ...prevState,
            templateDataJson: {
                ...prevState.templateDataJson,
                elements: updatedElements,
            },
        }));
    };

    const handleImageUpload = (index, file) => {
        const localUrl = URL.createObjectURL(file); // Create local URL for preview
        console.log(localUrl)
        dispatch(uploadCampaignFile({ file }, (response, status) => {
            // Callback to update content_or_url with the returned image URL
            if (status === 200) {
                handleInputChange(index, response, localUrl);
            } else {
                Toast(response, 'error');
            }
        }));
    };

    useEffect(() => {
        dispatch(getAllBannerTemplate());
    }, []);


    console.log("selectedBanner :", JSON.stringify(selectedBanner));


    return (
        <div className="w-full mb-6">
            <div className="flex justify-between items-start">
                <div className="flex justify-between items-start flex-wrap w-[69%]">
                    <div className="">
                        <h2 className="text-[1.4rem] text-[#111729] inter-font mb-6">
                            Select Banner<span className="text-[1.6rem] text-[#E3351D]">*</span>
                        </h2>
                        <div className="flex justify-start items-center flex-wrap">
                            {getAllBannerTemplateReducerData.length > 0 ?
                                getAllBannerTemplateReducerData?.map((banners, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className={`w-[18rem] h-[19rem] flex flex-col items-center justify-between mr-[2rem] border rounded-lg 
                                                ${banners?.isActive ? "cursor-pointer" : "cursor-not-allowed"} 
                                                ${banners?.bannerTemplateId === selectedBanner?.bannerTemplateId ? "border-[#0D9B50] bg-[#DBEAE6]/40" : "border-[#E3E8EF] bg-[#FFFFFF]"} 
                                                hover:border-[#0D9B50] hover:bg-[#DBEAE6]/20`
                                            }
                                        >
                                            <div
                                                className="w-auto h-auto m-auto"
                                                onClick={() => {
                                                    if (banners?.isActive) {
                                                        setSelectedBanner(banners);
                                                        setErrors((prevState) => {
                                                            const { ["selectedBanner"]: _, ...updatedObject } = prevState;
                                                            return updatedObject;
                                                        })
                                                    }
                                                }
                                                }
                                            >
                                                <img src={banners.sampleImageUrl} alt="Banner Icon" className="w-full h-full" />
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="text-[1.4rem]">
                                    No banners available.
                                </div>
                            }
                        </div>
                    </div>
                    {errors.selectedBanner && (
                        <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                            {errors.selectedBanner}
                        </p>
                    )}

                    <div className="w-full flex justify-between items-start flex-wrap my-[2.4rem]">
                        {selectedBanner != null && selectedBanner.isActive && (
                            selectedBanner.templateDataJson?.elements?.map((element, idx) => (
                                <div className={`w-[49%] ${element.type === 'text' ? "" : "flex flex-col justify-start items-start"}`} key={idx}>
                                    <label className="text-[1.6rem] border-gray-500 inter-font">{element.type.charAt(0).toUpperCase() + element.type.slice(1)}<span className='text-[#E3351D]'>*</span></label>
                                    {element.type === 'text' ? (
                                        <input
                                            className="w-full text-[1.4rem] inter-font px-4 py-4 border border-gray-300 rounded-lg focus:outline-none my-[1rem]"
                                            type="text"
                                            value={element.content_or_url}
                                            onChange={(e) => handleInputChange(idx, e.target.value)}
                                        />

                                    ) : (
                                        <input
                                            className="w-auto text-[1.6rem] inter-font px-4 py-2 bg-[#FFF] border border-gray-300 rounded-lg focus:outline-none my-[1rem]"
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                if (e.target.files[0]) {
                                                    handleImageUpload(idx, e.target.files[0]);
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                            ))
                        )}

                    </div>
                    <CampaignDate
                        startDate={startDate}
                        endDate={endDate}
                        errors={errors}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setErrors={setErrors}
                    />
                </div>
                <div className="w-[20%]">
                    {/* Preview Section */}
                    {selectedBanner != null && selectedBanner.isActive ?
                        <div className="w-full">
                            <h2 className="text-[1.4rem] text-[#111729] inter-font font-[400] mb-1">Preview</h2>
                            <div className="w-full h-full">
                                {selectedBanner?.templateDataJson ? (
                                    <DynamicImagePreview elements={selectedBanner.templateDataJson.elements} />
                                ) : (
                                    <img src={selectedBanner.sampleImageUrl} alt="Banner Icon" className="w-full h-full" />
                                )}
                            </div>
                        </div> : ''}

                </div>
            </div>
        </div>
    );
};

export default Banner;
