import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getCampaignListWithFilter = (payload, callback) => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FILTER_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const getCampaignListWithFilterSuccess = (payload) => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FILTER_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getCampaignListWithFilterFailure = (payload) => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FILTER_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getCampaignListWithFilterReset = () => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FILTER_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getCampaignListWithFilterResetAll = () => ({
    type: actionTypes.GET_CAMPAIGN_LIST_FILTER_RESET_ALL,
});
