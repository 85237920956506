import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const markApprovedRejectedContent = (payload, callback) => ({
    type: actionTypes.MARK_APPROVED_REJECTED_CONTENT_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const markApprovedRejectedContentSuccess = (payload) => ({
    type: actionTypes.MARK_APPROVED_REJECTED_CONTENT_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const markApprovedRejectedContentFailure = (payload) => ({
    type: actionTypes.MARK_APPROVED_REJECTED_CONTENT_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const markApprovedRejectedContentReset = () => ({
    type: actionTypes.MARK_APPROVED_REJECTED_CONTENT_RESET,
});

// Action creator for resetting all OTP generation reducer
export const markApprovedRejectedContentResetAll = () => ({
    type: actionTypes.MARK_APPROVED_REJECTED_CONTENT_RESET_ALL,
}); 