import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { getPotentialIncomeMissedByMonthAndYearForDashboardFailure, getPotentialIncomeMissedByMonthAndYearForDashboardSuccess } from '../../actions/InfluencerCampaignActions/getPotentialIncomeMissedByMonthAndYearForDashboardActions';

function* getPotentialIncomeMissedByMonthAndYearForDashboard({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.getPotentialIncomeMissedByMonthAndYearForDashboard,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_SUCCESS status :", response.data.status);
        console.log("GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_SUCCESS data :", response.data.response);
        console.log("GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            getPotentialIncomeMissedByMonthAndYearForDashboardSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_FAILURE status :", error?.response?.status);
        console.log("GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_FAILURE data:", error?.response?.data);
        console.log("GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_FAILURE error :", error);
        console.log('====================================');
        yield put(getPotentialIncomeMissedByMonthAndYearForDashboardFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* getPotentialIncomeMissedByMonthAndYearForDashboardSaga() {
    yield takeLatest(actionTypes.GET_POTENTIAL_INCOME_MISSED_BY_MONTH_AND_YEAR_FOR_DASHBOARD_REQUEST, getPotentialIncomeMissedByMonthAndYearForDashboard);
}

export default getPotentialIncomeMissedByMonthAndYearForDashboardSaga;
