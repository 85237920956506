import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { getCountryByRegionFailure, getCountryByRegionSuccess } from '../../actions/CampaignMasterActions/getCountryByRegionActions';

function* getCountryByRegion({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url,
            endPoint: endpoints.getCountryByRegion + `${payload?.regionId}`,
            isAuth: true,
        });
        console.log('====================================');
        console.log("GET_COUNTRY_BY_REGION_SUCCESS status :", response.data.status);
        console.log("GET_COUNTRY_BY_REGION_SUCCESS data :", response.data.response);
        console.log("GET_COUNTRY_BY_REGION_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            getCountryByRegionSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("GET_COUNTRY_BY_REGION_FAILURE status :", error?.response?.status);
        console.log("GET_COUNTRY_BY_REGION_FAILURE data:", error?.response?.data);
        console.log("GET_COUNTRY_BY_REGION_FAILURE error :", error);
        console.log('====================================');
        yield put(getCountryByRegionFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* getCountryByRegionSaga() {
    yield takeLatest(actionTypes.GET_COUNTRY_BY_REGION_REQUEST, getCountryByRegion);
}

export default getCountryByRegionSaga;