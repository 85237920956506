import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {loginFailure, loginSuccess} from "../../actions/authActions/loginActions";
import {setAccessToken} from "../../../publicStore/actions/utilsActions/utilsActions";

function* login({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.login,
            payload: payload,
            isAuth: false,
        });
        console.log('====================================');
        console.log("LOGIN_SUCCESS status :", response.data.status);
        console.log("LOGIN_SUCCESS data :", response.data.response);
        console.log("LOGIN_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            loginSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        yield put(setAccessToken(response?.data?.response?.accessToken));
    } catch (error) {
        console.log('====================================');
        console.log("LOGIN_FAILURE status :", error?.response?.status);
        console.log("LOGIN_FAILURE data:", error?.response?.data);
        console.log("LOGIN_FAILURE error :", error);
        console.log('====================================');
        yield put(loginFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* loginSaga() {
    yield takeLatest(actionTypes.LOGIN_REQUEST, login);
}

export default loginSaga;
