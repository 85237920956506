import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const requestPayout = (payload, callback) => ({
    type: actionTypes.REQUEST_PAYOUT_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const requestPayoutSuccess = (payload) => ({
    type: actionTypes.REQUEST_PAYOUT_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const requestPayoutFailure = (payload) => ({
    type: actionTypes.REQUEST_PAYOUT_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const requestPayoutReset = () => ({
    type: actionTypes.REQUEST_PAYOUT_RESET,
});

// Action creator for resetting all OTP generation reducer
export const requestPayoutResetAll = () => ({
    type: actionTypes.REQUEST_PAYOUT_RESET_ALL,
});
