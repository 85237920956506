import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const forgetPasswordReset = (payload) => ({
    type: actionTypes.FORGET_PASSWORD_RESET_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const forgetPasswordResetSuccess = (payload) => ({
    type: actionTypes.FORGET_PASSWORD_RESET_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const forgetPasswordResetFailure = (payload) => ({
    type: actionTypes.FORGET_PASSWORD_RESET_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const forgetPasswordResetReset = () => ({
    type: actionTypes.FORGET_PASSWORD_RESET_RESET,
});

// Action creator for resetting all OTP generation reducer
export const forgetPasswordResetResetAll = () => ({
    type: actionTypes.FORGET_PASSWORD_RESET_RESET_ALL,
});
