import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs';
import { BiFilterAlt } from 'react-icons/bi';
import Popup from '../../popups/Popup/Popup';
import { useDispatch } from 'react-redux';
import { getUploadedUGCContentForInfluencer, getUploadedUGCContentForInfluencerReset } from '../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/getUploadedUGCContentForInfluencerActions';
import moment from 'moment';
import { streamVideo } from '../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/streamVideoActions';
import { MyCampaignsImg } from '../../../static/constant/imgConstants';
import { publishVideoOnYoutube } from '../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/publishVideoOnYoutubeAction';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import Footer from '../../common/Footer/Footer';

const ApprovedList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupType, setPopupType] = useState(null);
    const [successPopupOpen, setSuccessPopupOpen] = useState(false);
    const [getUploadedUGCContentForInfluencerData, setGetUploadedUGCContentForInfluencerData] = useState();
    const [publishTitle, setPublishTitle] = useState();
    const [publishDescription, setPublishDescription] = useState();
    const [scheduleTime, setScheduleTime] = useState();
    const [contentId, setContentId] = useState();
    const [filteredCampaigns, setFilteredCampaigns] = useState(getUploadedUGCContentForInfluencerData);
    const [searchQuery, setSearchQuery] = useState('');

    const breadcrumbData = [
        { label: 'Home', href: '/' },
        // { label: 'My Campaigns', href: '/brand/campaigns' },
        { label: 'Approved Content List', href: '#' },
    ];

    const columns = [
        {
            name: 'Campaign',
            selector: row => row.campaignName,
            sortable: true,
            style: {
                width: '10rem',  // Set a smaller width for this column
            },
        },
        {
            name: 'Upload Date',
            selector: row => moment(row.uploadedAt).format("DD-MM-YYYY hh:mm A"),
            sortable: true,
            style: {
                width: '10rem',  // Adjust the width as needed
            },
        },
        {
            name: 'Status',
            selector: row => (
                <span
                    className={`inline-block px-3 py-1 rounded-full text-center ${getStatusStyle(row.status)}`}
                >
                    {row.status}
                </span>
            ),
            style: {
                width: '10rem',
            },
        },
        {
            name: 'Reject Reason',
            selector: row => (
                <span
                    className={``}
                >
                    {row.ugcRejectReason ? row.ugcRejectReason : "-"}
                </span>
            ),
            style: {
                width: '90rem',
            },
        },
        {
            name: 'Content',
            selector: row => <div>
                <button
                    className="bg-[#FF0505CC] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                    onClick={() => {
                        dispatch(streamVideo({ s3ContentPath: row.contentPath },
                            (status, response, message) => {
                                if (status === 200) {
                                    window.open(response, "_blank", 'rel=noopener noreferrer')
                                }
                            }))
                    }}
                >
                    View
                </button>
            </div>,
            style: {
                width: '10rem',  // Set a smaller width for this column
            },
        },
        {
            name: 'Action',
            cell: row => row.status == "UGC_APPROVED" ?
                <div className="flex space-x-[1.5rem]">
                    <button
                        className="bg-[#0D9B50] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                        onClick={() => {
                            setPopupType('publish');
                            setIsPopupOpen(true);
                            setContentId(row.contentId);
                        }}
                    >
                        Publish it
                    </button>
                    <button
                        className="bg-[#0D9B50] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                        onClick={() => {
                            setPopupType('schedule');
                            setIsPopupOpen(true);
                            setContentId(row.contentId);
                        }}
                    >
                        Schedule
                    </button>
                </div>
                : row.status == "UGC_REJECTED" ?
                    <button
                        className="bg-[#0D9B50] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                        onClick={() => {
                            navigate("/influencer/campaigns/ugc-upload", {
                                state: {
                                    campaign: {
                                        campaignType: "UGC",
                                        campaignId: row.campaignId,
                                        contentId: row.contentId
                                    },
                                },
                            })
                        }}
                    >
                        Re Upload
                    </button>
                    :
                    <div className="text-[1.6rem] text-center">
                        -
                    </div>,
            ignoreRowClick: true,
            allowOverflow: true,
            style: {
                width: '30rem',  // Adjust width for the Action column
            },
        },
    ];

    const getStatusStyle = (status) => {
        switch (status) {
            case 'UGC_APPROVED':
                return 'bg-[#00B69B] text-[#FFFFFF]';
            case 'PENDING_APPROVAL':
                return 'bg-[#FCBE2D] text-[#FFFFFF]';
            case 'UGC_REJECTED':
                return 'bg-[#FD5454] text-[#FFFFFF]';
            default:
                return 'bg-[#FCBE2D] text-[#FFFFFF]';
        }
    };

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredCampaigns(getUploadedUGCContentForInfluencerData);
        } else {
            const filtered = getUploadedUGCContentForInfluencerData.filter(campaign =>
                campaign.campaignName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                campaign.status.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredCampaigns(filtered);
        }
    }, [searchQuery, getUploadedUGCContentForInfluencerData]);

    const customTableStyles = {
        header: {
            style: {
                backgroundColor: '#F1F4F9',
                fontSize: '1.6rem',  // Increased text size for header
                fontWeight: 700,
                color: '#202224',
                position: 'sticky',  // Make header sticky
                top: 0,  // Ensure it sticks at the top
                zIndex: 1,  // Ensure it appears above the rows
            },
        },
        rows: {
            style: {
                fontSize: '1.4rem',  // Increased text size for rows
                fontWeight: 600,
                color: '#202224',
                borderBottom: '1px solid #EDEFF1',
            },
        },
        headCells: {
            style: {
                padding: '1rem',
                textAlign: 'left',
                backgroundColor: '#e5e7eb',
            },
        },
        cells: {
            style: {
                padding: '1.5rem',
            },
        },
    };

    useEffect(() => {
        dispatch(getUploadedUGCContentForInfluencer({
            "campaignId": 0
        }, (status, response, message) => {
            if (status === 200) {
                setGetUploadedUGCContentForInfluencerData(response);
                dispatch(getUploadedUGCContentForInfluencerReset());
            } else if (status) {
                dispatch(getUploadedUGCContentForInfluencerReset());
            }
        }))
    }, [])


    console.log("getUploadedUGCContentForInfluencerData :", getUploadedUGCContentForInfluencerData);

    console.log("contentId :", contentId);




    return (
        <div className="w-full h-full flex flex-col overflow-auto bg-[#F2F3F7]">
            <Breadcrumbs breadcrumbs={breadcrumbData} />

            {/* <h1 className="text-[1.8rem] text-[#534D4D] inter-font font-[600] px-[4rem] py-[2.7rem]">
                Approved Content List
            </h1> */}
            <div className="px-[4rem] mt-[4rem]">
                <div className="w-full flex flex-col justify-between rounded-[2.6rem] bg-[#ffffff] p-[2.4rem]">
                    <h1 className="text-[2.4rem] leading-[5rem] text-[#202224] inter-font font-[700] mb-[1rem]">
                        Content List
                    </h1>

                    <div className="w-full !overflow-y-scroll no-scrollbar">
                        {Array.isArray(filteredCampaigns) && filteredCampaigns.length > 0 ? (
                            <DataTable
                                columns={columns}
                                data={filteredCampaigns}
                                customStyles={customTableStyles}
                                pagination
                                highlightOnHover
                                striped
                                responsive
                            />
                        ) : (
                            <div className="text-4xl leading-[70px] text-gray-500 text-center mt-4">
                                There are no records to display.
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* <div className="px-[4rem]">
                <div className="bg-white rounded-[1.2rem] shadow-xl p-4 sm:p-8 mt-6 overflow-hidden">
                    <div className="w-full grid grid-cols-2 mb-6 gap-4">
                        <h2 className="text-[2.4rem] inter-font font-[700]">Content List</h2>
                        <div className="w-full flex justify-end">
                            <select className="w-[60%] sm:w-[35%] lg:w-[25%] border border-[#D5D5D5] text-[#2B303466] text-[1.4rem] inter-font font-[500] rounded-lg px-[1.5rem] py-[1rem]">
                                {months.map((month, index) => (
                                    <option key={index} value={month}>
                                        {month}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="grid grid-cols-5 text-[1.4rem] inter-font font-[700] bg-[#F1F4F9] text-[#202224] py-[1.5rem] px-[2rem]">
                        <div>Campaign</div>
                        <div>Upload Date</div>
                        <div>Status</div>
                        <div>Content</div>
                        <div>Action</div>
                    </div>

                    {getUploadedUGCContentForInfluencerData?.length > 0 ?
                        getUploadedUGCContentForInfluencerData?.map((item, index) => (
                            <div
                                key={index}
                                className="grid grid-cols-5 text-[1.4rem] text-[#202224] inter-font font-[500] py-[1.5rem] px-[2rem] border-t border-[#fff]"
                            >
                                <div>{item.campaignName ? item.campaignName : "NA"}</div>
                                <div>{moment(item.uploadedAt).format("DD-MM-YYYY hh:mm A")}</div>
                                <div>{item.status}</div>
                                <div>
                                    <button
                                        className="bg-[#FF0505CC] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                                        onClick={() => {
                                            dispatch(streamVideo({ s3ContentPath: item.contentPath },
                                                (status, response, message) => {
                                                    if (status === 200) {
                                                        window.open(response, "_blank", 'rel=noopener noreferrer')
                                                    }
                                                }))
                                        }}
                                    >
                                        View
                                    </button>
                                </div>
                                {item.status == "UGC_APPROVED" ?
                                    <div className="flex space-x-[1.5rem]">
                                        <button
                                            className="bg-[#0D9B50] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                                            onClick={() => {
                                                setPopupType('publish');
                                                setIsPopupOpen(true);
                                                setContentId(item.contentId);
                                            }}
                                        >
                                            Publish it
                                        </button>
                                        <button
                                            className="bg-[#0D9B50] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                                            onClick={() => {
                                                setPopupType('schedule');
                                                setIsPopupOpen(true);
                                                setContentId(item.contentId);
                                            }}
                                        >
                                            Schedule
                                        </button>
                                    </div>
                                    :
                                    <div className="text-[1.6rem] text-center">
                                        -
                                    </div>
                                }
                            </div>
                        ))
                        :
                        <div className="text-center text-[1.6rem]">
                            No Content Available
                        </div>
                    }
                </div>
            </div> */}

            <Footer />

            {/* Conditional Popup */}
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                title={popupType === 'publish' ? 'Publish It' : 'Schedule It'}
                canDismiss
                content={
                    <div className="text-[1.6rem] mt-12">
                        <form className="space-y-4">
                            <div>
                                <label htmlFor="title" className="text-[#202224] text-[1.5rem]">
                                    Add Title
                                </label>
                                <input
                                    type="text"
                                    id="title"
                                    placeholder="Enter Title here"
                                    className="w-full bg-[#F7FBFF] h-20 border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none"
                                    onChange={(e) => {
                                        setPublishTitle(e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="description" className="text-[#202224] text-[1.5rem]">
                                    Add Description
                                </label>
                                <input
                                    id="description"
                                    placeholder="Enter Description here"
                                    className="w-full bg-[#F7FBFF] h-20 border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none"
                                    onChange={(e) => {
                                        setPublishDescription(e.target.value);
                                    }}
                                />
                            </div>
                            {popupType === 'schedule' && (
                                <div className="relative">
                                    <label htmlFor="datetime" className="text-[#202224] text-[1.5rem]">
                                        Add Schedule
                                    </label>
                                    <input
                                        type="datetime-local"
                                        id="datetime"
                                        min={new Date().toISOString().slice(0, 16)}
                                        placeholder="Enter Schedule here"
                                        className="w-full bg-[#F7FBFF] h-20 border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none"
                                        value={scheduleTime}
                                        onChange={(e) => {
                                            console.log("schedule input :", e.target.value);
                                            setScheduleTime(e.target.value);
                                        }}
                                    />
                                    <img
                                        src={MyCampaignsImg.calendarIcon}
                                        alt="Calendar Icon"
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 mt-4 w-10 h-10"
                                        onKeyDown={(e) => e.preventDefault()}
                                        onClick={() => {
                                            const inputDateElement = document.getElementById('datetime');
                                            inputDateElement.showPicker();
                                        }}
                                    />
                                </div>
                            )}
                        </form>
                    </div>
                }
                size="sm"
                primaryButton={{
                    text: popupType === 'publish' ? 'Upload Now' : 'Schedule Now',
                    onClick: () => {
                        if (popupType === 'publish') {
                            dispatch(publishVideoOnYoutube({
                                "contentId": contentId,
                                "title": publishTitle,
                                "description": publishDescription,
                                "isPublishNow": true,
                                "scheduleDateTime": ""
                            }, (status, response, message) => {
                                if (status == 200) {
                                    // setPublishVideoResponse({ status: 200, response: response, message: message });
                                    // setSuccessPopupOpen(true);
                                    dispatch(getUploadedUGCContentForInfluencer({
                                        "campaignId": 0
                                    }, (status, response, message) => {
                                        if (status === 200) {
                                            setGetUploadedUGCContentForInfluencerData(response);
                                            dispatch(getUploadedUGCContentForInfluencerReset());
                                        } else if (status) {
                                            dispatch(getUploadedUGCContentForInfluencerReset());
                                        }
                                    }))
                                } else if (status === 305) {
                                    // setPopupForTokenExpireOpen(true)
                                } else if (status) {
                                    // setPublishVideoResponse({ status: status, response: response, message: message });
                                    // setSuccessPopupOpen(true);
                                }
                            }))
                        } else {
                            dispatch(publishVideoOnYoutube({
                                "contentId": contentId,
                                "title": publishTitle,
                                "description": publishDescription,
                                "isPublishNow": false,
                                "scheduleDateTime": moment(scheduleTime).format("YYYY-MM-DD h:mm:ss")
                            }, (status, response, message) => {
                                if (status == 200) {
                                    // setPublishVideoResponse({ status: 200, response: response, message: message });
                                    // setSuccessPopupOpen(true);
                                    dispatch(getUploadedUGCContentForInfluencer({
                                        "campaignId": 0
                                    }, (status, response, message) => {
                                        if (status === 200) {
                                            setGetUploadedUGCContentForInfluencerData(response);
                                            dispatch(getUploadedUGCContentForInfluencerReset());
                                        } else if (status) {
                                            dispatch(getUploadedUGCContentForInfluencerReset());
                                        }
                                    }))
                                } else if (status) {
                                    // setPublishVideoResponse({ status: status, response: response, message: message });
                                    // setSuccessPopupOpen(true);
                                }
                            }))
                        }
                        setIsPopupOpen(false);
                    },
                }}
            />
        </div >
    );
};

export default ApprovedList;
