import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {
    verifyOtpAndCreateUserFailure,
    verifyOtpAndCreateUserSuccess
} from "../../actions/authActions/verifyOtpAndCreateUserActions";

function* verifyOtpAndCreateUser({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.verifyOtpAndCreateUser,
            payload: payload,
            isAuth: false,
        });
        console.log('====================================');
        console.log("VERIFY_OTP_AND_CREATE_USER_SUCCESS status :", response.data.status);
        console.log("VERIFY_OTP_AND_CREATE_USER_SUCCESS data :", response.data.response);
        console.log("VERIFY_OTP_AND_CREATE_USER_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            verifyOtpAndCreateUserSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("VERIFY_OTP_AND_CREATE_USER_FAILURE status :", error?.response?.status);
        console.log("VERIFY_OTP_AND_CREATE_USER_FAILURE data:", error?.response?.data);
        console.log("VERIFY_OTP_AND_CREATE_USER_FAILURE error :", error);
        console.log('====================================');
        yield put(verifyOtpAndCreateUserFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* verifyOtpAndCreateUserSaga() {
    yield takeLatest(actionTypes.VERIFY_OTP_AND_CREATE_USER_REQUEST, verifyOtpAndCreateUser);
}

export default verifyOtpAndCreateUserSaga;
