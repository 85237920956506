import React, { useEffect, useState } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { InfluencerImg } from '../../../static/constant/imgConstants';
import Footer from '../../common/Footer/Footer';
import DatePicker from 'react-datepicker';
import "./InfluencerDashboard.css"
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignDetailsForDashboard } from '../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/getCampaignDetailsForDashboardActions';
import { getPotentialIncomeMissedByMonthAndYearForDashboard } from '../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/getPotentialIncomeMissedByMonthAndYearForDashboardActions';
import moment from 'moment';
import { getTotalIncomeByMonthAndYearForDashboard } from '../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/getTotalIncomeByMonthAndYearForDashboardActions';
import { useNavigate } from 'react-router';
import Popup from '../../popups/Popup/Popup';
import { SiLibreofficewriter } from 'react-icons/si';
import { getUserInfo } from '../../../store/authStore/actions/authActions/getUserInfoActions';
import { myChannelList } from '../../../store/BusinessStore/actions/InfluencerChannelActions/myChannelListActions';

const InfluencerDashboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const getCampaignDetailsForDashboardReducer = useSelector(state => state.getCampaignDetailsForDashboardReducer);
    const getPotentialIncomeMissedByMonthAndYearForDashboardReducer = useSelector(state => state.getPotentialIncomeMissedByMonthAndYearForDashboardReducer);
    const getTotalIncomeByMonthAndYearForDashboardReducer = useSelector(state => state.getTotalIncomeByMonthAndYearForDashboardReducer);

    const [totalIncomeDate, setTotalIncomeDate] = useState(moment((new Date())).format("YYYY-MM"));
    const [potentialMissedIncomeDate, setPotentialMissedIncomeDate] = useState(moment((new Date())).format("YYYY-MM"));

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isPopupForTokenExpireOpen, setPopupForTokenExpireOpen] = useState(false);
    const getUserInfoReducer = useSelector(state => state.getUserInfoReducer);
    const userInfo = getUserInfoReducer?.data;
    useEffect(() => {
        dispatch(getUserInfo());
        dispatch(myChannelList((status, response, message) => {
            if (status === 302) {
                setPopupOpen(true)
            } else if (userInfo?.isTokenExpire) {
                setPopupForTokenExpireOpen(true)
            }
        }))
    }, []);

    useEffect(() => {
        dispatch(getCampaignDetailsForDashboard());
        dispatch(getPotentialIncomeMissedByMonthAndYearForDashboard({
            "month": moment(potentialMissedIncomeDate).format("MM"),
            "year": moment(potentialMissedIncomeDate).format("YYYY")
        }))
        dispatch(getTotalIncomeByMonthAndYearForDashboard({
            "month": moment(totalIncomeDate).format("MM"),
            "year": moment(totalIncomeDate).format("YYYY")
        }))
    }, [])


    useEffect(() => {
        dispatch(getCampaignDetailsForDashboard())
        dispatch(getPotentialIncomeMissedByMonthAndYearForDashboard({
            "month": moment(potentialMissedIncomeDate).format("MM"),
            "year": moment(potentialMissedIncomeDate).format("YYYY")
        }))
    }, [potentialMissedIncomeDate])

    useEffect(() => {
        dispatch(getTotalIncomeByMonthAndYearForDashboard({
            "month": moment(totalIncomeDate).format("MM"),
            "year": moment(totalIncomeDate).format("YYYY")
        }))
    }, [totalIncomeDate])


    return (
        <div className="w-full h-full bg-[#EDEEF1] overflow-scroll">
            <div className="w-full flex flex-col">
                <div className="flex justify-start items-center border-b bg-[#EDEEF1] mx-[2rem] mt-4 p-4">
                    <div className="text-[1.4rem] text-[#38414A] flex items-center space-x-2 mb-2">
                        <a href="#" className="">
                            <GoArrowLeft size={22} color="#BCBCBC" />
                        </a>
                        <span className="text-[1.8rem] text-[#38414A] inter-font font-[500]">
                            Control Tower Dashboard
                        </span>
                    </div>
                </div>

                <div className="px-[4rem] mt-[3rem]">
                    <h2 className="text-[2.8rem] text-[#7F8387]  inter-font font-[500]">
                        Hii Influencer !
                    </h2>
                    <h2 className="text-[1.8rem] text-[#7F8387] font-[500] mt-2">
                        Welcome to creator dashboard lets collaborate!
                    </h2>
                    <h2 className="text-[2.8rem] text-[#7F8387] inter-font font-[500] py-[5rem]">
                        Dashboard
                    </h2>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[4rem] px-[4rem] ">
                    <div className="bg-white shadow-md rounded-[1.2rem] flex flex-col justify-center items-center p-[2rem]">
                        <div className='w-full flex justify-between'>
                            <h3 className="text-[#000000] text-[2rem] inter-font font-[500]">Campaign Participated</h3>
                            <div className='w-[7.1rem] h-[7.1rem]'>
                                <img src={InfluencerImg.info1} alt="Campaign Participated" className="w-full h-full mb-2" />
                            </div>
                        </div>
                        <p className="text-[#38414A] text-[4.8rem] inter-font font-[600] mt-1">{getCampaignDetailsForDashboardReducer?.data?.campaignParticipated}</p>
                    </div>

                    <div className="bg-white shadow-md rounded-[1.2rem] flex flex-col justify-center items-center p-[2rem]">
                        <div className='w-full flex justify-between'>
                            <h3 className="text-[#000000] text-[2rem] inter-font font-[500]">Campaign Missed</h3>
                            <div className='w-[7.1rem] h-[7.1rem]'>
                                <img src={InfluencerImg.info2} alt="Campaign Participated" className="w-full h-full mb-2" />
                            </div>
                        </div>
                        <p className="text-[#38414A] text-[4.8rem] inter-font font-[600] mt-1">{getCampaignDetailsForDashboardReducer?.data?.campaignMissed}</p>
                    </div>

                    <div className="bg-white shadow-md rounded-[1.2rem] flex flex-col justify-center items-center p-[2rem]">
                        <div className='w-full flex justify-between'>
                            <h3 className="text-[#000000] text-[2rem] inter-font font-[500]">Available Campaign</h3>
                            <div className='w-[7.1rem] h-[7.1rem]'>
                                <img src={InfluencerImg.info3} alt="Campaign Participated" className="w-full h-full mb-2" />
                            </div>
                        </div>
                        <p className="text-[#38414A] text-[4.8rem] inter-font font-[600] mt-1">{getCampaignDetailsForDashboardReducer?.data?.campaignAvaiable}</p>
                    </div>

                    <div className="bg-white shadow-md rounded-[1.2rem] flex flex-col justify-center items-center p-[2rem]">
                        <div className='w-full flex justify-between mb-[5rem]'>
                            <h3 className="text-[#000000] text-[2rem] text-center inter-font font-[500]">Total Income by Month<br />
                                and Year</h3>
                            <div className='w-[7.1rem] h-[7.1rem]'>
                                <img src={InfluencerImg.info4} alt="Campaign Participated" className="w-full h-full mb-2" />
                            </div>
                        </div>
                        {/* <input
                            type="text"
                            placeholder="MM/YY"
                            value={totalIncomeDate}
                            onChange={(e) => setTotalIncomeDate(e.target.value)}
                            className="mt-2 border border-[#D5D5D5] text-[#2B303466] text-[1.6rem] inter-font font-[500] p-2 rounded-[0.6rem] text-center"
                        /> */}
                        <DatePicker
                            selected={totalIncomeDate}
                            onChange={(date) => setTotalIncomeDate(date)}
                            dateFormat="MM/yyyy"
                            maxDate={moment((new Date())).format("YYYY-MM")}
                            showMonthYearPicker
                            className='mt-2 border border-[#D5D5D5] text-[#2B303466] text-[1.6rem] inter-font font-[500] p-2 rounded-[0.6rem] text-center'
                        />
                        <p className="text-[#38414A] text-[4.8rem] inter-font font-[600] mt-[3rem]">{getTotalIncomeByMonthAndYearForDashboardReducer?.data?.potentialIncome ? getTotalIncomeByMonthAndYearForDashboardReducer?.data?.potentialIncome : 0}</p>
                    </div>

                    <div className="bg-white shadow-md rounded-[1.2rem] flex flex-col justify-center items-center p-[2rem]">
                        <div className='w-full flex justify-between mb-[5rem]'>
                            <h3 className="text-[#000000] text-[2rem] text-center  inter-font font-[500]">Potential income Missed<br />
                                Month and Year</h3>
                            <div className='w-[7.1rem] h-[7.1rem]'>
                                <img src={InfluencerImg.info5} alt="Campaign Participated" className="w-full h-full mb-2" />
                            </div>
                        </div>
                        <DatePicker
                            selected={potentialMissedIncomeDate}
                            onChange={(date) => setPotentialMissedIncomeDate(date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            maxDate={moment((new Date())).format("YYYY-MM")}
                            className='mt-2 border border-[#D5D5D5] text-[#2B303466] text-[1.6rem] inter-font font-[500] p-2 rounded-[0.6rem] text-center w-[20rem]'
                        />
                        <p className="text-[#38414A] text-[4.8rem] inter-font font-[600] mt-[3rem]">{getPotentialIncomeMissedByMonthAndYearForDashboardReducer?.data?.potentialIncome ? getPotentialIncomeMissedByMonthAndYearForDashboardReducer?.data?.potentialIncome : 0}</p>
                    </div>
                </div>
            </div>
            <Footer />
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setPopupOpen(false)}
                title="Channel Registration"
                canDismiss
                icon={<SiLibreofficewriter color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>No YouTube channels found under this account</p>}
                size="sm"
                primaryButton={{
                    text: 'Add Channel',
                    onClick: () => {
                        navigate("/influencer-channel")
                        setPopupOpen(false);
                    },
                }}
                secondaryButton={{
                    text: 'Not Now',
                    onClick: () => setPopupOpen(false),
                }}
            />
            <Popup
                isOpen={isPopupForTokenExpireOpen}
                onClose={() => setPopupForTokenExpireOpen(false)}
                title="Youtube Auth token"
                canDismiss
                icon={<SiLibreofficewriter color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>Your Channel authentication is expired or revoked</p>}
                size="sm"
                primaryButton={{
                    text: 'Re-auth',
                    onClick: () => {
                        window.open('/influencer-channel-auth', '_blank');
                        setPopupForTokenExpireOpen(false);
                    },
                }}
                secondaryButton={{
                    text: 'Not Now',
                    onClick: () => setPopupForTokenExpireOpen(false),
                }}
            />
        </div>
    );
};

export default InfluencerDashboard;
