import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {
    forgetPasswordResetFailure,
    forgetPasswordResetSuccess
} from "../../actions/authActions/forgetPasswordResetActions";

function* forgetPasswordReset({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.forgetPasswordReset,
            payload: payload,
            isAuth: false,
        });
        console.log('====================================');
        console.log("FORGET_PASSWORD_RESET_SUCCESS status :", response.data.status);
        console.log("FORGET_PASSWORD_RESET_SUCCESS data :", response.data.response);
        console.log("FORGET_PASSWORD_RESET_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            forgetPasswordResetSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("FORGET_PASSWORD_RESET_FAILURE status :", error?.response?.status);
        console.log("FORGET_PASSWORD_RESET_FAILURE data:", error?.response?.data);
        console.log("FORGET_PASSWORD_RESET_FAILURE error :", error);
        console.log('====================================');
        yield put(forgetPasswordResetFailure(
            {
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* forgetPasswordResetSaga() {
    yield takeLatest(actionTypes.FORGET_PASSWORD_RESET_REQUEST, forgetPasswordReset);
}

export default forgetPasswordResetSaga;
