import React from 'react'
import {useNavigate} from 'react-router'
import {LoginPageImg} from '../../../static/constant/imgConstants';

function PublicHeader() {
    const navigate = useNavigate();

    return (
        <div className='logo-container w-full flex justify-start items-center'>
            <div className='logo w-[8rem] h-full cursor-pointer' onClick={() => navigate('/')} >
                <img className="w-full h-full" alt="" src={LoginPageImg.login1} />
            </div>
        </div>
    )
}

export default PublicHeader
