import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {uploadCampaignFileFailure, uploadCampaignFileSuccess} from '../../actions/CreateCampaignActions/uploadCampaignFileActions';

function* uploadCampaignFile({ payload, callback }) {
    try {
        const formData= new FormData();
        formData.append('file', payload.file);
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.uploadCampaignFile,
            payload: formData,
            isAuth: true,
            ContentType: "multipart/form-data"
        });
        console.log('====================================');
        console.log("UPLOAD_CAMPAIGN_FILE_SUCCESS status :", response.data.status);
        console.log("UPLOAD_CAMPAIGN_FILE_SUCCESS data :", response.data.response);
        console.log("UPLOAD_CAMPAIGN_FILE_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            uploadCampaignFileSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        // Execute the callback with the response if it exists
        if (callback && response?.data?.status === 200) {
            callback(response.data.response, response?.data?.status); // Pass the response data to the callback
        }else {
            callback(response.data.message, response?.data?.status); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("UPLOAD_CAMPAIGN_FILE_FAILURE status :", error?.response?.status);
        console.log("UPLOAD_CAMPAIGN_FILE_FAILURE data:", error?.response?.data);
        console.log("UPLOAD_CAMPAIGN_FILE_FAILURE error :", error);
        console.log('====================================');
        yield put(uploadCampaignFileFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* uploadCampaignFileSaga() {
    yield takeLatest(actionTypes.UPLOAD_CAMPAIGN_FILE_REQUEST, uploadCampaignFile);
}

export default uploadCampaignFileSaga;
