import React from 'react'
import { MyCampaignsImg } from '../../../static/constant/imgConstants';

function CampaignDate({ startDate, endDate, errors, setStartDate, setEndDate, setErrors }) {

    const startDateThresholdInDays = 15
    const startDateEndDateDifferenceInDays = 20

    return (
        <>
            <div className="w-[49%] relative">
                <label className="text-[1.6rem] text-[#111729] inter-font font-[400] mb-1">
                    Start Date<span className="text-[1.6rem] text-[#E3351D]">*</span>
                </label>
                <div className="relative mt-[1rem]">
                    <input
                        type="date"
                        value={startDate}
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(e) => {
                            setStartDate(e.target.value);
                            setEndDate('');
                            setErrors((prevState) => {
                                const {["startDate"]: _, ...updatedObject} = prevState;
                                return updatedObject;
                            });
                            setErrors((prevState) => {
                                const { ["endDate"]: _, ...updatedObject } = prevState;
                                return updatedObject;
                            });
                        }}
                        min={new Date(new Date().setDate(new Date().getDate() + startDateThresholdInDays)).toISOString().split("T")[0]} // Min date set to 15 days from today
                        className="w-full text-[1.6rem] text-[#111729] inter-font font-[400] border border-gray-300 rounded-lg p-4 pr-[3rem]"
                        id="startDate"
                    />
                    <img
                        src={MyCampaignsImg.calendarIcon}
                        alt="calendar"
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 w-10 h-10"
                        onClick={() => {
                            const inputDateElement = document.getElementById('startDate');
                            inputDateElement.showPicker();
                        }}
                    />
                </div>
                {errors?.startDate && (
                    <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                        {errors?.startDate}
                    </p>
                )}
            </div>
            <div className="w-[49%] relative">
                <label className="text-[1.6rem] text-[#111729] inter-font font-[400] mb-1">
                    End Date<span className="text-[1.6rem] text-[#E3351D]">*</span>
                </label>
                <div className="relative mt-[1rem]">
                    <input
                        type="date"
                        value={endDate}
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(e) => {
                            setEndDate(e.target.value);
                            setErrors((prevState) => {
                                const { ["endDate"]: _, ...updatedObject } = prevState;
                                return updatedObject;
                            });
                        }}
                        min={
                            startDate
                                ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() + startDateEndDateDifferenceInDays))
                                    .toISOString()
                                    .split("T")[0]
                                : ""
                        }
                        disabled={!startDate} // Disable end date until start date is selected
                        className={`w-full text-[1.6rem] text-[#111729] inter-font font-[400] border border-gray-300 rounded-lg p-4 pr-[3rem] ${
                            !startDate ? "bg-gray-100 cursor-not-allowed" : ""
                        }`}
                        id="endDate"
                    />
                    <img
                        src={MyCampaignsImg.calendarIcon}
                        alt="calendar"
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 w-10 h-10"
                        onClick={() => {
                            const inputDateElement = document.getElementById('endDate');
                            inputDateElement.showPicker();
                        }}
                    />
                </div>
                {errors?.endDate && (
                    <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                        {errors?.endDate}
                    </p>
                )}
            </div>
        </>
    )
}

export default CampaignDate
