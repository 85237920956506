import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getCampaignDetailsForDashboard = (payload, callback) => ({
    type: actionTypes.GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const getCampaignDetailsForDashboardSuccess = (payload) => ({
    type: actionTypes.GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getCampaignDetailsForDashboardFailure = (payload) => ({
    type: actionTypes.GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getCampaignDetailsForDashboardReset = () => ({
    type: actionTypes.GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getCampaignDetailsForDashboardResetAll = () => ({
    type: actionTypes.GET_CAMPAIGN_DETAILS_FOR_DASHBOARD_RESET_ALL,
});
