import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const verifyOtpAndCreateUser = (payload) => ({
    type: actionTypes.VERIFY_OTP_AND_CREATE_USER_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const verifyOtpAndCreateUserSuccess = (payload) => ({
    type: actionTypes.VERIFY_OTP_AND_CREATE_USER_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const verifyOtpAndCreateUserFailure = (payload) => ({
    type: actionTypes.VERIFY_OTP_AND_CREATE_USER_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const verifyOtpAndCreateUserReset = () => ({
    type: actionTypes.VERIFY_OTP_AND_CREATE_USER_RESET,
});

// Action creator for resetting all OTP generation reducer
export const verifyOtpAndCreateUserResetAll = () => ({
    type: actionTypes.VERIFY_OTP_AND_CREATE_USER_RESET_ALL,
});
