import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/common/Breadcrumbs/Breadcrumbs';
import moment from 'moment';
import { BarndImg, LoginPageImg } from '../../static/constant/imgConstants';
import { FiSearch } from 'react-icons/fi';
import Popup from '../../components/popups/Popup/Popup';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletTxnWithFilter, getWalletTxnWithFilterReset } from '../../store/influencerCampaignStore/actions/InfluencerCampaignActions/getWalletTxnWithFilterActions';
import { addMoneyToWallet } from '../../store/influencerCampaignStore/actions/InfluencerCampaignActions/addMoneyToWalletActions';
import { useLocation, useNavigate } from 'react-router';
import { txnStatus } from '../../store/influencerCampaignStore/actions/InfluencerCampaignActions/txnStatusActions';
import { getUserInfo } from '../../store/authStore/actions/authActions/getUserInfoActions';
import { BsCopy } from 'react-icons/bs';
import { useToast } from '../../hooks/useToast';
import { payment_redirect_uri } from '../../store/constants';
import DataTable from 'react-data-table-component';
import { FaCircleExclamation } from 'react-icons/fa6';
import { stripeOnboarding } from '../../store/influencerCampaignStore/actions/InfluencerCampaignActions/stripeOnboardingAction';
import { checkStripeOnboardingStatus } from '../../store/influencerCampaignStore/actions/InfluencerCampaignActions/checkStripeOnboardingStatusActions';
import { requestPayout } from '../../store/influencerCampaignStore/actions/InfluencerCampaignActions/requestPayoutAction';
import { Oval } from 'react-loader-spinner';
import { GiWallet } from "react-icons/gi";
import Footer from '../../components/common/Footer/Footer';

const InfluencerWalletAndTransactions = () => {
    const dispatch = useDispatch()
    const Toast = useToast()

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [receiptPopupOpen, setReceiptPopupOpen] = useState(false);
    const [isOnboardingPopupOpen, setIsOnboardingPopupOpen] = useState(false);
    const [getWalletTxnWithFilterResponse, setGetWalletTxnWithFilterResponse] = useState();
    const [enteredAmount, setEnteredAmount] = useState(0);
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const getUserInfoReducer = useSelector(state => state.getUserInfoReducer);
    const requestPayoutReducerLoading = useSelector(state => state.requestPayoutReducer.loading);

    const breadcrumbData = [
        { label: 'Home', href: '/influencer' },
        { label: 'Influencer Transactions', href: '#' },
    ];

    const columns = [
        {
            name: 'Transaction',
            selector: row => row.description,
            sortable: true,
            style: {
                width: '10rem',  // Set a smaller width for this column
            },
        },
        {
            name: 'Date',
            selector: row => moment(row.txnDate).format("DD-MM-YYYY hh:mm A"),
            sortable: true,
            style: {
                width: '10rem',  // Adjust the width as needed
            },
        },
        {
            name: 'Amount',
            selector: row => (
                <span
                // className={`inline-block px-3 py-1 rounded-full text-center ${getStatusStyle(row.campaignStatus)}`}
                >
                    ${row.amount}
                </span>
            ),
            style: {
                width: '10rem',  // Set a smaller width for this column
            },
        },
        {
            name: 'Txn Type',
            selector: row => row.transactionType,
            style: {
                width: '10rem',  // Adjust the width for consistency
            },
        },
        {
            name: 'Balance',
            selector: row => (
                <span
                // className={`inline-block px-3 py-1 rounded-full text-center ${getStatusStyle(row.campaignStatus)}`}
                >
                    ${row.balance}
                </span>
            ),
            style: {
                width: '90rem',
            },
        },
    ];

    const customTableStyles = {
        header: {
            style: {
                backgroundColor: '#F1F4F9',
                fontSize: '1.6rem',  // Increased text size for header
                fontWeight: 700,
                color: '#202224',
                position: 'sticky',  // Make header sticky
                top: 0,  // Ensure it sticks at the top
                zIndex: 1,  // Ensure it appears above the rows
            },
        },
        rows: {
            style: {
                fontSize: '1.4rem',  // Increased text size for rows
                fontWeight: 600,
                color: '#202224',
                borderBottom: '1px solid #EDEFF1',
            },
        },
        headCells: {
            style: {
                padding: '1rem',
                textAlign: 'left',
                backgroundColor: '#e5e7eb',
            },
        },
        cells: {
            style: {
                padding: '1.5rem',
            },
        },
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        if (searchQuery === '') {
            setGetWalletTxnWithFilterResponse(getWalletTxnWithFilterResponse);
        } else {
            const filtered = getWalletTxnWithFilterResponse?.filter(campaign =>
                campaign.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                campaign.transactionType.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setGetWalletTxnWithFilterResponse(filtered);
        }
    }, [searchQuery, getWalletTxnWithFilterResponse]);

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text); // Copy text to clipboard
            Toast('Copied to clipboard!', 'success'); // Set success message
        } catch (err) {
            Toast('Failed to copy', 'error'); // Error handling
        }
    };

    useEffect(() => {
        dispatch(getWalletTxnWithFilter({
            "fromDate": "",
            "toDate": ""
        }, (status, response, message) => {
            if (status === 200) {
                setGetWalletTxnWithFilterResponse(response);
                dispatch(getWalletTxnWithFilterReset());
            } else if (status) {
                dispatch(getWalletTxnWithFilterReset());
            }
        }));
        dispatch(getUserInfo());
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        // const sessionIdFromUrl = params.get('sessionId');
        const paymentStatusFromUrl = params.get('status');

        if (paymentStatusFromUrl) {
            // setSessionId(sessionIdFromUrl);
            console.log('Payment Status:', paymentStatusFromUrl);

            // Optionally process the status
            if (paymentStatusFromUrl === 'success') {
                console.log('Payment was successful!');
            } else if (paymentStatusFromUrl === 'failed') {
                Toast('Payment failed or was canceled.', "error");
            }

            // Clean up the URL if necessary
            navigate(location.pathname, { replace: true });

            dispatch(checkStripeOnboardingStatus({
            }, (status, response, message) => {
                if (status === 200) {
                    // Populate transaction details and open receipt popup
                    // setTransactionDetails(response);
                    // setReceiptPopupOpen(true);
                    dispatch(getUserInfo());
                    Toast(message, "success");
                } else if (status) {
                    Toast('Failed to fetch transaction status:', message);
                }
            }));
        }
    }, [location, navigate]);

    return (
        <div className="w-full h-full flex flex-col overflow-auto bg-[#F2F3F7]">
            <Breadcrumbs breadcrumbs={breadcrumbData} />
            <div className="w-full flex justify-between items-center py-2">
                <div className="relative px-[4rem]">
                    <FiSearch className="absolute top-1/2 left-20 transform -translate-y-1/2 w-[2.1rem] h-[2.1rem] text-gray-400" />
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search Transactions"
                        className="w-[20rem] p-3 border text-[1.4rem] inter-font font-[400] border-gray-300 rounded-[0.3rem] pl-16 outline-none"
                    />
                </div>
                <div
                    className="px-[4rem] cursor-pointer"
                    onClick={() => {
                        if (getUserInfoReducer?.data?.stripeServiceAccountId) {
                            setEnteredAmount(0);
                            setPopupOpen(true);
                        } else {
                            setIsOnboardingPopupOpen(true);
                        }
                    }}
                >
                    <div className="my-5">
                        <GiWallet size={40} className="" color='#202224' />
                        <div className="text-[1.4rem] text-[#202224]">
                            Pay Out
                        </div>
                    </div>
                </div>
            </div>
            {/* Content List Table */}
            <div className="px-[4rem] w-full">
                {requestPayoutReducerLoading ?
                    <div className="flex justify-center items-center">
                        <Oval
                            visible={true}
                            height="50"
                            width="50"
                            color="#000000"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                    :
                    <div className="w-full h-full flex flex-col justify-between rounded-[2.6rem] bg-[#ffffff] p-[2.4rem]">
                        <div className="pb-5">
                            <h2 className="text-[2.4rem] inter-font font-[700]">Transaction History</h2>
                        </div>
                        <div className="mb-[4rem] w-full !overflow-y-scroll no-scrollbar">
                            {Array.isArray(getWalletTxnWithFilterResponse) && getWalletTxnWithFilterResponse?.length > 0 ? (
                                <DataTable
                                    columns={columns}
                                    data={getWalletTxnWithFilterResponse}
                                    customStyles={customTableStyles}
                                    pagination
                                    highlightOnHover
                                    striped
                                    responsive
                                />
                            )
                                :
                                <div className="text-center text-[1.4rem] text-[#8A98AC] py-[2rem]">
                                    No Transactions Found.
                                </div>
                            }
                        </div>


                        {/* Table Header */}
                        {/* <div className="grid grid-cols-5 text-[1.4rem] inter-font font-[700] bg-[#F1F4F9] text-[#202224] py-[1.5rem] px-[2rem]">
                        <div>Transaction</div>
                        <div>Date</div>
                        <div>Amount</div>
                        <div>Txn Type</div>
                        <div>Balance</div>
                    </div> */}

                        {/* Table Rows */}
                        {/* {getWalletTxnWithFilterResponse?.length > 0 ? (
                        getWalletTxnWithFilterResponse?.map((item, index) => (
                            <div
                                key={index}
                                className="grid grid-cols-5 text-[1.4rem] text-[#202224] inter-font font-[600] py-[1.5rem] px-[2rem] border-t border-[#fff]"
                            >
                                <div>{item.description || 'N/A'}</div>
                                <div>{moment(item.uploadedAt).format("DD-MM-YYYY hh:mm A") || 'N/A'}</div>
                                <div>${item.amount || 'N/A'}</div>
                                <div>
                                    {item.transactionType}
                                </div>
                                <div className="">
                                    {item.balance}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center text-[1.4rem] text-[#8A98AC] py-[2rem]">No Transactions Found.</div>
                    )} */}
                    </div>
                }
            </div>

            {/* Add Money Popup */}
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setPopupOpen(false)}
                title="Pay Out from your Wallet"
                canDismiss
                icon={<GiWallet size={110} className="my-[5rem]" color='#202224' />}
                content={
                    <div className="">
                        <label className="text-[#111729] text-[1.4rem] mb-4">
                            Enter Digits
                        </label>
                        <input
                            type="number"
                            className="w-full px-4 py-4 text-[1.4rem] border border-gray-300 rounded-lg focus:outline-none "
                            value={enteredAmount}
                            onChange={(e) => { setEnteredAmount(e.target.value) }}
                        />
                    </div>
                }
                size="sm"
                primaryButton={{
                    text: 'Pay',
                    onClick: () => {
                        if (enteredAmount === 0) {
                            Toast("Please enter amount")
                        } else if (enteredAmount > 999999) {
                            Toast("Please enter smaller amount");
                        } else {
                            dispatch(requestPayout({
                                "amount": enteredAmount
                            }, (status, response, message) => {
                                if (status == 200) {
                                    setTransactionDetails(response);
                                    setReceiptPopupOpen(true);
                                    dispatch(getWalletTxnWithFilter({
                                        "fromDate": "",
                                        "toDate": ""
                                    }, (status, response, message) => {
                                        if (status === 200) {
                                            setGetWalletTxnWithFilterResponse(response);
                                            dispatch(getWalletTxnWithFilterReset());
                                        } else if (status) {
                                            Toast(message, "error")
                                            dispatch(getWalletTxnWithFilterReset());
                                        }
                                    }));
                                    dispatch(getUserInfo());
                                } else if (status) {
                                    Toast(message, "error");
                                }
                            }))
                            setEnteredAmount(0);
                            setPopupOpen(false);
                        }
                    },
                }}
                secondaryButton={{
                    text: 'Cancel',
                    onClick: () => {
                        setEnteredAmount(0);
                        setPopupOpen(false);
                    },
                }}
            />

            {/* Receipt Popup */}
            <Popup
                isOpen={receiptPopupOpen}
                onClose={() => setReceiptPopupOpen(false)}
                title="Transaction Receipt"
                canDismiss
                content={
                    transactionDetails ? (
                        <div className="w-full p-4">
                            {/* Logo and Header */}
                            <div className="flex items-center justify-between mb-6 border-b pb-4">
                                <div>
                                    <img src={LoginPageImg.login1} alt="Brand Logo" className="w-[40%] h-[40%]" />
                                </div>
                                <div className="text-right">
                                    <h2 className="text-xl font-bold text-gray-800">Transaction Receipt</h2>
                                    <p className="text-[1.4rem] text-gray-500">Thank you for your transaction!</p>
                                </div>
                            </div>

                            {/* Transaction Details */}
                            <div className="flex flex-col gap-4 mb-6">
                                <div className="flex justify-start items-center">
                                    <div className='w-[60%]'>
                                        <h3
                                            className="text-[1.4rem] font-semibold text-gray-500 flex justify-start items-center cursor-pointer"
                                            onClick={() => handleCopy(transactionDetails.orderId)}
                                        >
                                            Bank Account ID:
                                            <BsCopy className="ml-[1rem]" />
                                        </h3>
                                        <p className="text-[1.4rem] text-gray-800">{transactionDetails.bankAccountId}</p>
                                    </div>
                                    <div className='w-[30%]'>
                                        <h3
                                            className="text-[1.4rem] font-semibold text-gray-500 flex justify-start items-center cursor-pointer"
                                            onClick={() => handleCopy(transactionDetails.referenceNumber)}
                                        >
                                            Reference Number:
                                            <BsCopy className="ml-[1rem]" />
                                        </h3>
                                        <p className="text-[1.4rem] text-gray-800">{transactionDetails.referenceNumber}</p>
                                    </div>
                                </div>
                                <div className="flex justify-start items-center">
                                    <div className='w-[60%]'>
                                        <h3 className="text-[1.4rem] font-semibold text-gray-500">Transaction Date:</h3>
                                        <p className="text-[1.4rem] text-gray-800">{moment(transactionDetails.transactionDate).format('DD-MM-YYYY hh:mm A')}</p>
                                    </div>
                                    <div className='w-[30%]'>
                                        <h3 className="text-[1.4rem] font-semibold text-gray-500">Status:</h3>
                                        <p className={`text-[1.4rem] ${transactionDetails.status == 'SUCCESS' ? 'text-green-600' : 'text-red-600'}`}>
                                            {transactionDetails.status}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-start items-center">
                                    <div className='w-[60%]'>
                                        <h3
                                            className="text-[1.4rem] font-semibold text-gray-500 flex justify-start items-center cursor-pointer"
                                            onClick={() => handleCopy(transactionDetails.rrn)}
                                        >
                                            RRN No:
                                            <BsCopy className="ml-[1rem]" />
                                        </h3>
                                        <p className="text-[1.4rem] text-gray-800">{transactionDetails.rrn}</p>
                                    </div>
                                    <div className='w-[30%]'>
                                        <h3
                                            className="text-[1.4rem] font-semibold text-gray-500 flex justify-start items-center cursor-pointer"
                                        >
                                            Remaining Balance
                                        </h3>
                                        <p className="text-[1.4rem] text-gray-800">{transactionDetails.balance}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Amount Details */}
                            <div className="bg-gray-100 p-4 rounded-md">
                                <h3 className="text-[1.4rem] font-semibold text-gray-500">Amount:</h3>
                                <p className="text-[1.6rem] font-bold text-gray-800">${transactionDetails.amount}</p>
                            </div>

                            {/* Footer */}
                            <div className="mt-6 text-center text-[1.4rem] text-gray-500 border-t pt-4">
                                <p>If you have any questions about this transaction, please contact our support team.</p>
                            </div>
                        </div>
                    ) : (
                        <p>Loading transaction details...</p>
                    )
                }
                size="md"
            />
            {/* Onboarding Popup */}
            <Popup
                isOpen={isOnboardingPopupOpen}
                onClose={() => setIsOnboardingPopupOpen(false)}
                title="Need to on-Board on stripe"
                canDismiss
                icon={<FaCircleExclamation color="#D72828" size={110} className="my-[5rem]" />}
                content={
                    <div className="text-[1.4rem] font-[400] text-center">
                        Click On-Board to Complete your On-Boarding
                    </div>
                }
                size="sm"
                primaryButton={{
                    text: 'On-Board',
                    onClick: () => {
                        dispatch(stripeOnboarding({
                            "successUrl": "http://localhost:3000/influencer/transactions?status=success",
                            "cancelUrl": "http://localhost:3000/influencer/transactions?status=failed"
                        }, (status, response, message) => {
                            if (status == 200) {
                                setIsOnboardingPopupOpen(false);
                                window.open(response?.onboardingUrl, "_self");
                            } else if (status) {
                                Toast(message, "error");
                            }
                        }))
                    },
                }}
                secondaryButton={{
                    text: 'Cancel',
                    onClick: () => {
                        setIsOnboardingPopupOpen(false);
                    },
                }}
            />
            <Footer />

        </div>
    )
}

export default InfluencerWalletAndTransactions