import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { getUploadedUGCContentForInfluencerFailure, getUploadedUGCContentForInfluencerSuccess } from '../../actions/InfluencerCampaignActions/getUploadedUGCContentForInfluencerActions';

function* getUploadedUGCContentForInfluencer({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.getUploadedUGCContentForInfluencer,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_SUCCESS status :", response.data.status);
        console.log("GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_SUCCESS data :", response.data.response);
        console.log("GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            getUploadedUGCContentForInfluencerSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_FAILURE status :", error?.response?.status);
        console.log("GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_FAILURE data:", error?.response?.data);
        console.log("GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_FAILURE error :", error);
        console.log('====================================');
        yield put(getUploadedUGCContentForInfluencerFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* getUploadedUGCContentForInfluencerSaga() {
    yield takeLatest(actionTypes.GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_REQUEST, getUploadedUGCContentForInfluencer);
}

export default getUploadedUGCContentForInfluencerSaga;
