import { put, call, takeLatest } from 'redux-saga/effects';
import { endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { uploadToS3Failure, uploadToS3Success } from '../../actions/InfluencerCampaignActions/uploadToS3Actions';

function* uploadToS3({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'put',
            base_url: payload?.presignedURL,
            ContentType: 'video/mp4',
            endPoint: endpoints.uploadToS3,
            payload: payload.file,
            isAuth: false,
        });
        console.log('====================================');
        console.log("UPLOAD_TO_S3_SUCCESS status :", response);
        console.log("UPLOAD_TO_S3_SUCCESS data :", response.response);
        console.log("UPLOAD_TO_S3_SUCCESS message :", response.message);
        console.log('====================================');
        yield put(
            uploadToS3Success({
                status: response.status,
                // response: response?.response,
                // message: response?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("UPLOAD_TO_S3_FAILURE status :", error?.response?.status);
        console.log("UPLOAD_TO_S3_FAILURE data:", error?.response?.data);
        console.log("UPLOAD_TO_S3_FAILURE error :", error);
        console.log('====================================');
        yield put(uploadToS3Failure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* uploadToS3Saga() {
    yield takeLatest(actionTypes.UPLOAD_TO_S3_REQUEST, uploadToS3);
}

export default uploadToS3Saga;
