import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const storeValidateChannel = (payload, callback) => ({
    type: actionTypes.STORE_VALIDATE_CHANNEL_REQUEST,
    payload,
    callback,
});

// Action creator for successful OTP generation
export const storeValidateChannelSuccess = (payload) => ({
    type: actionTypes.STORE_VALIDATE_CHANNEL_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const storeValidateChannelFailure = (payload) => ({
    type: actionTypes.STORE_VALIDATE_CHANNEL_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const storeValidateChannelReset = () => ({
    type: actionTypes.STORE_VALIDATE_CHANNEL_RESET,
});

// Action creator for resetting all OTP generation reducer
export const storeValidateChannelResetAll = () => ({
    type: actionTypes.STORE_VALIDATE_CHANNEL_RESET_ALL,
});
