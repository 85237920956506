import React from "react";
import {useSelector} from "react-redux";
import Header from "../../main/Header/Header";
import Sidebar from "../../main/Sidebar/Sidebar";
import Landing from "../../main/Landing/Landing";

const InfluencerLayout = ({ children }) => {

    return (
        <div className='w-full h-full'>
            <Header/>
            <div className='flex justify-between h-[90%]'>
                <Sidebar/>
                {children}
            </div>

        </div>
    )
};

export default InfluencerLayout;
