import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const verifyOtpForForgetPassword = (payload) => ({
    type: actionTypes.VERIFY_OTP_FOR_FORGET_PASSWORD_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const verifyOtpForForgetPasswordSuccess = (payload) => ({
    type: actionTypes.VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const verifyOtpForForgetPasswordFailure = (payload) => ({
    type: actionTypes.VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const verifyOtpForForgetPasswordReset = () => ({
    type: actionTypes.VERIFY_OTP_FOR_FORGET_PASSWORD_RESET,
});

// Action creator for resetting all OTP generation reducer
export const verifyOtpForForgetPasswordResetAll = () => ({
    type: actionTypes.VERIFY_OTP_FOR_FORGET_PASSWORD_RESET_ALL,
});
