import { actionTypes } from '../../../types';

export const checkContentMergeStatus = (payload, callback) => ({
    type: actionTypes.CHECK_CONTENT_MERGE_STATUS_REQUEST,
    payload,
    callback
});

export const checkContentMergeStatusSuccess = (payload) => ({
    type: actionTypes.CHECK_CONTENT_MERGE_STATUS_SUCCESS,
    payload,
});

export const checkContentMergeStatusFailure = (payload) => ({
    type: actionTypes.CHECK_CONTENT_MERGE_STATUS_FAILURE,
    payload,
});

export const checkContentMergeStatusReset = () => ({
    type: actionTypes.CHECK_CONTENT_MERGE_STATUS_RESET,
});

export const checkContentMergeStatusResetAll = () => ({
    type: actionTypes.CHECK_CONTENT_MERGE_STATUS_RESET_ALL,
});
