export const LoginPageImg = {
    loginlogo: require("../img/loginlogo.png"),
    login1: require("../../static/img/login1.png"),
    facebook: require("../../static/img/facebook.png"),
    google: require("../../static/img/google.png"),
    flag: require("../../static/img/flag.png"),
    successfully: require("../../static/img/successfully.png"),
};


export const SidebarImg = {
    header1: require("../../static/img/header1.png"),
    header2: require("../../static/img/header2.png"),
    homeimg: require("../../static/img/homeimg.png"),
    searchimg: require("../../static/img/searchimg.png"),
    settingimg: require("../../static/img/settingimg.png"),
    heartimg: require("../../static/img/heartimg.png"),
    campaigns: require("../../static/img/campaigns.png"),
    logoutimg: require("../../static/img/logoutimg.png"),
    notification: require("../../static/img/notification.png"),
    profile: require("../../static/img/profile.png"),
    union: require("../../static/img/union.png"),
    facebook1: require("../../static/img/facebook1.png"),
    bin: require("../../static/img/bin.png"),
    plus: require("../../static/img/plus.png"),
    linkedin: require("../../static/img/linkedin.png"),
    insta: require("../../static/img/insta.png"),
    icici: require("../../static/img/icici.png"),
    crediticon: require("../../static/img/crediticon.png"),
    campaign: require("../../static/img/campaign.png"),
    views: require("../../static/img/views.png"),
    customer: require("../../static/img/customer.png"),
    impression: require("../../static/img/impression.png"),
    clicks: require("../../static/img/clicks.png"),
    kip: require("../../static/img/kpi.png"),
    login1: require("../../static/img/login1.png"),
    campaigns1: require("../../static/img/campaigns1.png"),
};

export const BarndImg = {
    hamburger: require("../../static/img/hamburger.png"),
    Bankw: require("../../static/img/Bankw.png"),
    Bankb: require("../../static/img/Bankb.png"),
    Manb: require("../../static/img/Manb.png"),
    Manw: require("../../static/img/Manw.png"),
    Focusw: require("../../static/img/Focusw.png"),
    Focusb: require("../../static/img/Focusb.png"),
    Groupw: require("../../static/img/Groupw.png"),
    Groupb: require("../../static/img/Groupb.png"),
    Reportw: require("../../static/img/Reportw.png"),
    Reportb: require("../../static/img/Reportb.png"),
    Monitorb: require("../../static/img/Monitorb.png"),
    Monitorw: require("../../static/img/Monitorw.png"),
    campaign: require("../../static/img/campaign.png"),
    views: require("../../static/img/views.png"),
    impression: require("../../static/img/impression.png"),
    cancelled: require("../../static/img/cancelled.png"),
    delicious1: require("../../static/img/delicious1.png"),
    delicious2: require("../../static/img/delicious2.png"),
    google0: require("../../static/img/google0.png"),
    facebook0: require("../../static/img/facebook0.png"),
    linkedin1: require("../../static/img/linkedin1.png"),
    youtube: require("../../static/img/youtube.png"),
    wallet: require("../../static/img/wallet-img.png"),
};


export const MyCampaignsImg = {
    mycampaigns1: require("../../static/img/mycampaigns1.png"),
    mycampaigns2: require("../../static/img/mycampaigns2.png"),
    mycampaigns3: require("../../static/img/mycampaigns3.png"),
    calendarIcon: require("../../static/img/calendarIcon.png"),
    clockIcon: require("../../static/img/clockIcon.png"),
    uploadIcon: require("../../static/img/upload.png")

};

export const LandingImg = {
    f1: require("../../static/img/f1.png"),
    businesspageimg: require("../../static/img/businesspageimg.png"),
    brandimpact1: require("../../static/img/brandimpact1.png"),
    brandimpact2: require("../../static/img/brandimpact2.png"),
    brandimpact3: require("../../static/img/brandimpact3.png"),
    dathub1: require("../../static/img/dathub1.png"),
    dathub2: require("../../static/img/dathub2.png"),
    dathub3: require("../../static/img/dathub3.png"),
    dathub4: require("../../static/img/dathub4.png"),
    dathub5: require("../../static/img/dathub5.png"),
    dathub6: require("../../static/img/dathub6.png"),
    backgroundimg: require("../../static/img/backgroundimg.png"),
    resource1: require("../../static/img/resource1.png"),
    resource2: require("../../static/img/resource2.png"),
    better: require("../../static/img/better.png"),
    better1: require("../../static/img/better1.png"),
    better2: require("../../static/img/better2.png"),
};


export const InfluencerImg = {
    info1: require("../../static/img/info1.png"),
    info2: require("../../static/img/info2.png"),
    info3: require("../../static/img/info3.png"),
    info4: require("../../static/img/info4.png"),
    info5: require("../../static/img/info5.png"),
    barnd1: require("../../static/img/barnd1.png"),
    barnd2: require("../../static/img/barnd2.png"),
    barnd3: require("../../static/img/barnd3.png"),
    barnd4: require("../../static/img/barnd4.png"),
};