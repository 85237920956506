import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getUploadedUGCContentForInfluencer = (payload, callback) => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const getUploadedUGCContentForInfluencerSuccess = (payload) => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getUploadedUGCContentForInfluencerFailure = (payload) => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getUploadedUGCContentForInfluencerReset = () => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getUploadedUGCContentForInfluencerResetAll = () => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_FOR_INFLUENCER_RESET_ALL,
});
