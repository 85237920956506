import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full h-[6rem] mt-auto px-[4rem] py-[1rem] text-[#8A98AC] text-[1.4rem] inter-font font-[600] flex justify-center">
      <div className="w-[60%] flex justify-end items-end">
        <span className="hover:underline">© 2024 Paid promo - All Rights Reserved.</span>
      </div>
      <div className="w-[40%] space-x-[1.5rem] flex justify-end items-end">
        <a className="hover:underline" href="/privacy">Privacy Policy</a>
        <a className="hover:underline" href="/cookies">Cookies</a>
      </div>
    </footer>
  );
};

export default Footer;
