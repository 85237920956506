import React, { useState } from 'react';
import { MyCampaignsImg } from '../../../static/constant/imgConstants';
import { useDispatch } from 'react-redux';
import {uploadCampaignFile} from "../../../store/campaignStore/actions/CreateCampaignActions/uploadCampaignFileActions";
import CampaignDate from '../BrandCampaignCreation/CampaignDate';
import { useToast } from '../../../hooks/useToast';

const VideoCampaign = ({
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    setErrors,
    errors,
    setVideoPosition,
    videoPosition,
    mediaFileLink,
    mediaFileName,
    setMediaFileLink,
    setMediaFileName
}) => {
    const dispatch = useDispatch();
    const Toast = useToast();

    const [isDragging, setIsDragging] = useState(false);
    const [videoFileName, setVideoFileName] = useState(mediaFileName);
    const [loading, setLoading] = useState(false); // Loading state

    console.log("mediaFile",mediaFileLink )
    console.log("mediaFile",mediaFileName )
    console.log("mediaFile", videoFileName)

    const handleFileUpload = (file) => {
        setLoading(true); // Start loading
        const localUrl = URL.createObjectURL(file); // Create local URL for preview
        setVideoFileName(file.name); // Set the video file name for display

        // Upload the file and update media file link
        dispatch(uploadCampaignFile({ file }, (response, status) => {
            if(status === 200){

                setMediaFileName(file.name);
                setMediaFileLink(response); // Update media link with returned URL
                setLoading(false); // Stop loading
                setErrors((prevState) => {
                    const {["mediaFileLink"]: _, ...updatedObject} = prevState;
                    return updatedObject;
                });
            }else {
                Toast(response, 'error');
            }
        }));
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('video/')) {
            handleFileUpload(file);
        } else {
            setErrors(prevState => ({ ...prevState, mediaFileLink: 'Please upload a valid video file' }));
        }
    };

    const handleRemoveVideo = () => {
        setVideoFileName(null); // Clear the video file name
        setMediaFileLink(null); // Clear the media file link
        setMediaFileName(null);
        setLoading(false); // Reset loading state
    };

    return (
        <div className="flex flex-row justify-between items-start w-full flex-wrap">
            <div className='w-[49%] flex flex-col mb-[2rem] h-[24rem]'>
                <label className="text-[1.6rem] text-[#111729] inter-font font-[500]">Videos to be used<span className="text-[1.6rem] text-[#E3351D]">*</span></label>
                <div
                    className={`w-full h-[90%] flex justify-center items-center border border-gray-300 mt-[1.4rem] rounded-lg text-center bg-gray-50 ${
                        isDragging ? 'bg-green-100 border-green-500' : ''
                    }`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {!videoFileName && !mediaFileName && !loading ? (
                        <>
                    <input
                        type="file"
                        accept="video/*"
                        className="w-full hidden"
                        onChange={(e) => {handleFileUpload(e.target.files[0])}}
                        id="fileUpload"
                    />
                    <label htmlFor="fileUpload" className="cursor-pointer">
                        <p className='text-[1.4rem] inter-font font-[400] text-[#111729]'>
                            Drag files here <br />
                            or <span className='text-[1.4rem] inter-font font-[400] text-[#34A853]'>browse your computer</span>
                        </p>
                    </label>
                        </>
                    ) : loading ? (
                        <p className="text-[1.6rem] text-[#111729] inter-font font-[400]">Uploading...</p>
                    ) : (
                        <div className="flex flex-col items-center">
                            <p className="text-[1.4rem] inter-font font-[400] text-[#111729] mb-2">
                                Selected video: {videoFileName}
                            </p>
                            <div className="flex space-x-4">
                                <button
                                    className="text-red-500 text-[1.6rem] font-[400] underline"
                                    onClick={handleRemoveVideo}
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {errors?.mediaFileLink && (
                    <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                        {errors?.mediaFileLink}
                    </p>
                )}
            </div>
            <div className="w-[49%]">
                <label className="text-[1.6rem] text-[#111729] inter-font font-[400]">
                    Video Position<span className="text-[1.6rem] text-[#E3351D]">*</span>
                </label>
                <select
                    className="w-full text-[1.6rem] text-[#111729] inter-font font-[400] border border-gray-300 rounded-lg p-4 mt-[1rem]"
                    value={videoPosition}
                    onChange={(e) => {
                        setVideoPosition(e.target.value);
                        setErrors((prevState) => { const { ["videoPosition"]: _, ...updatedObject } = prevState; return updatedObject; });
                    }}
                >
                    <option value="" >Select Position</option>
                    <option value="START" >START</option>
                    <option value="MIDDLE" >MIDDLE</option>
                    <option value="END" >END</option>

                </select>
                {errors?.videoPosition && (
                    <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                        {errors?.videoPosition}
                    </p>
                )}
            </div>
            <CampaignDate
                startDate={startDate}
                endDate={endDate}
                errors={errors}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setErrors={setErrors}
            />
        </div>
    )
}

export default VideoCampaign
