import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { publishVideoOnYoutubeFailure, publishVideoOnYoutubeSuccess } from '../../actions/InfluencerCampaignActions/publishVideoOnYoutubeAction';

function* publishVideoOnYoutube({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.publishVideoOnYoutube,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("PUBLISH_VIDEO_ON_YOUTUBE_SUCCESS status :", response.data.status);
        console.log("PUBLISH_VIDEO_ON_YOUTUBE_SUCCESS data :", response.data.response);
        console.log("PUBLISH_VIDEO_ON_YOUTUBE_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            publishVideoOnYoutubeSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("PUBLISH_VIDEO_ON_YOUTUBE_FAILURE status :", error?.response?.status);
        console.log("PUBLISH_VIDEO_ON_YOUTUBE_FAILURE data:", error?.response?.data);
        console.log("PUBLISH_VIDEO_ON_YOUTUBE_FAILURE error :", error);
        console.log('====================================');
        yield put(publishVideoOnYoutubeFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* publishVideoOnYoutubeSaga() {
    yield takeLatest(actionTypes.PUBLISH_VIDEO_ON_YOUTUBE_REQUEST, publishVideoOnYoutube);
}

export default publishVideoOnYoutubeSaga;
