import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../components/common/Breadcrumbs/Breadcrumbs';
import YouTubeOAuth from '../../components/main/Landing/YouTubeOAuth';
import { useDispatch } from 'react-redux';
import { storeYoutubeAuth } from '../../store/BusinessStore/actions/InfluencerChannelActions/storeYoutubeAuthActions';
import {youtube_redirect_uri, youtube_redirect_uri_auth_only} from '../../store/constants';
import {useToast} from "../../hooks/useToast";
import {
    storeValidateChannel
} from "../../store/BusinessStore/actions/InfluencerChannelActions/storeValidateChannelActions";
import {FaCircleCheck} from "react-icons/fa6";
import Popup from "../../components/popups/Popup/Popup";
import {useNavigate} from "react-router";
import {getUserInfo} from "../../store/authStore/actions/authActions/getUserInfoActions";

function InfluencerChannelAuthOnly() {
    const dispatch = useDispatch();

    const [platform, setPlatform] = useState("");
    const [errors, setErrors] = useState({});
    const [channelList, setChannelList] = useState([
    ]);

    const breadcrumbData = [
        { label: 'Home', href: '/' },
        { label: 'My Campaigns', href: '/brand/campaigns/create' },
    ];

    const Toast = useToast();
    let isAuthCalledAndSuccess = false;
    console.log("channelList : ", channelList)
    const handleStoreYoutubeAuth = (authCode) => {
        if(!isAuthCalledAndSuccess){
        dispatch(storeYoutubeAuth({
            "googleCode": authCode,
            "redirectUri": youtube_redirect_uri_auth_only
        },(status, response, message) => {
            if (status === 200) {
                isAuthCalledAndSuccess = true;
                // Fetch channel list on successful authentication
                window.close()

            } else {
                Toast(message, 'error');
            }
        }));
        }
    };

    useEffect(() => {
        dispatch(getUserInfo());
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        console.log("youtube code", code)
        if (code) {
            setTimeout(() => {
                handleStoreYoutubeAuth(code);
            }, 1000);
        }
    }, []); // Run once on component mount




    return (
        <div className='w-full flex flex-col overflow-auto bg-[#F2F3F7]'>
            <Breadcrumbs breadcrumbs={breadcrumbData} />
            <div className='p-[4rem] min-h-[70rem] relative'>
                <h2 className="text-[#534D4D] text-[1.8rem] font-[600]">Select the Platform</h2>
                <div className="flex justify-between items-end mt-[1.5rem]">
                    <div className="w-[49%]">
                        <label className="text-[1.6rem] inter-font font-[400] text-[#111729]">
                            Platform
                            <span className="text-red-500">*</span>
                        </label>
                        <select
                            className="w-full px-4 py-4 mt-[.8rem] text-[1.6rem] inter-font font-[400] border border-[#E3E8EF] rounded-lg focus:outline-none"
                            value={platform}
                            onChange={(e) => {
                                setPlatform(e.target.value);
                                setErrors((prevState) => { const { ["platform"]: _, ...updatedObject } = prevState; return updatedObject; });
                            }}
                        >
                            <option value="" disabled>Select Platform</option>
                            <option value="YOUTUBE">YOUTUBE</option>
                            <option value="INSTAGRAM" disabled>INSTAGRAM (coming soon)</option>
                            <option value="TIKTOK" disabled>TikTok (coming soon)</option>
                        </select>
                        {errors.platform && (
                            <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                                {errors.platform}
                            </p>
                        )}
                    </div>
                    {platform ? <YouTubeOAuth redirectUri={youtube_redirect_uri_auth_only}/> : ''}

                </div>


            </div>

        </div>
    );
}

export default InfluencerChannelAuthOnly;
