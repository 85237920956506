import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NotFound = () => {
    const userType = useSelector((state) => state.utilReducers?.userType);

    // Determine the home route based on userType
    const homeRoute = userType === 'Brand' 
        ? "/brand" 
        : userType === 'Influencer' 
        ? "/influencer" 
        : "/agency";

    return (
        <div className="h-screen bg-gray-100 flex flex-col items-center justify-center space-y-6">
            <h1 className="text-6xl font-extrabold text-gray-700">404</h1>
            <h2 className="text-3xl font-semibold text-gray-800">Page Not Found</h2>
            <p className="text-lg text-gray-500 max-w-lg text-center">
                The page you're looking for doesn't exist. You can go back to your dashboard.
            </p>
            <Link
                to={homeRoute}
                className="mt-4 px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-500 transition duration-300"
            >
                Go to Home
            </Link>
        </div>
    );
};

export default NotFound;
