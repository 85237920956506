import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getUploadedUGCContentList = (payload, callback) => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_LIST_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const getUploadedUGCContentListSuccess = (payload) => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_LIST_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getUploadedUGCContentListFailure = (payload) => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_LIST_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getUploadedUGCContentListReset = () => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_LIST_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getUploadedUGCContentListResetAll = () => ({
    type: actionTypes.GET_UPLOADED_UGC_CONTENT_LIST_RESET_ALL,
}); 