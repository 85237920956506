import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {
    verifyOtpForForgetPasswordFailure,
    verifyOtpForForgetPasswordSuccess
} from "../../actions/authActions/verifyOtpForForgetPasswordActions";

function* verifyOtpForForgetPassword({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.verifyOtpForForgetPassword,
            payload: payload,
            isAuth: false,
        });
        console.log('====================================');
        console.log("VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS status :", response.data.status);
        console.log("VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS data :", response.data.response);
        console.log("VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            verifyOtpForForgetPasswordSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE status :", error?.response?.status);
        console.log("VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE data:", error?.response?.data);
        console.log("VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE error :", error);
        console.log('====================================');
        yield put(verifyOtpForForgetPasswordFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* verifyOtpForForgetPasswordSaga() {
    yield takeLatest(actionTypes.VERIFY_OTP_FOR_FORGET_PASSWORD_REQUEST, verifyOtpForForgetPassword);
}

export default verifyOtpForForgetPasswordSaga;
