import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const myChannelList = (callback) => ({
    type: actionTypes.MY_CHANNEL_LIST_REQUEST,
    callback,
});

// Action creator for successful OTP generation
export const myChannelListSuccess = (payload) => ({
    type: actionTypes.MY_CHANNEL_LIST_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const myChannelListFailure = (payload) => ({
    type: actionTypes.MY_CHANNEL_LIST_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const myChannelListReset = () => ({
    type: actionTypes.MY_CHANNEL_LIST_RESET,
});

// Action creator for resetting all OTP generation reducer
export const myChannelListResetAll = () => ({
    type: actionTypes.MY_CHANNEL_LIST_RESET_ALL,
});
