import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { optAndSubmitContentFailure, optAndSubmitContentSuccess } from '../../actions/InfluencerCampaignActions/optAndSubmitContentActions';

function* optAndSubmitContent({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.optAndSubmitContent,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("OPT_AND_SUBMIT_CONTENT_SUCCESS status :", response.data.status);
        console.log("OPT_AND_SUBMIT_CONTENT_SUCCESS data :", response.data.response);
        console.log("OPT_AND_SUBMIT_CONTENT_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            optAndSubmitContentSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("OPT_AND_SUBMIT_CONTENT_FAILURE status :", error?.response?.status);
        console.log("OPT_AND_SUBMIT_CONTENT_FAILURE data:", error?.response?.data);
        console.log("OPT_AND_SUBMIT_CONTENT_FAILURE error :", error);
        console.log('====================================');
        yield put(optAndSubmitContentFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* optAndSubmitContentSaga() {
    yield takeLatest(actionTypes.OPT_AND_SUBMIT_CONTENT_REQUEST, optAndSubmitContent);
}

export default optAndSubmitContentSaga;
