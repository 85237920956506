import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";
import { useLocation } from "react-router-dom";

const BrandCampaignsDetails = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [formValues, setFormValues] = useState({
        campaignId: "",
        name: "",
        purpose: "",
        platform: "",
        campaignType: "",
        startDate: "",
        endDate: "",
        script: "",
        dialogue: "",
        backgroundColor: "",
        otherProperties: "",
        mediaFileLink: "",
        isLocationRequired: false,
        uploadDuration: "",
    });

    useEffect(() => {
        if (location.state?.campaign) {
            setFormValues(location.state.campaign);
        }
    }, [location.state]);
    return (
        <div className="w-full h-full flex flex-col justify-start bg-[#EDEEF1]">
            <div className="w-full h-full flex flex-col bg-[#F2F3F7]">
                <Breadcrumbs
                    breadcrumbs={[
                        { label: "Home", href: "/" },
                        { label: "My Campaigns", href: "/influencer/campaigns" },
                        { label: "Campaigns Details", href: "/influencer/campaigns/details" },
                    ]}
                />
                <form className="w-full h-full p-8 shadow-md space-y-4 mt-5">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Campaign Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formValues.name}
                                placeholder="Enter campaign name"
                                className="w-3/4 h-12 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-400 text-[1.4rem]"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Purpose of the Campaign</label>
                            <input
                                type="text"
                                name="purpose"
                                value={formValues.purpose}
                                placeholder="Enter purpose"
                                className="w-3/4 h-12 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-400 text-[1.4rem]"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Social Media Platform</label>
                            <input
                                type="text"
                                name="platform"
                                value={formValues.platform}
                                placeholder="e.g., YouTube"
                                className="w-3/4 h-12 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-400 text-[1.4rem]"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Campaign Type</label>
                            <input
                                type="text"
                                name="campaignType"
                                value={formValues.campaignType}
                                placeholder="e.g., Video"
                                className="w-3/4 h-12 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-400 text-[1.4rem]"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Start Date</label>
                            <input
                                type="date"
                                name="startDate"
                                value={formValues.startDate}
                                className="w-3/4 h-12 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-400 text-[1.4rem]"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium mb-2 text-[1.4rem]">End Date</label>
                            <input
                                type="date"
                                name="endDate"
                                value={formValues.endDate}
                                className="w-3/4 h-12 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-400 text-[1.4rem]"
                            />
                        </div>
                        {formValues?.campaignType == "UGC" ?
                            <div>
                                <label for="script" className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Script</label>
                                <textarea
                                    type="text"
                                    value={formValues?.script}
                                    placeholder="script"
                                    className="w-3/4 h-[8rem] border border-gray-300 text-[1.6rem] text-[#111729] inter-font font-[400] mt-[1rem] rounded-lg p-4 resize-y"
                                    maxLength={1000}
                                />
                            </div>
                            :
                            null
                        }
                        {formValues?.campaignType == "UGC" ?
                            <div>
                                <label for="dialogue" className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Dialogue</label>
                                <textarea
                                    type="text"
                                    value={formValues?.dialogue}
                                    placeholder="dialogue"
                                    className="w-3/4 h-[8rem] border border-gray-300 text-[1.6rem] text-[#111729] inter-font font-[400] mt-[1rem] rounded-lg p-4 resize-y"
                                    maxLength={1000}
                                />
                            </div>
                            :
                            null
                        }
                        {formValues?.campaignType == "UGC" ?
                            <div>
                                <label for="properties" className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Other Properites</label>
                                <textarea
                                    type="text"
                                    value={formValues?.otherProperties}
                                    placeholder="properties"
                                    className="w-3/4 h-[8rem] border border-gray-300 text-[1.6rem] text-[#111729] inter-font font-[400] mt-[1rem] rounded-lg p-4 resize-y"
                                    maxLength={1000}
                                />
                            </div>
                            :
                            null
                        }
                        {formValues?.campaignType == "UGC" ?
                            <div>
                                <label className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Background Color</label>
                                <input
                                    type="text"
                                    name="Color"
                                    value={formValues.backgroundColor}
                                    className="w-3/4 h-12 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-400 text-[1.4rem]"
                                />
                            </div>
                            :
                            null
                        }
                        {formValues?.campaignType == "UGC" ?
                            <div className="">
                                <label className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Location Required</label>
                                <input
                                    className="w-3/4 h-12 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-400 text-[1.4rem]"
                                    value={formValues?.isLocationRequired ? "Yes" : "No"}
                                />
                            </div>
                            :
                            null
                        }
                        {formValues?.campaignType == "UGC" ?
                            <div className="">
                                <label className="block text-gray-700 font-medium mb-2 text-[1.4rem]">Upload Duration days</label>
                                <input
                                    className="w-3/4 h-12 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-400 text-[1.4rem]"
                                    value={formValues?.uploadDuration}
                                />

                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="flex space-x-6 mt-4">
                        <button
                            type="button"
                            onClick={() => navigate("/brand/campaigns")}
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 text-[1.4rem]"
                        >
                            Back
                        </button>
                    </div>
                </form>
            </div >
        </div >
    )
}

export default BrandCampaignsDetails