import React from "react";
import Header from "../../main/Header/Header";
import BrandSidebar from "../../main/BrandSidebar/BrandSidebar";

const BrandLayout = ({ children }) => {

    return (
        <div className='w-full h-full'>
            <Header/>
            <div className='flex justify-between h-[90%]'>
                <BrandSidebar/>
                {children}
            </div>
        </div>
    )
};

export default BrandLayout;
