import React, { Fragment, useRef, useState } from 'react'
import Breadcrumbs from '../../components/common/Breadcrumbs/Breadcrumbs'
import { searchBusinessPlace } from '../../store/BusinessStore/actions/BrandBusinessActions/searchBusinessPlaceActions';
import { useDispatch, useSelector } from 'react-redux';
import { testData } from '../../testData';
import { IoIosSearch } from 'react-icons/io';
import StarRating from '../../components/utility/StarRating/StarRating';
import { CiGlobe, CiStar } from 'react-icons/ci';
import { FiPhone } from 'react-icons/fi';
import { MdOutlineLocationOn } from 'react-icons/md';
import { FaCircleCheck, FaCircleExclamation } from 'react-icons/fa6';
import Popup from '../../components/popups/Popup/Popup';
import { registerBusiness } from '../../store/BusinessStore/actions/BrandBusinessActions/registerBusinessActions';
import { useNavigate } from 'react-router';
import { getMyBrandBusinessDetail } from '../../store/BusinessStore/actions/BrandBusinessActions/getMyBrandBusinessDetailActions';
import {useToast} from "../../hooks/useToast";

function BrandBusinessDashboard() {
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const Toast = useToast();

    const searchBusinessPlaceReducer = useSelector(state => state.searchBusinessPlaceReducer)

    // Example StarIcon component (SVG)
    const StarIcon = ({ className }) => (
        <svg className={className} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2.5l2.76 5.6 6.2.9-4.48 4.4 1.06 6.2L12 16.5l-5.54 2.9 1.06-6.2L3.5 9l6.2-.9L12 2.5z" />
        </svg>
    );

    const [searchQuery, setSearchQuery] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(true);
    const [selectedBusiness, setSelectedBusiness] = useState({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isSuccessPopupOpen, setSuccessPopupOpen] = useState(false);
    const [isConfirmPopupOpen, setConfirmPopupOpen] = useState(false); // New state for confirmation popup

    const breadcrumbData = [
        { label: 'Home', href: '/' },
        { label: 'My Campaigns', href: '/brand/campaigns/create' },
    ];

    // Function to handle the API call
    const handleSearch = async () => {
        if (!searchQuery) return;

        dispatch(searchBusinessPlace(searchQuery, (status, response, message) => {
            if(status === 200){
                setDropdownVisible(true);
                setSelectedBusiness({})
            }else {
                setPopupOpen(true)
                setSelectedBusiness({})
            }
        }))
    };

    // Function to handle the "Add" action after confirmation
    const handleAddBusiness = () => {
        dispatch(registerBusiness({
            "placeId": selectedBusiness?.placeId
        }, (status, response, message) => {
            if (status === 200) {
                dispatch(getMyBrandBusinessDetail(() => { }))
                setSuccessPopupOpen(true)
            } else {
                Toast(message, 'error');
            }
        }));
    };

    return (
        <div className='w-full flex flex-col overflow-auto bg-[#F2F3F7]'>
            <Breadcrumbs breadcrumbs={breadcrumbData} />
            <div className='p-[4rem] min-h-[70rem] relative'>
                <div className="primary-search relative h-[5.6rem]">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-[1.4rem] pointer-events-none">
                        <IoIosSearch size={16}/>
                    </div>
                    <input
                        type="search"
                        autoFocus
                        ref={inputRef}
                        id="default-search"
                        className="block w-full h-full ps-[4rem] text-[1.6rem] text-gray-900 bg-[#FFFFFF] focus:border-none focus:outline-none"
                        placeholder="Search Your Business"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                        }}
                    />
                    <button
                        type="submit"
                        onClick={handleSearch}
                        className="text-white absolute end-[1.2rem] top-[50%] translate-y-[-50%] bg-[#0D9B50] rounded-[.8rem] px-[2rem] py-[.6rem] text-[1.6rem]"
                    >
                        Search
                    </button>
                </div>
                <div className="absolute left-[4rem] right-[4rem] h-[66rem] p-0 pb-[5rem] !overflow-y-scroll no-scrollbar" ref={dropdownRef}>
                    {dropdownVisible && searchBusinessPlaceReducer?.data?.length > 0 && (
                        <div className="w-full mt-1 bg-[#FFFFFF]">
                            {searchBusinessPlaceReducer?.data?.map((item, index) => (
                                <div
                                    key={index}
                                    className={`h-[12rem] flex flex-col border-b ps-[4rem] py-[1.6rem] text-[3rem] text-[#333333] cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 `}
                                    onClick={() => {
                                        setSelectedBusiness(item); // Adjust according to actual data structure
                                        setDropdownVisible(false);
                                    }}
                                >
                                    <div className="flex justify-start items-center">
                                        <h2 className="text-[1.6rem] font-[600]">{item?.name}</h2>
                                        <h2 className="text-[1.4rem] font-[600] ml-[3.2rem] mr-[.4rem]">{item?.rating}</h2>
                                        <StarRating rating={item?.rating} maxStars={5} StarIcon={StarIcon} />
                                        <h2 className="text-[1.4rem] font-[600] ml-[.4rem]">{`(${item?.userRatingsTotal})`}</h2>
                                    </div>
                                    <div className="text-[1.6rem] text-[#8897AD] my-[.8rem]">
                                        {item?.formattedAddress}
                                    </div>
                                    <div className="text-[1.6rem] font-[600] text-[#0D9B50]">
                                        {item?.businessStatus}
                                    </div>
                                     {/* Adjust according to actual data property */}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {Object.keys(selectedBusiness).length !== 0 &&
                    <div className='flex flex-col justify-start items-start'>
                        <div className="w-full flex flex-col justify-between h-[22rem] mt-[4rem] overflow-auto rounded-[2.6rem] p-[2.9rem] bg-[#FFF]">
                            <h2 className="text-[2.2rem] font-[600]">{selectedBusiness?.name}</h2>
                            <div className="flex justify-start items-center">
                                <h2 className="text-[1.4rem] font-[600] mr-[.4rem]">{selectedBusiness?.rating}</h2>
                                <StarRating rating={selectedBusiness?.rating} maxStars={5} StarIcon={StarIcon} />
                                <h2 className="text-[1.4rem] font-[600] ml-[.4rem]">{`(${selectedBusiness?.userRatingsTotal})`}</h2>
                            </div>
                            <div className="text-[1.6rem] text-[#8897AD] my-[.8rem]">
                                {selectedBusiness?.formattedAddress}
                            </div>
                            {/*<div className="flex justify-start items-center">
                                <div className="flex justify-start items-center mr-[2rem]">
                                    <CiGlobe size={24} color='#C84949' className='mr-[1.2rem]'/>
                                    <h2 className="text-[1.6rem] mr-[.4rem]">Mitindore.co.in</h2>
                                </div>
                                <div className="flex justify-start items-center mr-[2rem]">
                                    <FiPhone size={24} color='#C84949' className='mr-[1.2rem]'/>
                                    <h2 className="text-[1.6rem] mr-[.4rem]">0731 281 0001</h2>
                                </div>
                                <div className="flex justify-start items-center mr-[2rem]">
                                    <MdOutlineLocationOn size={24} color='#C84949' className='mr-[1.2rem]'/>
                                    <h2 className="text-[1.6rem] mr-[.4rem]">Qw5q+88 Indore, Madhya Pradesh</h2>
                                </div>
                            </div>*/}
                            <div className="text-[1.6rem] font-[600] text-[#0D9B50] mt-[1.2rem]">
                                {selectedBusiness?.businessStatus}
                            </div>
                        </div>
                        <div className="flex justify-start space-x-[1.8rem] mt-[2.2rem] z-[10]">
                            {/*<button*/}
                            {/*    className="w-[16rem] px-4 py-[1rem] text-[2rem] rounded-[1.2rem] cursor-pointer border border-[#0D9B50] text-[#0D9B50] hover:bg-[#0D9B50]/10"*/}
                            {/*    onClick={() => {}}*/}
                            {/*>*/}
                            {/*    Cancel*/}
                            {/*</button>*/}
                            <button
                                className="w-[16rem] px-4 py-[1rem] text-[2rem] rounded-[1.2rem] cursor-pointer bg-[#0D9B50] text-white hover:bg-[#0D9B50]/90"
                                onClick={() => {
                                    setConfirmPopupOpen(true); // Open confirmation popup
                                }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                }
            </div>
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setPopupOpen(false)}
                title="Failure"
                canDismiss
                icon={<FaCircleExclamation color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>Business not found, please verify your details </p>}
                size="sm"
                primaryButton={{
                    text: 'Done',
                    onClick: () => {
                        setPopupOpen(false);
                        setSearchQuery('')
                        if (inputRef.current) {
                            inputRef.current.focus();
                        }
                    },
                }}
            />
            <Popup
                isOpen={isSuccessPopupOpen}
                onClose={() => setSuccessPopupOpen(false)}
                title="Success"
                canDismiss
                icon={<FaCircleCheck color="#0D9B50" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>Success</p>}
                size="sm"
                primaryButton={{
                    text: 'Done',
                    onClick: () => {
                        navigate('/brand/campaigns')
                        setSuccessPopupOpen(false);
                    },
                }}
            />
            {/* New Confirmation Popup */}
            <Popup
                isOpen={isConfirmPopupOpen}
                onClose={() => setConfirmPopupOpen(false)}
                title=""
                canDismiss
                icon={<FaCircleCheck color="#0D9B50" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>Are you sure you want to add this business?</p>}
                size="sm"
                primaryButton={{
                    text: 'Yes',
                    onClick: () => {
                        handleAddBusiness();
                        setConfirmPopupOpen(false);
                    },
                }}
                secondaryButton={{
                    text: 'No',
                    onClick: () => {
                        setConfirmPopupOpen(false);
                    },
                }}
            />
        </div>
    )
}

export default BrandBusinessDashboard
