import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const getMyBrandBusinessDetail = (callback) => ({
    type: actionTypes.GET_MY_BRAND_BUSINESS_REQUEST,
    callback,
});

// Action creator for successful OTP generation
export const getMyBrandBusinessDetailSuccess = (payload) => ({
    type: actionTypes.GET_MY_BRAND_BUSINESS_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getMyBrandBusinessDetailFailure = (payload) => ({
    type: actionTypes.GET_MY_BRAND_BUSINESS_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getMyBrandBusinessDetailReset = () => ({
    type: actionTypes.GET_MY_BRAND_BUSINESS_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getMyBrandBusinessDetailResetAll = () => ({
    type: actionTypes.GET_MY_BRAND_BUSINESS_RESET_ALL,
});
