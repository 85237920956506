import React from 'react';

// StarRating component
const StarRating = ({ rating, maxStars = 5, StarIcon }) => {
    const stars = [];

    for (let i = 1; i <= maxStars; i++) {
        if (i <= Math.floor(rating)) {
            // Full star for values less than or equal to the integer part of rating
            stars.push(
                <StarIcon key={i} className="w-6 h-6 text-yellow-500" />
            );
        } else if (i - rating < 1 && i - rating > 0) {
            // Half star for fractional values, if available as a prop
            stars.push(
                <StarIcon key={i} className="w-6 h-6 text-yellow-500 opacity-50" />
            );
        } else {
            // Empty star for remaining stars
            stars.push(
                <StarIcon key={i} className="w-6 h-6 text-gray-300" />
            );
        }
    }

    return <div className="flex">{stars}</div>;
};

export default StarRating;
