import {call, put, takeLatest} from 'redux-saga/effects';
import {base_url, endpoints} from '../../../constants';
import {AxiosService} from '../../../services';
import {actionTypes} from '../../../types';
import {verifyOtpForLoginFailure, verifyOtpForLoginSuccess} from "../../actions/authActions/verifyOtpForLoginActions";
import {setAccessToken} from "../../../publicStore/actions/utilsActions/utilsActions";

function* verifyOtpForLogin({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.verifyOtpForLogin,
            payload: payload,
            isAuth: false,
        });
        console.log('====================================');
        console.log("VERIFY_OTP_FOR_LOGIN_SUCCESS status :", response.data.status);
        console.log("VERIFY_OTP_FOR_LOGIN_SUCCESS data :", response.data.response);
        console.log("VERIFY_OTP_FOR_LOGIN_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(setAccessToken(response?.data?.response?.accessToken));
        yield put(
            verifyOtpForLoginSuccess(
                {
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("VERIFY_OTP_FOR_LOGIN_FAILURE status :", error?.response?.status);
        console.log("VERIFY_OTP_FOR_LOGIN_FAILURE data:", error?.response?.data);
        console.log("VERIFY_OTP_FOR_LOGIN_FAILURE error :", error);
        console.log('====================================');
        yield put(verifyOtpForLoginFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* verifyOtpForLoginSaga() {
    yield takeLatest(actionTypes.VERIFY_OTP_FOR_LOGIN_REQUEST, verifyOtpForLogin);
}

export default verifyOtpForLoginSaga;
