import React, { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import { useDispatch, useSelector } from 'react-redux';
import { setIsMergePopupActive, setStreamVideoResponse } from '../../../store/publicStore/actions/utilsActions/utilsActions';
import { checkContentMergeStatus, checkContentMergeStatusReset } from '../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/checkContentMergeStatusActions';
import { useToast } from '../../../hooks/useToast';
import { streamVideo, streamVideoReset } from '../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/streamVideoActions';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router';

const MergePopup = () => {

    const dispatch = useDispatch()
    const Toast = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const { isMergePopupActive, optAndSubmitContentResponse } = useSelector((state) => state.utilReducers);

    useEffect(() => {
        let interval; // Declare interval reference outside

        if (optAndSubmitContentResponse?.contentId) {
            setIsLoading(true);

            const checkStatus = () => {
                dispatch(
                    checkContentMergeStatus(
                        { contentId: optAndSubmitContentResponse?.contentId },
                        (status, response, message) => {
                            console.log("checkContentMergeStatus status:", status);

                            if (status === 202) {
                                return; // Keep the interval running
                            }

                            console.log("checkContentMergeStatus message:", message);


                            clearInterval(interval); // Clear interval on any other status


                            if (status === 200) {
                                setTimeout(() => {
                                    dispatch(
                                        streamVideo(
                                            { s3ContentPath: optAndSubmitContentResponse?.contentPath },
                                            (status, response, message) => {
                                                if (status === 200) {
                                                    dispatch(setStreamVideoResponse(response));
                                                    setIsLoading(false);
                                                    dispatch(checkContentMergeStatusReset());
                                                    dispatch(streamVideoReset());
                                                } else if (status) {
                                                    Toast(message, "error");
                                                    dispatch(checkContentMergeStatusReset());
                                                    dispatch(streamVideoReset());
                                                    setIsLoading(false);
                                                }
                                            }
                                        )
                                    );
                                }, 5000);
                            } else if (status === 302) {
                                Toast(message, "error");
                                dispatch(checkContentMergeStatusReset());
                                setIsLoading(false);
                            } else if (status !== 202) {
                                Toast(message, "error");
                                dispatch(checkContentMergeStatusReset());
                                setIsLoading(false);
                            }
                        }
                    )
                );
            };

            interval = setInterval(checkStatus, 10000);

            // Cleanup interval on component unmount
            return () => clearInterval(interval);
        }
    }, [optAndSubmitContentResponse, isMergePopupActive]);

    console.log("isMergePopupActive :", isMergePopupActive);



    return isMergePopupActive && (
        <div
            id="popup-overlay"
            className="absolute bottom-[2rem] right-[2rem] flex items-center justify-center z-50 w-[20%]"
        >
            <div
                className={`bg-white rounded-lg shadow-lg p-[3.2rem] w-full relative border-2 border-[#0D9B50]`}
                onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing
            >


                <div className="flex items-center justify-between">
                    <h2 className="text-[#202224] text-[2rem] font-semibold">{isLoading ? "Merge in Progress..." : "Merge Completed"}</h2>
                    {isLoading ?
                        <Oval
                            visible={true}
                            height="25"
                            width="25"
                            color="#000000"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        :
                        null
                    }
                </div>
                {isLoading ?
                    null
                    :
                    <button
                        className="bg-[#0D9B50] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem] text-[1.4rem]"
                        onClick={() => { dispatch(setIsMergePopupActive(false)); navigate("/influencer/campaigns/upload") }}>
                        Continue
                    </button>
                }
            </div>
        </div>
    )
}

export default MergePopup