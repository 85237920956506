import React, { useEffect } from 'react';
import { RxCross1 } from 'react-icons/rx';

const Popup = ({
    isOpen,
    onClose,
    title,
    canDismiss = true,
    icon,
    content,
    size = 'md',
    primaryButton ,
    secondaryButton,
}) => {
    // Close popup on clicking outside
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if(!canDismiss)return;
            if (e.target.id === 'popup-overlay') {
                onClose();
            }
        };
        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
        }
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [isOpen, onClose]);

    // Define modal size classes
    const sizeClasses = {
        sm: 'w-1/4',
        md: 'w-1/2',
        lg: 'w-3/4',
    };

    if (!isOpen) return null;

    return (
        <div
            id="popup-overlay"
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
            <div
                className={`bg-white rounded-lg shadow-lg p-[3.2rem] ${sizeClasses[size]} relative`}
                onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing
            >
                {/* Header */}
                <div className="flex items-center justify-between">
                    <h2 className="text-[#202224] text-[2rem] font-semibold">{title}</h2>
                    {canDismiss && 
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            <RxCross1 size={24} />
                        </button>
                    }
                </div>

                {/* Icon */}
                {icon && <div className="flex justify-center">{icon}</div>}

                {/* Content */}
                <div className="mb-[4rem]">{content}</div>

                {/* Buttons */}
                {(primaryButton || secondaryButton) && (
                    <div className="flex justify-center space-x-[1.7rem]">
                        {primaryButton && (
                            <button
                                onClick={primaryButton.onClick}
                                className="w-full px-4 py-[1rem] text-[2rem] rounded-[1.2rem] bg-[#0D9B50] text-white hover:bg-[#0D9B50]/90"
                            >
                                {primaryButton.text}
                            </button>
                        )}
                        {secondaryButton && (
                            <button
                                onClick={secondaryButton.onClick}
                                className="w-full px-4 py-[1rem] text-[2rem] rounded-[1.2rem] border border-[#0D9B50] text-[#0D9B50] hover:bg-[#0D9B50]/10"
                            >
                                {secondaryButton.text}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Popup;
