import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const stripeOnboarding = (payload, callback) => ({
    type: actionTypes.STRIPE_ONBOARDING_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const stripeOnboardingSuccess = (payload) => ({
    type: actionTypes.STRIPE_ONBOARDING_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const stripeOnboardingFailure = (payload) => ({
    type: actionTypes.STRIPE_ONBOARDING_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const stripeOnboardingReset = () => ({
    type: actionTypes.STRIPE_ONBOARDING_RESET,
});

// Action creator for resetting all OTP generation reducer
export const stripeOnboardingResetAll = () => ({
    type: actionTypes.STRIPE_ONBOARDING_RESET_ALL,
});
