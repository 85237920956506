import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { createCampaignFailure, createCampaignSuccess } from '../../actions/CreateCampaignActions/createCampaignActions';

function* createCampaign({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.createCampaign,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("CREATE_CAMPAIGN_SUCCESS status :", response.data.status);
        console.log("CREATE_CAMPAIGN_SUCCESS data :", response.data.response);
        console.log("CREATE_CAMPAIGN_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            createCampaignSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(response.data); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("CREATE_CAMPAIGN_FAILURE status :", error?.response?.status);
        console.log("CREATE_CAMPAIGN_FAILURE data:", error?.response?.data);
        console.log("CREATE_CAMPAIGN_FAILURE error :", error);
        console.log('====================================');
        yield put(createCampaignFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* createCampaignSaga() {
    yield takeLatest(actionTypes.CREATE_CAMPAIGN_REQUEST, createCampaign);
}

export default createCampaignSaga;