import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {setBrandCampaignCreationStep} from "../../../../store/publicStore/actions/utilsActions/utilsActions";

function BottomNavigation({ isNextDisabled, handleNext }) {
    const dispatch = useDispatch();

    const { brandCampaignCreationStep } = useSelector(state => state.utilReducers);

    return (
        <div className="w-full flex my-[3.2rem]">
            {brandCampaignCreationStep !== 1 &&
                <button
                    onClick={() => dispatch(setBrandCampaignCreationStep(brandCampaignCreationStep - 1))}
                    className="w-[15rem] h-[5rem] text-[#0D9B50] text-[1.8rem] inter-font font-[400] border border-[#0D9B50] bg-[#ffffff] hover:scale-95 text-center rounded-[.8rem] cursor-pointer mr-[3rem]"
                >
                    Back
                </button>
            }
            <button
                onClick={handleNext}
                className={`${brandCampaignCreationStep === 4 ? "w-[20rem]" : "w-[15rem]"} h-[5rem] text-[#FFFFFF] text-[1.8rem] inter-font font-[400] ${isNextDisabled ? "bg-[#C9C9C9] cursor-not-allowed" : "bg-[#0D9B50] hover:scale-95 cursor-pointer"}  text-center rounded-[.8rem]`}
                disabled={isNextDisabled}
            >
                {brandCampaignCreationStep === 4 ? "Make Payment" : "Next"}
            </button>
        </div>
    )
}

export default BottomNavigation
