import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { resubmissionOptAndSubmitContentUGCFailure, resubmissionOptAndSubmitContentUGCSuccess } from '../../actions/InfluencerCampaignActions/resubmissionOptAndSubmitContentUGCActions';

function* resubmissionOptAndSubmitContentUGC({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.resubmissionOptAndSubmitContentUGC,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_SUCCESS status :", response.data.status);
        console.log("RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_SUCCESS data :", response.data.response);
        console.log("RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            resubmissionOptAndSubmitContentUGCSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_FAILURE status :", error?.response?.status);
        console.log("RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_FAILURE data:", error?.response?.data);
        console.log("RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_FAILURE error :", error);
        console.log('====================================');
        yield put(resubmissionOptAndSubmitContentUGCFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* resubmissionOptAndSubmitContentUGCSaga() {
    yield takeLatest(actionTypes.RESUBMISSION_OPT_AND_SUBMIT_CONTENT_UGC_REQUEST, resubmissionOptAndSubmitContentUGC);
}

export default resubmissionOptAndSubmitContentUGCSaga;
