import React, { useEffect, useRef } from 'react';

const DynamicImagePreview = (jsonInput) => {
    const canvasRef = useRef(null);
    console.log("jsonInput : ", jsonInput)


    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const width = canvas.width;
        const height = canvas.height;

        // Clear canvas
        ctx.clearRect(0, 0, width, height);

        // Set background color
        ctx.fillStyle = 'rgba(224, 224, 224, 0)';
        ctx.fillRect(0, 0, width, height);

        const drawElements = async () => {
            // Sort top and bottom elements by sequence
            const topElements = jsonInput.elements.filter(el => el.vertical_position === 'top').sort((a, b) => a.sequence - b.sequence);
            const bottomElements = jsonInput.elements.filter(el => el.vertical_position === 'bottom').sort((a, b) => a.sequence - b.sequence);

            let topY = 0;
            let bottomY = height;

            // Handle top elements
            for (const element of topElements) {
                topY = await addElement(ctx, element, width, height, topY, true);
            }

            // Handle bottom elements
            for (const element of bottomElements) {
                bottomY = await addElement(ctx, element, width, height, bottomY, false);
            }
        };

        const addElement = async (ctx, element, canvasWidth, canvasHeight, currentY, isTop) => {
            const { type, width_percentage, height_percentage, content_or_url, font_size_in_pixel, horizontal_position,localUrl } = element;
            const elementWidth = canvasWidth * (width_percentage / 100);
            const elementHeight = canvasHeight * (height_percentage / 100);

            if (type === 'image') {
                return await addImageElement(ctx, localUrl, elementWidth, elementHeight, canvasWidth, currentY, isTop, horizontal_position);
            } else if (type === 'text') {
                return addTextElement(ctx, content_or_url, font_size_in_pixel, elementWidth, elementHeight, canvasWidth, currentY, isTop, horizontal_position);
            }

            return currentY;
        };

        const addImageElement = async (ctx, imageUrl, elementWidth, elementHeight, canvasWidth, currentY, isTop, horizontalPosition) => {
            const img = new Image();
            img.src = imageUrl;

            return new Promise((resolve) => {
                img.onload = () => {
                    const x = calculateHorizontalPosition(horizontalPosition, elementWidth, canvasWidth);
                    const y = isTop ? currentY : currentY - elementHeight;

                    // Draw the image
                    ctx.drawImage(img, x, y, elementWidth, elementHeight);
                    resolve(isTop ? y + elementHeight : y);
                };
            });
        };

        const addTextElement = (ctx, text, fontSize, elementWidth, elementHeight, canvasWidth, currentY, isTop, horizontalPosition) => {
            ctx.font = `${fontSize}px Arial`;
            ctx.fillStyle = 'black';

            const textWidth = ctx.measureText(text).width;
            const x = calculateHorizontalPosition(horizontalPosition, textWidth, canvasWidth);
            const y = isTop ? currentY + elementHeight : currentY - elementHeight;

            // Draw the text
            ctx.fillText(text, x, y);

            return isTop ? y : y - elementHeight;
        };

        const calculateHorizontalPosition = (position, elementWidth, canvasWidth) => {
            switch (position) {
                case 'center':
                    return (canvasWidth - elementWidth) / 2;
                case 'left':
                    return 0;
                case 'right':
                    return canvasWidth - elementWidth;
                default:
                    return 0;
            }
        };

        // Draw the elements
        drawElements();
    }, [jsonInput]);

    return (
        <div style={{ width: '100%', aspectRatio: '9 / 16' }}>
            <canvas ref={canvasRef} width={1080} height={1920} style={{ width: '100%', height: '100%', border: '1px solid black' }} />
        </div>
    );
};

export default DynamicImagePreview;
