import React, {useEffect, useState} from 'react';
import {LoginPageImg} from '../../static/constant/imgConstants';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useToast} from '../../hooks/useToast';
import PublicHeader from '../../components/utility/PublicHeader/PublicHeader';
import {setEmailForForgetPassword} from "../../store/publicStore/actions/utilsActions/utilsActions";
import {sendOtpForForgetPassword, sendOtpForForgetPasswordReset} from "../../store/authStore/actions/authActions/sendOtpForForgetPasswordActions";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const Toast = useToast();
    const navigate = useNavigate();

    const sendOtpForForgetPasswordReducer = useSelector(state => state.sendOtpForForgetPasswordReducer)

    const [loginType, setLoginType] = useState('Influencer');
    const [errors, setErrors] = useState({});

    const validateForm = (data) => {
        let formErrors = {};
        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!data.email.trim()) {
            formErrors.email = 'Email is required';
        } else if (!emailRegex.test(data.email)) {
            formErrors.email = 'Enter a valid email address';
        }

        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            email: e.target.elements.email.value,
        };

        const formErrors = validateForm(formData);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            setErrors({});
            dispatch(setEmailForForgetPassword(formData.email))
            dispatch(sendOtpForForgetPassword({
                "email": formData.email,
                "isBrand" : loginType === "Brand"
            }))
        }

    };

    useEffect(() => {
        if(sendOtpForForgetPasswordReducer.status === 200){
            dispatch(sendOtpForForgetPasswordReset())
            navigate('/verifyEmailOtp/'+loginType.toLowerCase())
        }else if(sendOtpForForgetPasswordReducer.status === 403){
            Toast(sendOtpForForgetPasswordReducer.message, 'error' )
            dispatch(sendOtpForForgetPasswordReset())
        }else if(sendOtpForForgetPasswordReducer.status){
            Toast(sendOtpForForgetPasswordReducer.message, 'info' )
            dispatch(sendOtpForForgetPasswordReset())
        }
    },[sendOtpForForgetPasswordReducer])

    return (
        <div className='flex min-h-[80rem] h-[100vh]'>
            <div className='flex flex-col justify-start items-center w-[58%] h-full px-[4.8rem] py-[3rem]'>
                <PublicHeader />
                <div className='flex flex-grow flex-col justify-start items-start text-[1.6rem] w-[40%] max-h-[90%]'>
                    <div className='login-nav flex justify-between items-center w-full my-[2.4rem]'>
                        <h4
                            className={`text-[1.8rem] cursor-pointer ${loginType === 'Brand' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'}`}
                            onClick={() => {setLoginType('Brand')}}
                        >
                            Brand
                        </h4>
                        <h4
                            className={`text-[1.8rem] cursor-pointer ${loginType === 'Influencer' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'}`}
                            onClick={() => {setLoginType('Influencer')}}
                        >
                            Influencer
                        </h4>
                        <h4
                            className={`text-[1.8rem] cursor-pointer ${loginType === 'Influencer' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'}`}
                            onClick={() => {}}
                        >

                        </h4>
                    </div>
                    <h2 className="text-[1.6rem] text-start text-[#313957]  md:text-[1.8rem] font-semibold mb-3 md:mb-4">
                        Forgot Password
                    </h2>
                    <p className="text-[1.4rem] text-start  md:text-[1.6rem] text-[#313957] mb-4">
                        Enter your email for the verification proccess,we will send 4 digits code to your email.
                    </p>
                    <form onSubmit={handleSubmit} className='w-full my-[2rem]'>
                        <div className={`w-full mb-[1rem]`}>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                className={`w-full px-[1.6rem] py-[.8rem] bg-[#F3F9FA] border rounded-[1.2rem] focus:outline-none ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                                />
                            {errors.email && <p className="text-right text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-[#0D9B50] text-[2rem] text-white py-3 mb-4 mt-[1rem] lg:mt-[2rem] rounded-[1.2rem] hover:bg-green-700"
                        >
                            Continue
                        </button>
                    </form>
                </div>
            </div>
            <div className='flex flex-col justify-start items-center w-[42%] h-full'>
                <img src={LoginPageImg.loginlogo} alt="" className="w-full" />
            </div>
        </div>
    );
}

export default ForgotPassword;
