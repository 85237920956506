import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const createCampaign = (payload, callback) => ({
    type: actionTypes.CREATE_CAMPAIGN_REQUEST,
    payload,
    callback
});

// Action creator for successful OTP generation
export const createCampaignSuccess = (payload) => ({
    type: actionTypes.CREATE_CAMPAIGN_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const createCampaignFailure = (payload) => ({
    type: actionTypes.CREATE_CAMPAIGN_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const createCampaignReset = () => ({
    type: actionTypes.CREATE_CAMPAIGN_RESET,
});

// Action creator for resetting all OTP generation reducer
export const createCampaignResetAll = () => ({
    type: actionTypes.CREATE_CAMPAIGN_RESET_ALL,
}); 