import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

import utilReducers from "../publicStore/reducers/utilReducers/utilReducers";
import getAllCountryReducer from "../publicStore/reducers/publicReducers/getAllCountryReducer";

import loginReducer from "../authStore/reducers/authReducers/loginReducer";
import googleUserLoginReducer from "../authStore/reducers/authReducers/googleUserLoginReducer";
import getUserInfoReducer from "../authStore/reducers/authReducers/getUserInfoReducer";
import createUserReducer from "../authStore/reducers/authReducers/createUserReducer";
import verifyOtpAndCreateUserReducer from "../authStore/reducers/authReducers/verifyOtpAndCreateUserReducer";
import sendOtpForForgetPasswordReducer from "../authStore/reducers/authReducers/sendOtpForForgetPasswordReducer";
import verifyOtpForForgetPasswordReducer from "../authStore/reducers/authReducers/verifyOtpForForgetPasswordReducer";
import forgetPasswordResetReducer from "../authStore/reducers/authReducers/forgetPasswordResetReducer";
import sendOtpToCreateUserReducer from "../authStore/reducers/authReducers/sendOtpToCreateUserReducer";
import userLoginReducer from "../authStore/reducers/authReducers/userLoginReducer";
import verifyOtpForLoginReducer from "../authStore/reducers/authReducers/verifyOtpForLoginReducer";
import logoutReducer from "../authStore/reducers/authReducers/logoutReducer";
import getAllRegionReducer from "../campaignStore/reducers/campaignMasterReducers/getAllRegionReducer";
import getCountryByRegionReducer from "../campaignStore/reducers/campaignMasterReducers/getCountryByRegionReducer";
import getStateByCountryReducer from "../campaignStore/reducers/campaignMasterReducers/getStateByCountryReducer";
import getAllAgeGroupReducer from "../campaignStore/reducers/campaignMasterReducers/getAllAgeGroupReducer";
import getAllNicheReducer from "../campaignStore/reducers/campaignMasterReducers/getAllNicheReducer";
import getBudgetReducer from "../campaignStore/reducers/createCampaignReducers/getBudgetReducer";
import getMaxViewsReducer from "../campaignStore/reducers/createCampaignReducers/getMaxViewsReducer";
import getBrandCampaignReducer from "../campaignStore/reducers/createCampaignReducers/getBrandCampaignReducer";
import getAllBannerTemplateReducer from "../campaignStore/reducers/createCampaignReducers/getAllBannerTemplateReducer";
import uploadCampaignFileReducer from "../campaignStore/reducers/createCampaignReducers/uploadCampaignFileReducer";
import createCampaignReducer from "../campaignStore/reducers/createCampaignReducers/createCampaignReducer";
import getMyBrandBusinessDetailReducer from "../BusinessStore/reducers/BrandBusinessReducers/getMyBrandBusinessDetailReducer";
import searchBusinessPlaceReducer from "../BusinessStore/reducers/BrandBusinessReducers/searchBusinessPlaceReducer";
import registerBusinessReducer from "../BusinessStore/reducers/BrandBusinessReducers/registerBusinessReducer";
import myChannelListReducer from "../BusinessStore/reducers/InfluencerChannelReducers/myChannelListReducer";
import storeYoutubeAuthReducer from "../BusinessStore/reducers/InfluencerChannelReducers/storeYoutubeAuthReducer";
import getYoutubeChannelListReducer from "../BusinessStore/reducers/InfluencerChannelReducers/getYoutubeChannelListReducer";
import storeValidateChannelReducer from "../BusinessStore/reducers/InfluencerChannelReducers/storeValidateChannelReducer";
import getCampaignListFilterReducer
	from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/getCampaignListFilterReducer";
import streamVideoReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/streamVideoReducer";
import uploadInfluencerVideoReducer
	from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/uploadInfluencerVideoReducer";
import optAndSubmitContentReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/optAndSubmitContentReducer";
import checkContentMergeStatusReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/checkContentMergeStatusReducer";
import publishVideoOnYoutubeReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/publishVideoOnYoutubeReducer";
import resubmissionOptAndSubmitContentUGCReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/resubmissionOptAndSubmitContentUGCReducer";
import uploadToS3Reducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/uploadToS3Reducer";
import getUploadedUGCContentListReducer from "../campaignStore/reducers/createCampaignReducers/getUploadedUGCContentListReducer";
import markApprovedRejectedContentReducer from "../campaignStore/reducers/createCampaignReducers/markApprovedRejectedContentReducer";
import getWalletTxnWithFilterReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/getWalletTxnWithFilterReducer";
import addMoneyToWalletReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/addMoneyToWalletReducer";
import txnStatusReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/txnStatusReducer";
import stripeOnboardingReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/stripeOnboardingReducer";
import checkStripeOnboardingStatusReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/checkStripeOnboardingStatusReducer";
import requestPayoutReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/requestPayoutReducer";
import getCampaignDetailsForDashboardReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/getCampaignDetailsForDashboardReducer";
import getPotentialIncomeMissedByMonthAndYearForDashboardReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/getPotentialIncomeMissedByMonthAndYearForDashboardReducer";
import getTotalIncomeByMonthAndYearForDashboardReducer from "../influencerCampaignStore/reducers/InfluencerCampaignReducers/getTotalIncomeByMonthAndYearForDashboardReducer";
import getDashboardDataReducer from "../brandDashboardStore/reducers/getDashboardDataReducer";
import getCampaignListWithFilterDashBoardReducer from "../brandDashboardStore/reducers/getCampaignListWithFilterDashBoardReducer";

const persistConfig = {
	key: 'root',
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: [
		// add reducers to persist data
		'utilReducers',
		'getUserInfoReducer',
	],
};

const appReducer = combineReducers({
	utilReducers: utilReducers,

	// public rducers
	getAllCountryReducer: getAllCountryReducer,

	// auth reducers
	loginReducer: loginReducer,
	googleUserLoginReducer: googleUserLoginReducer,
	getUserInfoReducer: getUserInfoReducer,
	createUserReducer: createUserReducer,
	verifyOtpAndCreateUserReducer: verifyOtpAndCreateUserReducer,
	sendOtpForForgetPasswordReducer: sendOtpForForgetPasswordReducer,
	verifyOtpForForgetPasswordReducer: verifyOtpForForgetPasswordReducer,
	forgetPasswordResetReducer: forgetPasswordResetReducer,
	sendOtpToCreateUserReducer: sendOtpToCreateUserReducer,
	userLoginReducer: userLoginReducer,
	verifyOtpForLoginReducer: verifyOtpForLoginReducer,
	logoutReducer: logoutReducer,

	getAllRegionReducer: getAllRegionReducer,
	getCountryByRegionReducer: getCountryByRegionReducer,
	getStateByCountryReducer: getStateByCountryReducer,
	getAllAgeGroupReducer: getAllAgeGroupReducer,
	getAllNicheReducer: getAllNicheReducer,
	getBudgetReducer: getBudgetReducer,
	getMaxViewsReducer: getMaxViewsReducer,
	getBrandCampaignReducer: getBrandCampaignReducer,
	getAllBannerTemplateReducer: getAllBannerTemplateReducer,
	uploadCampaignFileReducer: uploadCampaignFileReducer,
	createCampaignReducer: createCampaignReducer,
	getMyBrandBusinessDetailReducer: getMyBrandBusinessDetailReducer,
	searchBusinessPlaceReducer: searchBusinessPlaceReducer,
	registerBusinessReducer: registerBusinessReducer,
	myChannelListReducer: myChannelListReducer,
	storeYoutubeAuthReducer: storeYoutubeAuthReducer,
	getYoutubeChannelListReducer: getYoutubeChannelListReducer,
	storeValidateChannelReducer: storeValidateChannelReducer,
	getCampaignListFilterReducer: getCampaignListFilterReducer,
	streamVideoReducer: streamVideoReducer,
	uploadInfluencerVideoReducer: uploadInfluencerVideoReducer,
	optAndSubmitContentReducer: optAndSubmitContentReducer,
	checkContentMergeStatusReducer: checkContentMergeStatusReducer,
	publishVideoOnYoutubeReducer: publishVideoOnYoutubeReducer,
	resubmissionOptAndSubmitContentUGCReducer: resubmissionOptAndSubmitContentUGCReducer,
	uploadToS3Reducer: uploadToS3Reducer,
	getUploadedUGCContentListReducer: getUploadedUGCContentListReducer,
	markApprovedRejectedContentReducer: markApprovedRejectedContentReducer,
	getWalletTxnWithFilterReducer: getWalletTxnWithFilterReducer,
	addMoneyToWalletReducer: addMoneyToWalletReducer,
	txnStatusReducer: txnStatusReducer,
	stripeOnboardingReducer: stripeOnboardingReducer,
	checkStripeOnboardingStatusReducer: checkStripeOnboardingStatusReducer,
	requestPayoutReducer: requestPayoutReducer,
	getCampaignDetailsForDashboardReducer: getCampaignDetailsForDashboardReducer,
	getPotentialIncomeMissedByMonthAndYearForDashboardReducer: getPotentialIncomeMissedByMonthAndYearForDashboardReducer,
	getTotalIncomeByMonthAndYearForDashboardReducer: getTotalIncomeByMonthAndYearForDashboardReducer,
	getDashboardDataReducer: getDashboardDataReducer,
	getCampaignListWithFilterDashBoardReducer: getCampaignListWithFilterDashBoardReducer,

})

const rootReducers = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		console.log("logggginggg ouutttt")
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducers);
