import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs';
import { BiFilterAlt } from 'react-icons/bi';
import { FaCircleCheck } from 'react-icons/fa6';
import { MdCancel } from 'react-icons/md';
import Popup from '../../popups/Popup/Popup';
import { useDispatch, useSelector } from 'react-redux';
import { getUploadedUGCContentList, getUploadedUGCContentListReset, getUploadedUGCContentListResetAll } from '../../../store/campaignStore/actions/CreateCampaignActions/getUploadedUGCContentListActions';
import { getBrandCampaign } from '../../../store/campaignStore/actions/CreateCampaignActions/getBrandCampaignActions';
import moment from 'moment/moment';
import { streamVideo } from '../../../store/influencerCampaignStore/actions/InfluencerCampaignActions/streamVideoActions';
import { markApprovedRejectedContent, markApprovedRejectedContentReset } from '../../../store/campaignStore/actions/CreateCampaignActions/markApprovedRejectedContentActions';
import Footer from '../../common/Footer/Footer';

const BrandCampaignList = () => {
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupType, setPopupType] = useState(null);
    const [campaignData, setCampaignData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCampaign, setSelectedCampaign] = useState();
    const [contentId, setContentId] = useState();
    const [selectedcontent, setselectedContent] = useState();
    const [selectedstatus, setselectedstatus] = useState();
    const [rejectionReason, setRejectionReason] = useState("");
    const [getUploadedUGCContentListData, setgetUploadedUGCContentListData] = useState([]);

    const getBrandCampaignReducerData = useSelector(state => state.getBrandCampaignReducer.data);
    const getBrandCampaignReducerStatus = useSelector(state => state.getBrandCampaignReducer.status);
    const getUploadedUGCContentListReducerData = useSelector(
        (state) => state.getUploadedUGCContentListReducer?.data
    );
    const getUploadedUGCContentListReducerStatus = useSelector(
        (state) => state.getUploadedUGCContentListReducer?.status
    );

    const markApprovedRejectedContentReducerData = useSelector(
        (state) => state.markApprovedRejectedContentReducer?.data
    );

    const markApprovedRejectedContentReducerStatus = useSelector(
        (state) => state.markApprovedRejectedContentReducer?.status
    );

    console.log("Reducer Data:", markApprovedRejectedContentReducerData);
    console.log("Reducer Status:", markApprovedRejectedContentReducerStatus);


    useEffect(() => {
        dispatch(getUploadedUGCContentList({
            campaignId: selectedCampaign
        }));
    }, [selectedCampaign]);

    useEffect(() => {
        if (getUploadedUGCContentListReducerStatus == 200) {
            setCampaignData(getUploadedUGCContentListReducerData);
            dispatch(getUploadedUGCContentListReset());
        } else if (getUploadedUGCContentListReducerStatus == 302) {
            setCampaignData(getUploadedUGCContentListReducerData);
            dispatch(getUploadedUGCContentListReset());
        } else if (getUploadedUGCContentListReducerStatus) {
            dispatch(getUploadedUGCContentListReset())
        }
    }, [getUploadedUGCContentListReducerStatus]);


    useEffect(() => {
        dispatch(getBrandCampaign());
    }, []);


    const breadcrumbData = [
        { label: 'Home', href: '/' },
        { label: 'My Campaigns', href: '/brand/campaigns' },
        { label: 'Campaign List', href: '#' },
    ];

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
    ];

    const handleApprove = (contentId, status) => {
        setselectedContent(contentId);
        // setselectedstatus(status);
        setPopupType('approve');
        setPopupOpen(true);
    };

    const handleReject = (contentId, status,) => {
        setselectedContent(contentId);
        // setselectedstatus(status);
        setPopupType('reject');
        setPopupOpen(true);
    };

    const handleRejectionReasonChange = (event, ugcRejectReason) => {
        setRejectionReason(event.target.value);
    };

    console.log("campaignData :", campaignData);


    return (
        <div className="w-full h-full flex flex-col overflow-auto bg-[#F2F3F7]">
            <Breadcrumbs breadcrumbs={breadcrumbData} />

            <h1 className="text-[1.8rem] text-[#534D4D] inter-font font-[600] px-[4rem] py-[2.7rem]">
                Campaign List
            </h1>

            <div className="px-[4rem] mb-[3rem] flex justify-between items-center">
                <select
                    className="w-[40%] px-[1.5rem] py-[1rem] text-[#677489] text-[1.6rem] inter-font font-[400] border border-[#E1E1E1] rounded-[0.5rem] bg-white shadow-sm"
                    onChange={(e) => {
                        console.log("e.target", e.target.value);
                        setSelectedCampaign(e.target.value)
                    }}
                >
                    <option value="" selected disabled>Select Campaign Option</option>
                    {getBrandCampaignReducerData.length > 0 ?
                        getBrandCampaignReducerData?.filter((item) => { return item.campaignType == "UGC" && item.campaignStatus == "APPROVED" }).map((items) => {
                            return (
                                <option value={items.campaignId}>{items.name}</option>
                            )
                        })
                        :
                        <div className="text-[1.4rem] text-center">
                            No campaign available
                        </div>
                    }
                </select>
                {/* <button className="px-[1rem] py-[0.5rem] bg-white border border-[#E1E1E1] text-[2.4rem] text-[#272829] rounded-[0.5rem] shadow-sm">
                    <BiFilterAlt />
                </button> */}
            </div>

            {/* Content List Table */}
            <div className="px-[4rem]">
                <div className="bg-white rounded-[1.2rem] shadow-xl p-4 sm:p-8 mt-6 overflow-hidden">
                    <div className="w-full grid grid-cols-2 mb-6 gap-4">
                        <h2 className="text-[2.4rem] inter-font font-[700]">Content List</h2>
                        {/* <div className="w-full flex justify-end">
                            <select className="w-[60%] sm:w-[35%] lg:w-[25%] border border-[#D5D5D5] text-[#2B303466] text-[1.4rem] inter-font font-[500] rounded-lg px-[1.5rem] py-[1rem]">
                                {months.map((month, index) => (
                                    <option key={index} value={month}>
                                        {month}
                                    </option>
                                ))}
                            </select>
                        </div> */}
                    </div>
                    {/* Table Header */}
                    <div className="grid grid-cols-6 text-[1.4rem] inter-font font-[700] bg-[#F1F4F9] text-[#202224] py-[1.5rem] px-[2rem]">
                        <div>Influencer</div>
                        <div>Upload Date</div>
                        <div>Channel Name</div>
                        <div>Status</div>
                        <div>Content</div>
                        <div>Action</div>
                    </div>

                    {/* Table Rows */}
                    {campaignData?.length > 0 ? (
                        campaignData?.map((item, index) => (
                            <div
                                key={index}
                                className="grid grid-cols-5 text-[1.4rem] text-[#202224] inter-font font-[600] py-[1.5rem] px-[2rem] border-t border-[#fff]"
                            >
                                <div>{item.influencerName || 'N/A'}</div>
                                <div>{moment(item.uploadedAt).format("DD-MM-YYYY hh:mm") || 'N/A'}</div>
                                <div>{item.channelName || 'N/A'}</div>
                                <div>{item.status || 'N/A'}</div>
                                <div>
                                    <button
                                        className="bg-[#FF0505CC] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                                        onClick={() => {
                                            dispatch(streamVideo({ s3ContentPath: item.contentPath },
                                                (status, response, message) => {
                                                    if (status === 200) {
                                                        window.open(response, "_blank", 'rel=noopener noreferrer')
                                                    }
                                                }))
                                        }}
                                    >
                                        View
                                    </button>
                                </div>
                                <div className="flex space-x-[1.5rem]">
                                    <button
                                        className="bg-[#0D9B50] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                                        onClick={() => handleApprove(item.contentId)}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        className="bg-[#FF0303] text-white text-center rounded-[1rem] py-[0.5rem] px-[2.5rem]"
                                        onClick={() => handleReject(item.contentId)}
                                    >
                                        Reject
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center text-[1.4rem] text-[#8A98AC] py-[2rem]">No campaigns found for the search query.</div>
                    )}
                </div>
            </div>

            {/* Popup */}
            {popupType === 'approve' && (
                <Popup
                    isOpen={isPopupOpen}
                    onClose={() => setPopupOpen(false)}
                    title="Approve"
                    canDismiss
                    icon={<FaCircleCheck color="#0D9B50" size={110} className="my-[5rem]" />}
                    content={
                        <p className="text-[1.6rem] w-full mx-auto text-center">
                            Are you sure you want to approve this content?
                        </p>
                    }
                    size="sm"
                    primaryButton={{
                        text: 'Yes',
                        onClick: () => {
                            dispatch(markApprovedRejectedContent({ "contentId": selectedcontent, "status": "UGC_APPROVED" }, (status, response, message) => {
                                if (status == 200) {
                                    dispatch(getUploadedUGCContentList({
                                        "campaignId": contentId
                                    }, (status, response, message) => {
                                        if (status === 200) {
                                            setgetUploadedUGCContentListData(response);
                                            dispatch(getUploadedUGCContentListReset());
                                        } else if (status) {
                                            dispatch(getUploadedUGCContentListReset());
                                        }
                                    }))
                                    setPopupOpen(false);
                                } else if (status === 305) {
                                    setPopupOpen(false);
                                    // setPopupForTokenExpireOpen(true)
                                } else if (status) {
                                    setPopupOpen(false);
                                }
                            }));

                        },
                    }}
                    secondaryButton={{
                        text: 'No',
                        onClick: () => setPopupOpen(false),
                    }}
                />
            )}

            {popupType === 'reject' && (
                <Popup
                    isOpen={isPopupOpen}
                    onClose={() => setPopupOpen(false)}
                    title="Reject"
                    canDismiss
                    icon={<MdCancel color="#FF0505" size={110} className="mt-[5rem]" />}
                    content={
                        <>
                            <p className="text-[1.6rem] w-full mx-auto text-center my-[2rem]">
                                Please provide a reason for rejection.
                            </p>
                            <form className="space-y-4">
                                <div>
                                    <input
                                        id="description"
                                        value={rejectionReason}
                                        onChange={handleRejectionReasonChange}
                                        placeholder="Please enter a reason"
                                        className="w-full bg-[#FFFFFF] h-20 border text-[1.6rem] border-gray-300 rounded-2xl px-4 py-2 focus:outline-none"
                                    />
                                </div>
                            </form>
                        </>
                    }
                    size="sm"
                    primaryButton={{
                        text: 'Reject',
                        onClick: () => {
                            dispatch(markApprovedRejectedContent({ "contentId": selectedcontent, "status": "UGC_REJECTED", "ugcRejectReason": rejectionReason }, (status, response, message) => {
                                if (status == 200) {
                                    dispatch(getUploadedUGCContentList({
                                        "campaignId": contentId
                                    }, (status, response, message) => {
                                        if (status === 200) {
                                            setgetUploadedUGCContentListData(response);
                                            dispatch(getUploadedUGCContentListReset());
                                        } else if (status) {
                                            dispatch(getUploadedUGCContentListReset());
                                        }
                                    }))
                                    setPopupOpen(false);
                                } else if (status === 305) {
                                    setPopupOpen(false);
                                    // setPopupForTokenExpireOpen(true)
                                } else if (status) {
                                    setPopupOpen(false);
                                }
                            }));

                        },
                    }}
                    secondaryButton={{
                        text: 'Cancel',
                        onClick: () => setPopupOpen(false),
                    }}
                />
            )}

            <Footer />
        </div>
    );
};

export default BrandCampaignList;
