import React, { useEffect, useState } from 'react';
import Landing from '../../components/main/Landing/Landing';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router';
import { SiLibreofficewriter } from 'react-icons/si';
import Popup from '../../components/popups/Popup/Popup';
import { myChannelList } from '../../store/BusinessStore/actions/InfluencerChannelActions/myChannelListActions';
import {getUserInfo} from "../../store/authStore/actions/authActions/getUserInfoActions";

function Dashboard() {
        
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isPopupForTokenExpireOpen, setPopupForTokenExpireOpen] = useState(false);
    const getUserInfoReducer = useSelector(state => state.getUserInfoReducer);
    const userInfo = getUserInfoReducer?.data;
    useEffect(() => {
        dispatch(getUserInfo());
        dispatch(myChannelList(( status, response, message ) => {
            if (status === 302){
                setPopupOpen(true)
            }else if(userInfo?.isTokenExpire) {
                setPopupForTokenExpireOpen(true)
            }
        }))
    }, []);
    
    return (
        <div className='w-full h-full'>
            <Landing />
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setPopupOpen(false)}
                title="Channel Registration"
                canDismiss
                icon={<SiLibreofficewriter color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>No YouTube channels found under this account</p>}
                size="sm"
                primaryButton={{
                    text: 'Add Channel',
                    onClick: () => {
                        navigate("/influencer-channel")
                        setPopupOpen(false);
                    },
                }}
                secondaryButton={{
                    text: 'Not Now',
                    onClick: () => setPopupOpen(false),
                }}
            />
            <Popup
                isOpen={isPopupForTokenExpireOpen}
                onClose={() => setPopupForTokenExpireOpen(false)}
                title="Youtube Auth token"
                canDismiss
                icon={<SiLibreofficewriter color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>Your Channel authentication is expired or revoked</p>}
                size="sm"
                primaryButton={{
                    text: 'Re-auth',
                    onClick: () => {
                        window.open('/influencer-channel-auth', '_blank');
                        setPopupForTokenExpireOpen(false);
                    },
                }}
                secondaryButton={{
                    text: 'Not Now',
                    onClick: () => setPopupForTokenExpireOpen(false),
                }}
            />
        </div>

    );
}

export default Dashboard;
