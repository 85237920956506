import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../components/common/Breadcrumbs/Breadcrumbs';
import YouTubeOAuth from '../../components/main/Landing/YouTubeOAuth';
import { useDispatch } from 'react-redux';
import { storeYoutubeAuth } from '../../store/BusinessStore/actions/InfluencerChannelActions/storeYoutubeAuthActions';
import { youtube_redirect_uri } from '../../store/constants';
import { getYoutubeChannelList } from '../../store/BusinessStore/actions/InfluencerChannelActions/getYoutubeChannelListActions';
import {useToast} from "../../hooks/useToast";
import {
    storeValidateChannel
} from "../../store/BusinessStore/actions/InfluencerChannelActions/storeValidateChannelActions";
import {FaCircleCheck} from "react-icons/fa6";
import Popup from "../../components/popups/Popup/Popup";
import {useNavigate} from "react-router";

function InfluencerChannelDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [platform, setPlatform] = useState("");
    const [selectedChannel, setSelectedChannel] = useState(null); // Default to null
    const [errors, setErrors] = useState({});
    const [channelList, setChannelList] = useState([
    ]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // For modal
    const [isSuccessPopupOpen, setSuccessPopupOpen] = useState(false);
    const [storeChannelMsg, setStoreChannelMsg] = useState('');

    const breadcrumbData = [
        { label: 'Home', href: '/' },
        { label: 'My Campaigns', href: '/brand/campaigns/create' },
    ];

    const Toast = useToast();
    let isAuthCalledAndSuccess = false;
    console.log("channelList : ", channelList)
    const handleStoreYoutubeAuth = (authCode) => {
        if(!isAuthCalledAndSuccess){
        dispatch(storeYoutubeAuth({
            "googleCode": authCode,
            "redirectUri": youtube_redirect_uri
        },(status, response, message) => {
            if (status === 200) {
                isAuthCalledAndSuccess = true;
                // Fetch channel list on successful authentication
                dispatch(getYoutubeChannelList((status, response, message) => {
                    console.log("for channel list status, response, message", status, response, message)
                    if (status === 200) {
                            setChannelList(response || []); // Update the channel list state
                            // setSelectedChannel(response.length > 0 ? response[0] : null); // Set the first channel if available
                    } else {
                        Toast(message, 'error');
                    }
                }));
            } else {
                Toast(message, 'error');
            }
        }));
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        console.log("youtube code", code)
        if (code) {
            setTimeout(() => {
                handleStoreYoutubeAuth(code);
            }, 1000);
        }
    }, []); // Run once on component mount

    // Handle channel click to show only the selected channel
    const handleChannelSelect = (channel) => {
        setSelectedChannel(channel);
        setChannelList([channel]); // Retain only the selected channel in the list
    };

    // Handle Cancel button to reset to the original channel list
    const handleCancel = () => {
        setSelectedChannel(null);
    };

    const handleSubmit = () => {
        dispatch(storeValidateChannel({
            "channelId": selectedChannel?.channelId
        }, (status, response, message) => {
            console.log("for store channel status, response, message", status, response, message)
            setStoreChannelMsg(message)
            if (status === 200) {
                setIsConfirmationOpen(false); // Close modal on success
                setSuccessPopupOpen(true)

            } else {
                Toast(message, 'error');
            }
        }));
    };

    return (
        <div className='w-full flex flex-col overflow-auto bg-[#F2F3F7]'>
            <Breadcrumbs breadcrumbs={breadcrumbData} />
            <div className='p-[4rem] min-h-[70rem] relative'>
                <h2 className="text-[#534D4D] text-[1.8rem] font-[600]">Select the Platform</h2>
                <div className="flex justify-between items-end mt-[1.5rem]">
                    <div className="w-[49%]">
                        <label className="text-[1.6rem] inter-font font-[400] text-[#111729]">
                            Platform
                            <span className="text-red-500">*</span>
                        </label>
                        <select
                            className="w-full px-4 py-4 mt-[.8rem] text-[1.6rem] inter-font font-[400] border border-[#E3E8EF] rounded-lg focus:outline-none"
                            value={platform}
                            onChange={(e) => {
                                setPlatform(e.target.value);
                                setErrors((prevState) => { const { ["platform"]: _, ...updatedObject } = prevState; return updatedObject; });
                            }}
                        >
                            <option value="" disabled>Select Platform</option>
                            <option value="YOUTUBE">YOUTUBE</option>
                            <option value="INSTAGRAM" disabled>INSTAGRAM (coming soon)</option>
                            <option value="TIKTOK" disabled>TikTok (coming soon)</option>
                        </select>
                        {errors.platform && (
                            <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                                {errors.platform}
                            </p>
                        )}
                    </div>
                    {platform ? <YouTubeOAuth redirectUri={youtube_redirect_uri}/> : ''}


                </div>
                {selectedChannel ? (
                    <div className="mt-[2rem]">
                        <h2 className="text-[2rem] font-[600] mb-[1rem]">Selected Channel</h2>
                        <div className="flex items-start justify-between border rounded-lg p-4 border-green-500  bg-white">
                            <div className="flex items-start">
                                <div className="w-[6rem] h-[6rem] rounded-full bg-gray-300 flex-shrink-0">
                                    <img src={selectedChannel.channelImageUri} alt="Channel"/>
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-[1.8rem] font-[600]">{selectedChannel.channelName}</h3>
                                    <p className="text-[1.4rem] text-gray-500">{selectedChannel.totalSubscribers} subscribers
                                        | {selectedChannel.totalVideos} videos</p>
                                    <p className="text-[1.4rem] text-gray-500">{selectedChannel.channelDescription}</p>
                                    <a href={selectedChannel.channelUri} target="_blank" rel="noopener noreferrer"
                                       className="text-blue-500 underline mt-1">
                                        {selectedChannel.channelUri}
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className="flex justify-start space-x-[1.8rem] mt-[2.2rem] z-[10]">
                            <button
                                className="w-[16rem] px-4 py-[1rem] text-[2rem] rounded-[1.2rem] cursor-pointer border border-[#0D9B50] text-[#0D9B50] hover:bg-[#0D9B50]/10"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                className="w-[16rem] px-4 py-[1rem] text-[2rem] rounded-[1.2rem] cursor-pointer bg-[#0D9B50] text-white hover:bg-[#0D9B50]/90"
                                onClick={() => setIsConfirmationOpen(true)}
                            >
                                OK
                            </button>

                        </div>
                    </div>
                ) : (
                    <div className="mt-[2rem] bg-white">
                        <div className="space-y-[1.6rem]">
                            {channelList.map((channel, index) => (
                                <div
                                    key={index}
                                    className="flex items-start justify-between border rounded-lg p-4 border-gray-300 cursor-pointer hover:shadow-lg"
                                    onClick={() => handleChannelSelect(channel)}
                                >
                                    <div className="flex items-start">
                                        <div className="w-[6rem] h-[6rem] rounded-full bg-gray-300 flex-shrink-0">
                                            <img src={channel.channelImageUri} alt="Channel" />
                                        </div>
                                        <div className="ml-4">
                                        <h3 className="text-[1.8rem] font-[600]">{channel.channelName}</h3>
                                            <p className="text-[1.4rem] text-gray-500">{channel.totalSubscribers} subscribers | {channel.totalVideos} videos</p>
                                            <p className="text-[1.4rem] text-gray-500">{channel.channelDescription}</p>
                                            <a href={channel.channelUri} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline mt-1">
                                                {channel.channelUri}
                                            </a>
                            </div>
                        </div>
                    </div>
                            ))}
                        </div>
                    </div>
                )}
                {isConfirmationOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg">
                            <h2 className="text-[2rem] font-[600] mb-4">Confirm Submission</h2>
                            <p className="text-[1.4rem] mb-6">Are you sure you want to submit the selected channel?</p>
                            <div className="flex justify-between space-x-[1.8rem]">
                                <button
                                    className="w-[10rem] px-4 py-[1rem] text-[1.8rem] rounded-[.8rem] border border-gray-500 text-gray-500 hover:bg-gray-100"
                                    onClick={() => setIsConfirmationOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="w-[10rem] px-4 py-[1rem] text-[1.8rem] rounded-[.8rem] bg-[#0D9B50] text-white hover:bg-[#0D9B50]/90"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Popup
                isOpen={isSuccessPopupOpen}
                onClose={() => setSuccessPopupOpen(false)}
                title="Success"
                canDismiss={false}
                icon={<FaCircleCheck color="#0D9B50" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>{storeChannelMsg}</p>}
                size="sm"
                primaryButton={{
                    text: 'Done',
                    onClick: () => {
                        navigate('/influencer')
                        setSuccessPopupOpen(false);
                        setStoreChannelMsg('')
                    },
                }}
            />
        </div>
    );
}

export default InfluencerChannelDashboard;
