import { actionTypes } from '../../../types';

export const optAndSubmitContent = (payload, callback) => ({
    type: actionTypes.OPT_AND_SUBMIT_CONTENT_REQUEST,
    payload,
    callback
});

export const optAndSubmitContentSuccess = (payload) => ({
    type: actionTypes.OPT_AND_SUBMIT_CONTENT_SUCCESS,
    payload,
});

export const optAndSubmitContentFailure = (payload) => ({
    type: actionTypes.OPT_AND_SUBMIT_CONTENT_FAILURE,
    payload,
});

export const optAndSubmitContentReset = () => ({
    type: actionTypes.OPT_AND_SUBMIT_CONTENT_RESET,
});

export const optAndSubmitContentResetAll = () => ({
    type: actionTypes.OPT_AND_SUBMIT_CONTENT_RESET_ALL,
});
