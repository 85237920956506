import React, { useState } from 'react';
import Banner from '../MyCampaigns/Banner';
import VideoCampaign from '../MyCampaigns/VideoCampaign';
import Ugc from '../MyCampaigns/Ugc';
import { useDispatch, useSelector } from 'react-redux';
import BottomNavigation from './BrandCampaignLayout/BottomNavigation';
import {
    setBrandCampaignCreationData,
    setBrandCampaignCreationStep
} from "../../../store/publicStore/actions/utilsActions/utilsActions";


const CampaignForm = () => {
    const dispatch = useDispatch();

    const { brandCampaignCreationStep, brandCampaignCreationData } = useSelector((state) => state.utilReducers);
    const getAllBannerTemplateReducerData = useSelector(state => state.getAllBannerTemplateReducer.data) || [];

    const [startDate, setStartDate] = useState(brandCampaignCreationData?.contentDetails?.startDate ? brandCampaignCreationData?.contentDetails?.startDate : '');
    const [endDate, setEndDate] = useState(brandCampaignCreationData?.contentDetails?.endDate ? brandCampaignCreationData?.contentDetails?.endDate : '');
    const [selectedBanner, setSelectedBanner] = useState(Object.keys(brandCampaignCreationData?.contentDetails?.bannerTemplate)?.length !== 0 ? brandCampaignCreationData?.contentDetails?.bannerTemplate : getAllBannerTemplateReducerData?.filter(item => (item.isActive))?.length > 0 ? getAllBannerTemplateReducerData?.filter(item => (item.isActive))[0] : {});
    const [videoPosition, setVideoPosition] = useState(brandCampaignCreationData?.contentDetails?.videoPosition ? brandCampaignCreationData?.contentDetails?.videoPosition : '');
    const [script, setScript] = useState(brandCampaignCreationData?.contentDetails?.script ? brandCampaignCreationData?.contentDetails?.script : "");
    const [dialogue, setDialogue] = useState(brandCampaignCreationData?.contentDetails?.dialogue ? brandCampaignCreationData?.contentDetails?.dialogue : "");
    const [backgroundColor, setBackgroundColor] = useState(brandCampaignCreationData?.contentDetails?.backgroundColor ? brandCampaignCreationData?.contentDetails?.backgroundColor : '#000000');
    const [mediaFileLink, setMediaFileLink] = useState(brandCampaignCreationData?.contentDetails?.mediaFileLink ? brandCampaignCreationData?.contentDetails?.mediaFileLink : '');
    const [mediaFileName, setMediaFileName] = useState(brandCampaignCreationData?.contentDetails?.mediaFileName ? brandCampaignCreationData?.contentDetails?.mediaFileName : '');
    const [otherProperty, setOtherProperty] = useState(brandCampaignCreationData?.contentDetails?.otherProperties ? brandCampaignCreationData?.contentDetails?.otherProperties : '');
    const [isLocationRequired, setIsLocationRequired] = useState(brandCampaignCreationData?.contentDetails?.isLocationRequired);
    const [isBrandApprovalRequired, setIsBrandApprovalRequired] = useState(brandCampaignCreationData?.contentDetails?.isBrandApprovalRequired);
    const [selectedDateTime, setSelectedDateTime] = useState(brandCampaignCreationData?.contentDetails?.uploadDuration ? brandCampaignCreationData?.contentDetails?.uploadDuration : '');
    const [selectedGenre, setSelectedGenre] = useState(brandCampaignCreationData?.contentDetails?.selectedGenre ? brandCampaignCreationData?.contentDetails?.selectedGenre : '');
    const [errors, setErrors] = useState({});

    // Validate the form and return errors
    const validateBannerForm = (data) => {
        let formErrors = {};

        if (brandCampaignCreationData?.basicDetails?.campaignType === "Banner") {
            if (Object.keys(data?.selectedBanner).length == 0) {
                formErrors.selectedBanner = "Please Select Banner";
            }
        }
        if (brandCampaignCreationData?.basicDetails?.campaignType === "Video") {
            if (!data?.videoPosition?.trim()) {
                formErrors.videoPosition = "Video Position required";
            }
            if (!data?.mediaFileLink?.trim()) {
                formErrors.mediaFileLink = "Please Upload Midea";
            }
        }
        if (brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content") {
            if (!data?.script?.trim()) {
                formErrors.script = "Script is required";
            }
            if (!data?.dialogue?.trim()) {
                formErrors.dialogue = "Dialogue is required";
            }
            if (!data?.mediaFileLink?.trim()) {
                formErrors.mediaFileLink = "Please Upload Midea";
            }
            if (!data?.otherProperty?.trim()) {
                formErrors.otherProperty = "Other Property is required";
            }
            if (!data?.selectedDateTime?.trim()) {
                formErrors.selectedDateTime = "Duration required";
            }
            // if (!data?.selectedGenre?.trim()) {
            //     formErrors.selectedGenre = "Genre required";
            // }
        }
        if (!data.startDate?.trim()) {
            formErrors.startDate = "Start Date is required";
        }
        if (!data.endDate?.trim()) {
            formErrors.endDate = "End date is required";
        }

        return formErrors;
    };

    console.log("================================ mediafilename",mediaFileName)
    console.log("================================ reducer",brandCampaignCreationData?.contentDetails)

    const handleNext = () => {

        let formErrors;
        // Validate form data
        if(brandCampaignCreationData?.basicDetails?.campaignType === "Banner"){
            formErrors = validateBannerForm({
                selectedBanner,
                startDate,
                endDate,
            });
        }else if(brandCampaignCreationData?.basicDetails?.campaignType === "Video"){
            formErrors = validateBannerForm({
                mediaFileLink,
                videoPosition,
                startDate,
                endDate,
            });
        }else if(brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content"){
            formErrors = validateBannerForm({
                script,
                dialogue,
                mediaFileLink,
                otherProperty,
                selectedDateTime,
                startDate,
                endDate,
            });
        }

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors); // Show errors if validation fails
        } else {
            // Clear errors and save data to Redux store
            setErrors({});
            console.log("going to set data in reducer",mediaFileName)

            // Dispatch the campaign data to Redux
            dispatch(
                setBrandCampaignCreationData({
                    basicDetails: {
                        campaignName: brandCampaignCreationData?.basicDetails?.campaignName,
                        campaignPurpose: brandCampaignCreationData?.basicDetails?.campaignPurpose,
                        platform: brandCampaignCreationData?.basicDetails?.platform,
                        campaignType: brandCampaignCreationData?.basicDetails?.campaignType,
                        referralCode: brandCampaignCreationData?.basicDetails?.referralCode,
                    },
                    contentDetails: {
                        startDate: startDate,
                        endDate: endDate,
                        bannerTemplate: brandCampaignCreationData?.basicDetails?.campaignType === "Banner" ? selectedBanner : {},
                        videoPosition: brandCampaignCreationData?.basicDetails?.campaignType === "Video" ? videoPosition : null,
                        mediaFileLink: brandCampaignCreationData?.basicDetails?.campaignType !== "Banner" ? mediaFileLink : null,
                        mediaFileName: brandCampaignCreationData?.basicDetails?.campaignType !== "Banner" ? mediaFileName : null,
                        script: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? script : null,
                        dialogue: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? dialogue : null,
                        backgroundColor: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? backgroundColor : null,
                        otherProperties: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? otherProperty : null,
                        isLocationRequired: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? isLocationRequired : null,
                        isBrandApprovalRequired: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? isBrandApprovalRequired : null,
                        uploadDuration: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? selectedDateTime : null,
                        selectedGenre: brandCampaignCreationData?.basicDetails?.campaignType === "User Generated Content" ? selectedGenre : null,
                    },
                    audienceDemographicDetails: {
                        ageGroup: brandCampaignCreationData?.audienceDemographicDetails?.ageGroup,
                        country: brandCampaignCreationData?.audienceDemographicDetails?.country,
                        niche: brandCampaignCreationData?.audienceDemographicDetails?.niche,
                        region: brandCampaignCreationData?.audienceDemographicDetails?.region,
                        gender: brandCampaignCreationData?.audienceDemographicDetails?.gender,
                        state: brandCampaignCreationData?.audienceDemographicDetails?.state,
                    }
                })
            );

            // Proceed to the next step
            dispatch(
                setBrandCampaignCreationStep(brandCampaignCreationStep + 1)
            );
        }
    };

    return (
        <div className="w-full h-[80%] bg-[#FFFFFF66] shadow-xl shadow-[#E3E8EF] rounded-[0.6rem] p-[3.2rem]">
            <div className="w-full h-full !overflow-y-scroll no-scrollbar">
                {
                    brandCampaignCreationData?.basicDetails?.campaignType === "Banner" ? <Banner
                        selectedBanner={selectedBanner}
                        setSelectedBanner={setSelectedBanner}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        errors={errors}
                        setErrors={setErrors}
                    />
                    : brandCampaignCreationData?.basicDetails?.campaignType === "Video" ? <VideoCampaign
                        mediaFileLink={mediaFileLink}
                        mediaFileName={mediaFileName}
                        setMediaFileLink={setMediaFileLink}
                        setMediaFileName={setMediaFileName}
                        videoPosition={videoPosition}
                        setVideoPosition={setVideoPosition}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        errors={errors}
                        setErrors={setErrors}
                    />
                    :
                    <Ugc
                        script={script}
                        setScript={setScript}
                        dialogue={dialogue}
                        setDialogue={setDialogue}
                        backgroundColor={backgroundColor}
                        setBackgroundColor={setBackgroundColor}
                        mediaFileLink={mediaFileLink}
                        mediaFileName={mediaFileName}
                        setMediaFileLink={setMediaFileLink}
                        setMediaFileName={setMediaFileName}
                        otherProperty={otherProperty}
                        setOtherProperty={setOtherProperty}
                        isLocationRequired={isLocationRequired}
                        setIsLocationRequired={setIsLocationRequired}
                        isBrandApprovalRequired={isBrandApprovalRequired}
                        setIsBrandApprovalRequired={setIsBrandApprovalRequired}
                        selectedGenre={selectedGenre}
                        setSelectedGenre={setSelectedGenre}
                        selectedDateTime={selectedDateTime}
                        setSelectedDateTime={setSelectedDateTime}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        errors={errors}
                        setErrors={setErrors}
                    />
                }
                <BottomNavigation isNextDisabled={Object.keys(errors).length > 0} handleNext={handleNext} />
            </div>
        </div>
    );
};

export default CampaignForm;
