import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const NavigationButtons = () => {

    const { brandCampaignCreationStep } = useSelector(state => state.utilReducers);

    const sections = [
        "Basic Details",
        "Campaign Content Details",
        "Audience & Demographic Details",
        "Campaign Targets and Budget"
    ];

    return (
        <div className="flex flex-col justify-start items-center md:flex-row h-[12rem]">
            {sections.map((item, index) => (
                <React.Fragment key={index}>
                    <div
                        className={`text-[1.4rem] inter-font font-[500] text-center py-4 px-16 rounded-[1rem] 
                            ${index+1 <= brandCampaignCreationStep ? 'text-[#0D9B50] bg-[#DBEAE6]' : 'bg-[#E3E5E9] text-[#38414A]'} 
                            cursor-default`
                        }
                        // onClick={() => dispatch(setBrandCampaignCreationStep(index+1))}
                        // disabled={index+1 > brandCampaignCreationStep}
                    >
                        {item}
                    </div>
                    {index < sections.length - 1 && (
                        <div className="border border-[#C5C6C8] w-[4rem] h-[0.1rem]"></div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default NavigationButtons;
