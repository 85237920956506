import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../../constants';
import { AxiosService } from '../../../services';
import { actionTypes } from '../../../types';
import { checkContentMergeStatusFailure, checkContentMergeStatusSuccess } from '../../actions/InfluencerCampaignActions/checkContentMergeStatusActions';

function* checkContentMergeStatus({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url,
            endPoint: endpoints.checkContentMergeStatus + `?contentId=${payload.contentId}`,
            isAuth: true,
        });
        console.log('====================================');
        console.log("CHECK_CONTENT_MERGE_STATUS_SUCCESS status :", response.data.status);
        console.log("CHECK_CONTENT_MERGE_STATUS_SUCCESS data :", response.data.response);
        console.log("CHECK_CONTENT_MERGE_STATUS_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            checkContentMergeStatusSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        if (callback) {
            callback(
                response?.data?.status,
                response?.data?.response,
                response?.data?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("CHECK_CONTENT_MERGE_STATUS_FAILURE status :", error?.response?.status);
        console.log("CHECK_CONTENT_MERGE_STATUS_FAILURE data:", error?.response?.data);
        console.log("CHECK_CONTENT_MERGE_STATUS_FAILURE error :", error);
        console.log('====================================');
        yield put(checkContentMergeStatusFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* checkContentMergeStatusSaga() {
    yield takeLatest(actionTypes.CHECK_CONTENT_MERGE_STATUS_REQUEST, checkContentMergeStatus);
}

export default checkContentMergeStatusSaga;
