import { actionTypes } from '../../../types';

// Action creator for generating OTP request
export const getStateByCountry = (payload) => ({
    type: actionTypes.GET_STATE_BY_COUNTRY_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getStateByCountrySuccess = (payload) => ({
    type: actionTypes.GET_STATE_BY_COUNTRY_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getStateByCountryFailure = (payload) => ({
    type: actionTypes.GET_STATE_BY_COUNTRY_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getStateByCountryReset = () => ({
    type: actionTypes.GET_STATE_BY_COUNTRY_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getStateByCountryResetAll = () => ({
    type: actionTypes.GET_STATE_BY_COUNTRY_RESET_ALL,
}); 