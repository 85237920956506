import {actionTypes} from '../../../types';

// Action creator for generating OTP request
export const verifyOtpForLogin = (payload) => ({
    type: actionTypes.VERIFY_OTP_FOR_LOGIN_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const verifyOtpForLoginSuccess = (payload) => ({
    type: actionTypes.VERIFY_OTP_FOR_LOGIN_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const verifyOtpForLoginFailure = (payload) => ({
    type: actionTypes.VERIFY_OTP_FOR_LOGIN_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const verifyOtpForLoginReset = () => ({
    type: actionTypes.VERIFY_OTP_FOR_LOGIN_RESET,
});

// Action creator for resetting all OTP generation reducer
export const verifyOtpForLoginResetAll = () => ({
    type: actionTypes.VERIFY_OTP_FOR_LOGIN_RESET_ALL,
});
