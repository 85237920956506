import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiFilter, FiMoreVertical, FiSearch, FiPlus } from 'react-icons/fi';
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";
import { getBrandCampaign } from "../../../store/campaignStore/actions/CreateCampaignActions/getBrandCampaignActions";
import DataTable from "react-data-table-component";
import { SidebarImg } from "../../../static/constant/imgConstants";
import { setBrandCampaignCreationData, setBrandCampaignCreationStep } from '../../../store/publicStore/actions/utilsActions/utilsActions';
import Footer from '../../common/Footer/Footer';

const MyCampaigns = () => {
    const getBrandCampaignReducerData = useSelector(state => state.getBrandCampaignReducer.data);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredCampaigns, setFilteredCampaigns] = useState(getBrandCampaignReducerData);

    const columns = [
        {
            name: 'Campaign',
            selector: row => row.name,
            sortable: true,
            style: {
                width: '10rem',  // Set a smaller width for this column
            },
        },
        {
            name: 'Purpose',
            selector: row => row.purpose,
            sortable: true,
            style: {
                width: '10rem',  // Adjust the width as needed
            },
        },
        {
            name: 'Platform',
            selector: row => row.platform,
            style: {
                width: '10rem',  // Set a smaller width for this column
            },
        },
        {
            name: 'Campaign Type',
            selector: row => row.campaignType,
            style: {
                width: '10rem',  // Adjust the width for consistency
            },
        },
        {
            name: 'Status',
            selector: row => (
                <span
                    className={`inline-block px-3 py-1 rounded-full text-center ${getStatusStyle(row.campaignStatus)}`}
                >
                    {row.campaignStatus}
                </span>
            ),
            style: {
                width: '90rem',
            },
        },
        {
            name: 'Start Date',
            selector: row => row.startDate,
            style: {
                width: '10rem',  // Set a smaller width for this column
            },
        },
        {
            name: 'End Date',
            selector: row => row.endDate,
            style: {
                width: '10rem',  // Set a smaller width for this column
            },
        },
        {
            name: 'Action',
            cell: row => <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={() => handleViewDetails(row)}
            >
                View Details
            </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            style: {
                width: '10rem',  // Adjust width for the Action column
            },
        },
    ];

    const handleViewDetails = (campaign) => {
        navigate('/brand/campaigns/details', { state: { campaign } });
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 'APPROVED':
                return 'bg-[#00B69B] text-[#FFFFFF]';
            case 'PENDING_APPROVAL':
                return 'bg-[#FCBE2D] text-[#FFFFFF]';
            case 'REJECTED':
                return 'bg-[#FD5454] text-[#FFFFFF]';
            default:
                return 'bg-[#FCBE2D] text-[#FFFFFF]';
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredCampaigns(getBrandCampaignReducerData);
        } else {
            const filtered = getBrandCampaignReducerData.filter(campaign =>
                campaign.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                campaign.purpose.toLowerCase().includes(searchQuery.toLowerCase()) ||
                campaign.platform.toLowerCase().includes(searchQuery.toLowerCase()) ||
                campaign.campaignType.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredCampaigns(filtered);
        }
    }, [searchQuery, getBrandCampaignReducerData]);

    const customTableStyles = {
        header: {
            style: {
                backgroundColor: '#F1F4F9',
                fontSize: '1.6rem',  // Increased text size for header
                fontWeight: 700,
                color: '#202224',
                position: 'sticky',  // Make header sticky
                top: 0,  // Ensure it sticks at the top
                zIndex: 1,  // Ensure it appears above the rows
            },
        },
        rows: {
            style: {
                fontSize: '1.4rem',  // Increased text size for rows
                fontWeight: 600,
                color: '#202224',
                borderBottom: '1px solid #EDEFF1',
            },
        },
        headCells: {
            style: {
                padding: '1rem',
                textAlign: 'left',
                backgroundColor: '#e5e7eb',
            },
        },
        cells: {
            style: {
                padding: '1.5rem',
            },
        },
    };

    useEffect(() => {
        dispatch(getBrandCampaign());
    }, [dispatch]);

    return (
        <div className="w-full h-full flex flex-col justify-start bg-[#EDEEF1] p-4">
            <Breadcrumbs breadcrumbs={[{ label: 'Home', href: '/' }, { label: 'My Campaigns', href: '/brand/campaigns' }]} />
            <div className="w-full flex justify-between items-center my-[4rem] px-[4rem]">
                    <div className="relative py-[1.5rem]">
                        <FiSearch className="absolute top-1/2 left-5 transform -translate-y-1/2 w-[2.1rem] h-[2.1rem] text-gray-400" />
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search Campaigns"
                            className="w-[20rem] p-3 border text-[1.4rem] inter-font font-[400] border-gray-300 rounded-[0.3rem] pl-16 outline-none"
                        />
                    </div>

                    <div className="flex space-x-[1.2rem]">
                        {/*<button className="bg-white p-4 rounded-[1rem] outline-none">*/}
                        {/*    <FiFilter className="inline-block w-[2.1rem] h-[2.1rem]" />*/}
                        {/*</button>*/}
                        <button
                            className="flex items-center bg-[#0D9B50] text-[1.6rem] text-white px-4 py-[1rem] rounded-[0.8rem] outline-none"
                            onClick={() => {
                                dispatch(setBrandCampaignCreationData({
                                    basicDetails: {
                                        campaignName: null,
                                        campaignPurpose: null,
                                        platform: null,
                                        campaignType: null,
                                    },
                                    contentDetails: {
                                        startDate: null,
                                        endDate: null,
                                        bannerTemplate: {},
                                        videoPosition: null,
                                        mediaFileLink: null,
                                        mediaFileName: null,
                                        script: null,
                                        dialogue: null,
                                        backgroundColor: null,
                                        otherProperties: null,
                                        isLocationRequired: false,
                                        isBrandApprovalRequired: false,
                                        uploadDuration: null,
                                    },
                                    demographicsDetails: {
                                        region: {},
                                        country: {},
                                        state: {},
                                        campaignAgeGroups: [],
                                        gender: null,
                                        niches: [],
                                        influencerType: null,
                                        targetView: null,
                                        estimatedBudget: null
                                    },
                                }));
                                dispatch(setBrandCampaignCreationStep(1));
                                navigate('/brand/campaigns/create');
                            }}
                        >
                            <img src={SidebarImg.campaigns1} alt="Campaign Icon" className="w-[2.1rem] h-[2.1rem]" />+
                            <h1 className="inter-font font-[500] px-4">New Campaigns</h1>
                        </button>
                    </div>
                </div>
            <div className="w-full h-full flex flex-col px-[4rem]">
                <div className="w-full flex flex-col justify-between rounded-[2.6rem] bg-[#ffffff] p-[2.4rem]">
                    <h1 className="text-[2.4rem] leading-[5rem] text-[#202224] inter-font font-[700] p-[2rem]">
                        Campaigns
                    </h1>

                    <div className="w-full !overflow-y-scroll no-scrollbar">
                        {Array.isArray(filteredCampaigns) && filteredCampaigns.length > 0 ? (
                            <DataTable
                                columns={columns}
                                data={filteredCampaigns}
                                customStyles={customTableStyles}
                                pagination
                                highlightOnHover
                                striped
                                responsive
                            />
                        ) : (
                            <div className="text-4xl leading-[70px] text-gray-500 text-center mt-4">
                                There are no records to display.
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MyCampaigns;
