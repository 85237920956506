import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoArrowLeft } from 'react-icons/go';
import { InfluencerImg } from '../../../static/constant/imgConstants';
import { getStateByCountry } from '../../../store/campaignStore/actions/CampaignMasterActions/getStateByCountryActions';
import { getDashboardData } from '../../../store/brandDashboardStore/actions/getDashboardDataActions';
import { getAllCountry } from '../../../store/publicStore/actions/publicActions/getAllCountryActions';
import { getCampaignListWithFilterDashBoard } from '../../../store/brandDashboardStore/actions/getCampaignListWithFilterDashBoardActions';
import { getMyBrandBusinessDetail } from '../../../store/BusinessStore/actions/BrandBusinessActions/getMyBrandBusinessDetailActions';
import { useNavigate } from 'react-router';
import Popup from '../../popups/Popup/Popup';
import { SiLibreofficewriter } from 'react-icons/si';

const BrandDashboardV2 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [country, setCountry] = useState();
    const [selectedState, setSelectedState] = useState();

    const [isPopupOpen, setPopupOpen] = useState(false);


    const getUserInfoReducerData = useSelector(state => state.getUserInfoReducer.data);
    const getAllCountryReducerData = useSelector(state => state.getAllCountryReducer.data);
    const getAllCountryReducerStatus = useSelector(state => state.getAllCountryReducer.status);
    const getStateByCountryReducerData = useSelector(state => state.getStateByCountryReducer?.data);
    const getDashboardDataReducerData = useSelector(state => state.getDashboardDataReducer?.data);
    const getCampaignListWithFilterDashBoardReducerData = useSelector(state => state.getCampaignListWithFilterDashBoardReducer?.data);

    useEffect(() => {
        dispatch(getMyBrandBusinessDetail((status, response, message) => {
            if (status === 302) {
                setPopupOpen(true)
            } else {
                // Toast(response.message, 'error');
            }
        }))
    }, []);

    const [campaignData, setCampaignData] = useState([
        { id: 1, name: 'Campaign 1', period: '12.09.2024', views: '10000k' },
        { id: 2, name: 'Campaign 2', period: '12.09.2024', views: '5000k' },
        { id: 3, name: 'Campaign 3', period: '12.09.2024', views: '1000k' },
    ]);

    useEffect(() => {
        dispatch(getDashboardData());
        dispatch(getAllCountry());
    }, [])

    useMemo(() => {
        if (getAllCountryReducerStatus == 200) {
            dispatch(getStateByCountry({ countryId: country ? country : 1 }));
        }
    }, [getAllCountryReducerStatus, country])

    console.log("getUserInfoReducerData :", getUserInfoReducerData);


    return (
        <div className="w-full h-full bg-[#EDEEF1] overflow-scroll">
            <div className="w-full flex flex-col">
                {/* Header */}
                <div className="flex justify-start items-center border-b bg-[#EDEEF1] mx-[2rem] mt-4 p-4">
                    <div className="text-[1.4rem] text-[#38414A] flex items-center space-x-2 mb-2">
                        <a href="#" className="">
                            <GoArrowLeft size={22} color="#BCBCBC" />
                        </a>
                        <span className="text-[1.8rem] text-[#38414A] inter-font font-[500]">
                            Control Tower Dashboard
                        </span>
                    </div>
                </div>
                {/* Greeting Section */}
                <div className="px-[4rem] mt-[3rem]">
                    <h2 className="text-[2.8rem] text-[#7F8387] inter-font font-[500]">
                        HI {getUserInfoReducerData?.firstName ? getUserInfoReducerData?.firstName : "User"}!
                    </h2>
                    <h2 className="text-[1.8rem] text-[#7F8387] inter-font font-[500] mt-2">
                        Welcome to creator dashboard lets collaborate!
                    </h2>
                </div>
                {/* Dashboard Content */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-[4rem] mt-[3rem]">
                    {/* Card 1: Views of Campaign By State */}
                    <div className="bg-white p-6 shadow-md rounded-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-[2.4rem] inter-font font-[500]">Views of Campaign By State</h3>
                            <div className='w-[7.4rem] h-[7.4rem]'>
                                <img src={InfluencerImg.barnd1} alt="Icon" className='w-full h-full' />
                            </div>
                        </div>
                    </div>
                    {/* Card 2: Campaign Status */}
                    <div className="bg-white p-6 shadow-md rounded-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-[2.4rem] inter-font font-[500]">Campaign Status</h3>
                            <div className='w-[7.4rem] h-[7.4rem]'>
                                <img src={InfluencerImg.barnd2} alt="Icon" className='w-full h-full' />
                            </div>
                        </div>
                    </div>
                    {/* Card 3: Influencer/Campaign */}
                    <div className="bg-white p-6 shadow-md rounded-lg flex flex-col">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-[2.4rem] inter-font font-[500]">Influencer/Campaign</h3>
                            <div className='w-[7.4rem] h-[7.4rem]'>
                                <img src={InfluencerImg.barnd3} alt="Icon" className='w-full h-full' />
                            </div>
                        </div>
                        <p className="text-[2rem] font-bold">
                            {getDashboardDataReducerData?.influencerCampaignCount?.influencer ? getDashboardDataReducerData?.influencerCampaignCount?.influencer : 0}/{getDashboardDataReducerData?.influencerCampaignCount?.campaign ? getDashboardDataReducerData?.influencerCampaignCount?.campaign : 0}
                        </p>
                    </div>
                    {/* Card 4: First Table */}
                    <div className="bg-white p-6 shadow-md rounded-lg">
                        <div className="grid grid-cols-2 gap-4">
                            <select
            className="text-[1.4rem] text-[#7F8387] inter-font font-[500] border p-2 rounded"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            >
            {getAllCountryReducerData?.map((country) => (
                <option key={country.countryId} value={country.countryId}>
                    {country.countryName}
                </option>
            ))}
                            </select>
                            <select
                                id="state"
                                value={selectedState}
                                onChange={(e) => {
                                    setSelectedState(e.target.value);
                                    dispatch(getCampaignListWithFilterDashBoard({
                                        "countryId": country ? country : 1,
                                        "stateId": e.target.value
                                    }))
                                }}
            className="w-full px-4 py-2 text-[1.4rem] inter-font font-[400] border border-gray-300 rounded-lg focus:outline-none"
            required
        >
                                {Array.isArray(getStateByCountryReducerData) && getStateByCountryReducerData.map((items) => (
                                    <option key={items.stateId} value={items.stateId}>
                                        {items.name}
                                    </option>
                                ))}
                            </select>
                        </div>
    <div className="mt-4 max-h-[200px] overflow-y-auto">
        <table className="w-full text-left border-collapse">
                            <thead>
                                <tr className="bg-[#F1F4F9]">
                    <th className="text-[1.4rem] text-[#7F8387] inter-font font-[500] p-2 w-[40%]">Campaign</th>
                    <th className="text-[1.4rem] text-[#7F8387] inter-font font-[500] p-2 w-[40%]">Period</th>
                    <th className="text-[1.4rem] text-[#7F8387] inter-font font-[500] p-2 w-[20%]">Views</th>
                                </tr>
                            </thead>
                            <tbody>
                {getCampaignListWithFilterDashBoardReducerData?.length > 0 ? (
                                    getCampaignListWithFilterDashBoardReducerData.map((item) => (
                                        <tr key={item.id} className="border border-gray-200">
                            <td
                                className="text-[1.4rem] text-[#7F8387] inter-font font-[400] border border-gray-300 p-2 truncate"
                                title={item.campaignName}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '150px', // Adjust based on available width
                                }}
                            >
                                {item.campaignName}
                            </td>
                            <td className="text-[1.4rem] text-[#7F8387] inter-font font-[400] border border-gray-300 p-2">
                                {item.startDate + " to " + item.endDate}
                            </td>
                            <td className="text-[1.4rem] text-[#7F8387] inter-font font-[400] border border-gray-300 p-2 text-center">
                                {item.views ? item.views : 0}
                            </td>
                                        </tr>
                                    ))
                ) : (
                    <tr>
                        <td className="p-2"></td>
                        <td className="text-[1.4rem] text-[#7F8387] inter-font font-[400] p-2">
                            No Data Available!
                        </td>
                        <td className="p-2"></td>
                                    </tr>
                )}
                            </tbody>
                        </table>
                    </div>
</div>

                    {/* Card 5: Second Table */}
                    <div className="bg-white p-6 shadow-md rounded-lg">
                        <h3 className="text-[1.6rem] inter-font font-[500] mb-4">Campaign Status Counts</h3>
    <div className="max-h-[200px] overflow-y-auto">
        <table className="w-full text-left border-collapse">
                            <thead>
                                <tr className="bg-[#F1F4F9]">
                    <th className="text-[1.4rem] text-[#7F8387] inter-font font-[500] p-2 w-[60%]">Status</th>
                    <th className="text-[1.4rem] text-[#7F8387] inter-font font-[500] p-2 w-[40%]">No of Campaign</th>
                                </tr>
                            </thead>
                            <tbody>
                {getDashboardDataReducerData?.campaignStatusCountJson?.length > 0 ? (
                                    getDashboardDataReducerData?.campaignStatusCountJson?.map((data, index) => (
                        <tr key={index} className="border border-gray-200">
                            <td
                                className="text-[1.4rem] text-[#7F8387] inter-font font-[400] border border-gray-300 p-2 truncate"
                                title={data.status}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '200px', // Adjust width as necessary
                                }}
                            >
                                {data.status}
                            </td>
                            <td className="text-[1.4rem] text-[#7F8387] inter-font font-[400] border border-gray-300 p-2 text-center">
                                {data.statusCount}
                            </td>
                                        </tr>
                                    ))
                ) : (
                    <tr>
                        <td className="p-2"></td>
                        <td className="text-[1.4rem] text-[#7F8387] inter-font font-[400] p-2">
                            No Data Available!
                        </td>
                        <td className="p-2"></td>
                                    </tr>
                )}
                            </tbody>
                        </table>
                    </div>
</div>

                    {/* Card 6: Total Views */}
                    <div className="bg-white p-6 shadow-md rounded-lg flex flex-col">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-[1.6rem] inter-font font-[500]">Total Views of 10 brands</h3>
                            <div className='w-[7.4rem] h-[7.4rem]'>
                                <img src={InfluencerImg.barnd4} alt="Icon" className='w-full h-full' />
                            </div>
                        </div>
                        <p className="text-[2rem] font-bold">{getDashboardDataReducerData?.viewTopTenBrand}</p>
                    </div>
                </div>
            </div>
            <div className="mb-8 px-[4rem] mt-[3rem]">
                <h3 className="text-[2.8rem] inter-font font-[500] text-[#7F8387] mb-4">Recent Campaign</h3>
                <table className="w-full bg-white shadow-md rounded-md overflow-scroll mb-6">
                    <thead>
                        <tr className="bg-[#F1F4F9] text-[2rem] text-center inter-font font-[500]">
                            <th>Total Budget</th>
                            <th>Influencer</th>
                            <th>View Capacity</th>
                            <th>Progress (%)</th>
                            <th>Remaining Views</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getDashboardDataReducerData?.influencerViewsProgress?.data?.length > 0 ?
                            getDashboardDataReducerData?.influencerViewsProgress?.data?.map((campaign, index) => (
                                <tr key={index} className="text-[2rem] inter-font font-[500] text-center border-b">
                                    <td>{index == 0 ? "$" + getDashboardDataReducerData?.influencerViewsProgress?.totalBudget : null}</td>
                                    <td>{campaign.influencerName}</td>
                                    <td>{campaign.viewCapacity}</td>
                                    <td>{campaign.progress}%</td>
                                    <td>{campaign.remainingView}</td>
                                </tr>
                            ))
                            :
                            <tr className="">
                                <td className=""></td>
                                <td className=""></td>
                                <td className="text-[2rem] inter-font font-[500] text-center p-2">No Data Available!</td>
                                <td className=""></td>
                                <td className=""></td>
                            </tr>
                        }
                    </tbody>
                </table>
                {/* Second Table */}
                <table className="w-full bg-white shadow-md rounded-md overflow-scroll">
                    <thead>
                        <tr className="bg-[#F1F4F9] text-center text-[2rem] inter-font font-[500]">
                            <th>Total Budget</th>
                            <th>Influencer</th>
                            <th>Max Budget</th>
                            <th>Progress (%)</th>
                            <th>Remaining Budget</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getDashboardDataReducerData?.influencerEarningProgress?.data?.length > 0 ?
                            getDashboardDataReducerData?.influencerEarningProgress?.data.map((campaign, index) => (
                                <tr key={index} className="text-[2rem] inter-font font-[500] text-center border-b">
                                    <td>{index == 0 ? "$" + getDashboardDataReducerData?.influencerEarningProgress?.totalBudget : null}</td>
                                    <td>{campaign.influencerName}</td>
                                    <td>{campaign.MaxBudget}</td>
                                    <td>{campaign.progress}%</td>
                                    <td>{campaign.remainingBudget}</td>
                                </tr>
                            ))
                            :
                            <tr className="">
                                <td className=""></td>
                                <td className=""></td>
                                <td className="text-[2rem] inter-font font-[500] text-center p-2">No Data Available!</td>
                                <td className=""></td>
                                <td className=""></td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setPopupOpen(false)}
                title="Business Registration"
                canDismiss
                icon={<SiLibreofficewriter color="#D72828" size={110} className="my-[5rem]" />}
                content={<p className='text-[1.6rem] w-[80%] mx-auto text-center'>Please register your business to access the campaign creation features</p>}
                size="sm"
                primaryButton={{
                    text: 'Add Business',
                    onClick: () => {
                        navigate("/brand/busisness")
                        setPopupOpen(false);
                    },
                }}
                secondaryButton={{
                    text: 'Not Now',
                    onClick: () => setPopupOpen(false),
                }}
            />
        </div>
    );
};

export default BrandDashboardV2;
