import React, { useState } from 'react';
import { MyCampaignsImg } from '../../../static/constant/imgConstants';
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import { useDispatch } from 'react-redux';
import { HexColorPicker } from 'react-colorful';
import {uploadCampaignFile} from "../../../store/campaignStore/actions/CreateCampaignActions/uploadCampaignFileActions";
import CampaignDate from '../BrandCampaignCreation/CampaignDate';
import { useToast } from '../../../hooks/useToast';

const Ugc = ({
    script,
    setScript,
    dialogue,
    setDialogue,
    backgroundColor,
    setBackgroundColor,
    mediaFileLink,
    setMediaFileLink,
    mediaFileName,
    setMediaFileName,
    otherProperty,
    setOtherProperty,
    isLocationRequired,
    setIsLocationRequired,
    isBrandApprovalRequired,
    setIsBrandApprovalRequired,
    selectedGenre,
    setSelectedGenre,
    selectedDateTime,
    setSelectedDateTime,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    errors,
    setErrors,
}) => {
    const dispatch = useDispatch();
    const Toast = useToast();

    const [fileName, setFileName] = useState(mediaFileName);
    const [isUploading, setIsUploading] = useState(false); // New state for upload status
    const [isDragActive, setIsDragActive] = useState(false); // State for drag feedback

    const handleFileUpload = (file) => {
        setIsUploading(true);  // Show uploading status
        setFileName(file.name);  // Store file name for display
        const localUrl = URL.createObjectURL(file); // Create local URL for preview
        dispatch(uploadCampaignFile({ file }, (response, status) => {
            // Callback to update content_or_url with the returned image URL
            if(status === 200){
                setMediaFileName(file.name);  // Update the file name
                setMediaFileLink(response);
                setIsUploading(false);  // Hide uploading status
                setErrors((prevState) => {
                    const {["mediaFileLink"]: _, ...updatedObject} = prevState;
                    return updatedObject;
                });
            }else {
                Toast(response, 'error');
            }

        }));
    };

    const handleFileRemove = () => {
        setFileName("");  // Clear the file name
        setMediaFileLink("");  // Clear the media link
        setMediaFileName("");
        URL.revokeObjectURL(mediaFileLink);  // Revoke the URL
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragActive(true); // Set drag state to active
    };

    const handleDragLeave = () => {
        setIsDragActive(false); // Reset drag state when leaving
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragActive(false); // Reset drag state
        const file = e.dataTransfer.files[0];
        handleFileUpload(file);
    };

    const genre = [
        { "id": 1, "name": "Rock" },
        { "id": 2, "name": "Pop" },
        { "id": 3, "name": "Hip Hop" },
        { "id": 4, "name": "Electronic" },
        { "id": 5, "name": "Jazz" },
        { "id": 6, "name": "Classical" },
        { "id": 7, "name": "Country" },
        { "id": 8, "name": "Reggae" },
        { "id": 9, "name": "Blues" },
        { "id": 10, "name": "R&B" },
        { "id": 11, "name": "Soul" },
        { "id": 12, "name": "Funk" },
        { "id": 13, "name": "Metal" },
        { "id": 14, "name": "Latin" },
        { "id": 15, "name": "Folk" }
      ]

    return (
        <div className='w-full'>
            <h1 className='text-[1.4rem] text-[#111729] inter-font font-[500]'>User Generated Content Campaign</h1>
            <div className='flex justify-between items-start flex-wrap w-full'>
                <div className='w-[49%] mb-[2rem]'>
                    <label className="text-[1.6rem] text-[#111729] inter-font font-[400]">Script<span className=' text-[1.6rem] inter-font font-[400] text-[#E3351D]'>*</span></label>
                    <textarea
                        type="text"
                        value={script}
                        onChange={(e) => {
                            setScript(e.target.value)
                            setErrors((prevState) => {
                                const {["script"]: _, ...updatedObject} = prevState;
                                return updatedObject;
                            });
                        }}
                        placeholder="Enter Script"
                        class="w-full min-h-[12rem] border border-gray-300 text-[1.6rem] text-[#111729] inter-font font-[400] mt-[1rem] rounded-lg p-4 resize-y"
                        maxLength={1000}
                    />
                    {errors?.script && (
                        <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                            {errors?.script}
                        </p>
                    )}
                </div>
                <div className='w-[49%] mb-[2rem]'>
                    <label className="text-[1.6rem] text-[#111729] inter-font font-[400]">Dialogue<span className='text-[1.6rem] inter-font font-[400] text-[#E3351D]'>*</span></label>
                    <textarea
                        type="text"
                        value={dialogue}
                        onChange={(e) => {
                            setDialogue(e.target.value)
                            setErrors((prevState) => {
                                const {["dialogue"]: _, ...updatedObject} = prevState;
                                return updatedObject;
                            });
                        }}
                        placeholder="Enter Dialogue"
                        className="w-full min-h-[12rem] border border-gray-300 text-[1.6rem] text-[#111729] inter-font font-[400] mt-[1rem] rounded-lg p-4 resize-y"
                        maxLength={1000}
                    />
                    {errors?.dialogue && (
                        <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                            {errors?.dialogue}
                        </p>
                    )}
                </div>
                <div className="w-[49%] text-[1.6rem] inter-font font-[400] campaign-video-bg">
                    <label className="text-[#111729]">
                        Background Color Shade<span className="text-red-500">*</span>
                    </label>
                    <div className="flex justify-between items-end mt-[1rem]">
                        <HexColorPicker
                            color={backgroundColor}
                            onChange={setBackgroundColor}
                        />
                        <div className="w-[60%] ">
                            <div className="w-full h-[22.2rem] rounded-lg" style={{backgroundColor: backgroundColor}} />
                            <input
                                type='text'
                                className="w-full h-[4.4rem] px-4 py-4 border border-[#E3E8EF] rounded-lg focus:outline-none placeholder-[#677489]"
                                value={backgroundColor}
                                placeholder='Enter hex color code'
                                maxLength={7} // Set maximum length
                                readOnly
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (value.length === 6 && !value.startsWith('#')) {
                                        value = `#${value}`; // Prepend '#'
                                    }
                                    const hexColorRegex = /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/;
                                    if (value === '' || hexColorRegex.test(value)) {
                                        setBackgroundColor(value);
                                    }}
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className='w-[49%] flex flex-col mb-[2rem] h-[30rem]'>
                    <label className="text-[1.6rem] text-[#111729] inter-font font-[500]">Image/Video to be used<span
                        className="text-red-500">*</span></label>
                    <div
                        className={`w-full flex flex-col justify-center items-center h-full border ${
                            isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                        } mt-[1rem] rounded-lg p-6 text-center ${mediaFileLink ? '' : 'cursor-pointer'}`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                    >
                        <input
                            type="file"
                            accept="image/*,video/*"
                            className="hidden"
                            onChange={(e) => handleFileUpload(e.target.files[0])}
                            id="fileUpload"
                        />
                        {isUploading ? (
                            <p className="text-blue-500 text-[1.4rem] inter-font font-[400]">Uploading...</p>
                        ) : mediaFileLink ? (
                            <div className="w-full text-center">
                                <p className='text-[1.4rem] inter-font font-[400] text-[#111729] mb-4'>{fileName}</p>
                                <button
                                    onClick={handleFileRemove}
                                    className='text-[#FF4D4F] font-medium hover:underline text-[1.4rem]'
                                >
                                    Remove File
                                </button>
                            </div>
                        ) : (
                            <label htmlFor="fileUpload" className="cursor-pointer">
                                <p className={`text-[1.4rem] inter-font font-[400] ${isDragActive ? 'text-blue-600' : 'text-[#111729]'}`}>
                                    {isDragActive ? 'Drop file here' : ''}
                                </p>
                                {!isDragActive ? (
                                <p className='text-[1.4rem] inter-font font-[400] text-[#111729]'>
                                    Drag files here <br/>
                                    or <span className='text-[1.4rem] inter-font font-[400] text-[#34A853]'>browse your computer</span>
                                    </p>
                                ) : ''}
                            </label>
                        )}
                    </div>
                    {errors?.mediaFileLink && (
                        <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                            {errors?.mediaFileLink}
                        </p>
                    )}
                </div>
                <div className='w-[49%] mb-[2rem]'>
                    <label className="text-[1.4rem] text-[#111729] inter-font font-[500]">Other Properties<span className='text-[#E3351D]'>*</span></label>
                    <textarea
                        type="text"
                        value={otherProperty}
                        onChange={(e) => {
                            setOtherProperty(e.target.value)
                            setErrors((prevState) => {
                                const {["otherProperty"]: _, ...updatedObject} = prevState;
                                return updatedObject;
                            });
                        }}
                        placeholder="Enter Other Properties"
                        class="w-full min-h-[12rem] border border-gray-300 text-[1.6rem] text-[#111729] inter-font font-[400] mt-[1rem] rounded-lg p-4 resize-y"
                        maxLength={1000}
                    />
                    {errors?.otherProperty && (
                        <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                            {errors?.otherProperty}
                        </p>
                    )}
                </div>
                <div className='w-[49%] mb-[2rem]'>
                    <label htmlFor="genre" className="text-[1.6rem] text-[#111729] inter-font font-[400]">genre</label>
                    <select
                        id="genre"
                        value={selectedGenre}
                        onChange={(e) => {
                            setSelectedGenre(e.target.value)
                            setErrors((prevState) => {
                                const {["selectedGenre"]: _, ...updatedObject} = prevState;
                                return updatedObject;
                            });
                        }}
                        className="w-full px-4 py-4 text-[1.6rem] inter-font font-[400] border border-gray-300 rounded-lg focus:outline-none mt-[1rem]"
                    >
                        <option value="" selected>Select from Option</option>
                        {genre.map((items) => (
                            <option key={items.id} value={items.name}>
                                {items.name}
                            </option>
                        ))}
                    </select>
                    {errors.selectedGenre && <span className="text-[1.6rem] inter-font font-[400] text-red-500">{errors.selectedGenre}</span>}
                </div>
                <CampaignDate
                    startDate={startDate}
                    endDate={endDate}
                    errors={errors}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setErrors={setErrors}
                />
                <div className="w-[49%] my-[2rem]">
                    <label className="text-[1.6rem] inter-font font-[400] text-[#111729] mb-1">Upload Duration Days<span className="text-[1.6rem] text-[#E3351D]">*</span></label>
                    <div className="relative mt-[1.2rem]">
                        <input
                            className="w-full text-[1.6rem] text-[#111729] inter-font font-[400] border border-gray-300 rounded-lg p-4 pr-[3rem]"
                            onKeyDown={(e) => e.preventDefault()}
                            type="datetime-local"
                            min={new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().slice(0, 16)} // Min date set to 15 days from now
                            max={`${startDate}T23:59`} // End of day for the provided startDate
                            value={selectedDateTime}
                            onChange={(e) => {
                                setSelectedDateTime(e.target.value);
                                setErrors((prevState) => {
                                    const {["selectedDateTime"]: _, ...updatedObject} = prevState;
                                    return updatedObject;
                                });
                            }}
                            id="datetime"
                        />
                        <img
                            src={MyCampaignsImg.calendarIcon}
                            alt="Calendar Icon"
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 w-10 h-10"
                            onClick={() => {
                                const inputDateElement = document.getElementById('datetime');
                                inputDateElement.showPicker();
                            }}
                        />
                    </div>
                    {errors?.selectedDateTime && (
                        <p className="text-red-500 text-[1.6rem] inter-font font-[400]">
                            {errors?.selectedDateTime}
                        </p>
                    )}
                </div>
                <div className='w-[24%] my-[2rem] flex flex-col justify-between text-[1.4rem] text-[#111729] inter-font font-[500]'>
                    <label className="block font-medium mb-[1rem]">Location<span className='text-[#E3351D]'>*</span></label>
                    <div className="flex items-center mt-[1rem] space-x-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="location"
                                checked={isLocationRequired}
                                onChange={() => setIsLocationRequired(true)}
                                className="mr-2"
                                />
                            Required
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="location"
                                checked={!isLocationRequired}
                                onChange={() => setIsLocationRequired(false)}
                                className="mr-2"
                                />
                            Not-Required
                        </label>
                    </div>
                </div>
                <div className='w-[24%] my-[2rem] flex flex-col justify-between text-[1.4rem] text-[#111729] inter-font font-[500]'>
                    <label className="block font-medium mb-[1rem]">Brand Approval Required<span className='text-[#E3351D]'>*</span></label>
                    <div className="flex items-center mt-[1rem] space-x-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="brandApproval"
                                checked={isBrandApprovalRequired}
                                onChange={() => setIsBrandApprovalRequired(true)}
                                className="mr-2"
                                />
                            Yes
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="brandApproval"
                                checked={!isBrandApprovalRequired}
                                onChange={() => setIsBrandApprovalRequired(false)}
                                className="mr-2"
                            />
                            No
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ugc
